import { object, string, array } from 'yup'

export const validationSchema = (steps: number) =>
  object().shape({
    ...(steps > 0 && {
      name: string()
        .required('This field is required')
        .min(3, `Airdrop name is too short. Min 3 characters`)
        .max(50, `Airdrop name is too long. Max 50 characters`)
    }),
    ...(steps > 1 && {
      budget: string()
        .test('required', 'This field is required', (value) => {
          return Boolean(value)
        })
        .test('min', 'Value must be greater than 0', (value) => {
          return +(value || 0) > 0
        })
        .nullable()
    }),
    ...(steps > 2 && { distribution: string().required('This field is required') }),

    ...(steps > 3 && {
      frequency: string().test('isRequired', 'This field is required', (value, { parent }) => {
        if (parent.distribution !== 'Manual') return Boolean(value)
        return true
      }),

      specificMembers: array()
        .test('isRequired', 'Must be at least 1 member', (value, { parent }) => {
          if (parent.distribution === 'Manual') return Boolean(value?.length)
          return true
        })
        .of(
          object().shape({
            name: string().required('This field is required'),
            walletAddress: string().required(
              'Invalid wallet format, should be an Ethereum address'
            ),
            amount: string()
              .required('This field is required')
              .test('min', 'Value must be greater than 0', (value) => {
                return +(value || 0) > 0
              })
          })
        )
        .test('insufficientBudget', 'Insufficient budget', (value: any, { parent }) => {
          if (parent.distribution !== 'Manual') return true
          const budget = parent.budget || parent.amountSum
          const addedAmount = value.reduce(
            (acc: number, next: { amount: string }) => acc + (+next.amount || 0),
            0
          )
          return +budget - +addedAmount.toFixed(6) >= 0
        })
        .test('remainedBudget', 'Use all budget', (value: any, { parent }) => {
          if (parent.distribution !== 'Manual') return true
          const budget = parent.budget || parent.amountSum
          const addedAmount = value.reduce(
            (acc: number, next: { amount: string }) => acc + (+next.amount || 0),
            0
          )
          return +budget - +addedAmount.toFixed(6) === 0
        })
    })
  })
