import { useMemo } from 'react'
import { useStore } from 'effector-react'
import { useParams } from 'react-router-dom'

import { $fundsList } from 'src/state/effector/store'

interface FundData {
  fundId: number
  name: string
  urlName: string
}

export const useFundData = () => {
  const { fund = '' } = useParams<{ fund?: string }>()
  const funds = useStore($fundsList)

  const fundData = useMemo(
    () => funds.find(({ name }) => name.replaceAll(' ', '-') === fund),
    [funds]
  )
  return (
    fundData
      ? { fundId: fundData.id, urlName: fundData.name.replaceAll(' ', '-'), name: fundData.name }
      : {}
  ) as FundData
}
