import React, { useEffect, useState, useMemo } from 'react'
import styled from '@emotion/styled'
import { InputLabel, MenuItem, Select } from '@mui/material'
import { SelectChangeEvent } from '@mui/material/Select/SelectInput'
import { useSearchParams } from 'react-router-dom'
import { useFundData } from 'src/hooks/use-fund-data'
import { colors } from 'src/constants/colors'
import { MEDIA_WIDTHS } from 'src/constants'
import { ECountry } from 'src/state/effector/users/user.types'
import apiService from 'src/services/api'
import SearchBar from 'src/components/SearchBar'
import { RadioButton, ButtonTransparent } from 'src/components/common'

interface Props {
  handleFilters: any
  filters: Record<string, any>
}

export const Filters = ({ filters, handleFilters }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [eventsName, handleEventsName] = useState<string[]>([])

  const queryTab: ECountry = searchParams.get('tab') as ECountry

  const { fundId } = useFundData()

  useEffect(() => {
    const fetch = async () => {
      try {
        const res = await apiService.getAllEventsName(fundId)
        if (res.data.length) {
          handleEventsName(res.data)
        }
      } catch (e) {}
    }
    if (eventsName.length === 0 && fundId) {
      fetch()
    }
  }, [eventsName, fundId])

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const search = event.currentTarget.value
    handleFilters((state: any) => ({ ...state, search }))
  }

  const onClearFiters = () => {
    setSearchParams({ tab: queryTab || 'NonUS' })

    handleFilters({
      search: '',
      eventName: 'all'
    })
  }

  const eventsOptions = useMemo(() => {
    if (eventsName.length) {
      const data = eventsName.map((name) => ({ label: name, value: name }))
      data.unshift({ label: 'All Events', value: 'all' })
      return data
    }

    return [{ label: 'All Events', value: 'all' }]
  }, [eventsName])

  return (
    <Container>
      <SearchBar
        name="search"
        value={filters.search}
        onChange={handleSearchChange}
        placeholder="Search by Name or Wallet"
      />
      <SelectFilter
        name="eventName"
        value={filters.eventName}
        onChange={handleFilters}
        options={eventsOptions}
      />
      <ClearFiltersButton onClick={onClearFiters}>Clear</ClearFiltersButton>
    </Container>
  )
}

interface SelectProps {
  name: string
  value: string
  onChange: any
  options: Array<{ label: string; value: string }>
}

const SelectFilter = ({ name, value, onChange, options }: SelectProps) => {
  const getSelectedOption = () => {
    const item = options.find((itemSelect) => itemSelect.value === value)
    if (item) return item.label

    return '-'
  }

  const handleSelect = (event: SelectChangeEvent) => {
    const selectedValue: any = event.target.value
    onChange((state: any) => ({ ...state, [name]: selectedValue }))
  }

  return (
    <Select
      id="item_type"
      labelId="item_type_label"
      value={value || ''}
      size="medium"
      onChange={handleSelect}
      displayEmpty
      sx={{
        '.MuiOutlinedInput-notchedOutline': {
          border: `1px solid ${colors.$disabledBg2}`,
          borderRadius: 0
        },
        '.MuiOutlinedInput-input': {
          paddingTop: '14px',
          paddingBottom: '14px'
        },
        '.MuiFormLabel-root div': {
          color: colors.$primaryLight + ' !important'
        }
      }}
      MenuProps={{
        sx: {
          '.MuiPaper-root': {
            borderRadius: 0
          }
        }
      }}
      renderValue={() => (
        <InputLabel
          disableAnimation
          shrink={false}
          focused={value !== undefined}
          id="item_type_label"
          sx={{ cursor: 'pointer', fontSize: 16, fontWeight: 500 }}
        >
          {getSelectedOption()}
        </InputLabel>
      )}
    >
      {options.map((item) => (
        <MenuItemWrapper key={item.label} value={item.value}>
          {item.label}
          <RadioButton checked={item.value === value} />
        </MenuItemWrapper>
      ))}
    </Select>
  )
}

const Container = styled.div`
  display: flex;
  gap: 8px;

  > :first-child {
    flex: 1;
    min-width: 200px;
  }

  > :nth-child(2) {
    width: 200px;
    @media (max-width: ${MEDIA_WIDTHS.upToSmall}px) {
      width: 100%;
    }
  }

  @media (max-width: ${MEDIA_WIDTHS.upToLarge}px) {
    flex-wrap: wrap;
  }
  @media (max-width: ${MEDIA_WIDTHS.upToSmall}px) {
    flex-direction: column;
  }
`

const MenuItemWrapper = styled(MenuItem)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  /* overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; */
  &:hover {
    color: ${colors.$black};
  }
`

const ClearFiltersButton = styled(ButtonTransparent)`
  background-color: #1e2223 !important;
  color: white;
  text-transform: none;
  white-space: nowrap;
  padding: 15px 22px !important;
  max-width: 136px;
  @media (max-width: ${MEDIA_WIDTHS.upToSmall}px) {
    max-width: 100%;
  }
`
