import React, { CSSProperties, useMemo, useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import DatePicker, { ReactDatePickerCustomHeaderProps } from 'react-datepicker'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import styled from '@emotion/styled'
import { Box, Select, MenuItem, FormControl } from '@mui/material'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined'

import moment from 'moment'

import calendarIcon from 'src/assets/calendar.svg'
import { colors } from 'src/constants/colors'
import EmptyStateIcon from 'src/assets/empty_state_icon.svg'
import { LoaderContainer } from 'src/components/LoadingIndicator'
import { ErrorMessage, Switch } from 'src/components/common'
import { useIsOpen } from 'src/hooks/use-is-open'

export const PageTitle = ({ title, style }: { title: string; style?: CSSProperties }) => {
  return <Title style={style}>{title}</Title>
}

const Title = styled.div`
  color: ${colors.$secondary};
  font-size: 30px;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: -0.6px;
  @media (max-width: 599px) {
    margin-top: 25px;
    font-size: 18px;
  }
`

export const EmptyStateBox = ({
  message = 'No records found',
  isLoading
}: {
  message?: string
  isLoading?: boolean
}) =>
  isLoading ? (
    <LoaderContainer height="200px" />
  ) : (
    <Wrapper>
      <img src={EmptyStateIcon} alt="empty-state-icon" />
      <WrapperMessage>{message}</WrapperMessage>
    </Wrapper>
  )

const Wrapper = styled.div`
  position: relative;
  height: 181px;
  width: 100%;
  box-sizing: border-box;
  gap: 12px;
  background-image: url("data:image/svg+xml;utf8,<svg width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'><rect width='100%' height='100%' style='fill: none; stroke: silver; stroke-width: 2; stroke-dasharray: 8'/></svg>");
  border-radius: 8px;
  align-self: stretch;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  font-weight: 500;
  font-size: 16px;
  line-height: 138%;

  color: ${colors.$primary2};
`

const WrapperMessage = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 138%;
`
interface HideUnhideSwitchProps {
  checked: boolean
  onChange: (val: boolean) => void
  text?: string
  withIcon?: boolean
  mt?: number
}

export const HideUnhideSwitch = ({
  checked,
  onChange,
  text = 'Show hidden users',
  withIcon = true,
  mt = 3
}: HideUnhideSwitchProps) => (
  <Switch
    checked={checked}
    onChange={onChange}
    label={
      text === 'Show hidden users' ? (
        <Box display="flex" alignItems="center" justifyContent="start">
          <Box mr={withIcon ? 1 : 0}>{text}</Box>
          {withIcon && (
            <Box display="flex" alignItems="center" justifyContent="start">
              {checked ? (
                <VisibilityOffOutlinedIcon fontSize="small" />
              ) : (
                <VisibilityOutlinedIcon fontSize="small" />
              )}
            </Box>
          )}
        </Box>
      ) : (
        text
      )
    }
    type="primary"
  />
)

interface DateSelectProps {
  value?: string
  onChange: (value: string) => void
  label?: string
  placeholder?: string
  disabled?: boolean
  style?: React.CSSProperties
  minDate?: Date | string
  error?: string
}

export const DateSelect = ({
  placeholder,
  minDate,
  value,
  onChange,
  disabled,
  error,
  label,
  style
}: DateSelectProps) => {
  const { isOpen: isDatePickerOpen, open: openDatePicker, close: closeDatePicker } = useIsOpen()

  return (
    <FormControl variant="standard" fullWidth style={style}>
      {label && (
        <Label color={colors.$black} className="form-control-label">
          {label}
        </Label>
      )}
      <DateContainer>
        <CalendarIcon src={calendarIcon} alt="calendarIcon" />
        <DatePicker
          placeholderText={placeholder}
          open={isDatePickerOpen}
          onFocus={() => {
            openDatePicker()
          }}
          locale="en-GB"
          onClickOutside={(e: any) => {
            if (e.target.localName === 'li' || e.target.id.includes('select')) return
            closeDatePicker()
          }}
          minDate={minDate ? new Date(minDate) : null}
          showPopperArrow={false}
          selected={value ? new Date(value) : null}
          onChange={(date: Date) => {
            onChange(moment(date).utc(true).toISOString())
            closeDatePicker()
          }}
          dateFormat="dd MMM yyyy"
          disabled={disabled}
          renderCustomHeader={(props: ReactDatePickerCustomHeaderProps) => (
            <CustomDatePickerHeader {...props} />
          )}
        />
      </DateContainer>
      <ErrorMessage message={error as string} isError={Boolean(error)} />
    </FormControl>
  )
}

const CustomDatePickerHeader = ({
  changeYear,
  changeMonth,
  date
}: ReactDatePickerCustomHeaderProps) => {
  const years = useMemo(() => new Array(78).fill('').map((_, index) => index + 2023), [])
  const months = useMemo(
    () => [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ],
    []
  )

  const [currMonth, setMonth] = useState(new Date(date).getMonth())
  const [currYear, setYear] = useState(new Date(date).getFullYear())

  return (
    <DatePickerHeader>
      <Select
        disableUnderline
        value={currMonth}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: '200px'
            }
          }
        }}
        IconComponent={() => <ExpandMoreIcon sx={{ fill: '#1E2223' }} />}
        onChange={({ target: { value } }) => {
          changeMonth(+value)
          setMonth(+value)
        }}
      >
        {months.map((month, index) => (
          <MenuItem key={month} value={index}>
            <SelectOptionLabel id={`select-month-${month}`}>{month}</SelectOptionLabel>
          </MenuItem>
        ))}
      </Select>
      <Select
        disableUnderline
        value={currYear}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: '200px'
            }
          }
        }}
        onChange={({ target: { value } }) => {
          changeYear(+value)
          setYear(+value)
        }}
        IconComponent={() => <ExpandMoreIcon sx={{ fill: '#1E2223' }} />}
      >
        {years.map((year) => (
          <MenuItem key={year} value={year}>
            <SelectOptionLabel id={`select-year-${year}`}>{year}</SelectOptionLabel>
          </MenuItem>
        ))}
      </Select>
    </DatePickerHeader>
  )
}

const Label = styled.div`
  color: ${colors.$secondary};
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
`

const SelectOptionLabel = styled.div`
  color: ${colors.$secondary};
  font-size: 16px;
  font-weight: 600;
  line-height: 16px; /* 100% */
  width: 100%;
`

const CalendarIcon = styled.img`
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
  z-index: 2;
`

const DatePickerHeader = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  .MuiSelect-select {
    padding: 0px 12px 0px 0px !important;
  }
`

const DateContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    width: 100%;
    height: 100%;
  }
  input {
    padding: 16px 16px 16px 52px;
    border-radius: 4px;
    border: none;
    width: 100%;
    height: 100%;
    font-size: 14px;
    font-family: 'Inter';
    ::placeholder {
      color: rgba(0, 0, 0, 0.32);
      font-weight: 400;
      /* color: rgba(0, 0, 0, 0.42); */
    }
  }
  .react-datepicker {
    color: ${colors.$secondary};
    padding: 44px;
    font-family: 'Inter' !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
  }
  .react-datepicker__header {
    background-color: transparent;
    border: none;
  }

  .react-datepicker__week,
  .react-datepicker__day-names {
    display: flex;
    align-items: center;
    gap: 16px;
  }
  .react-datepicker__day,
  .react-datepicker__day-name {
    margin: 0px;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    color: ${colors.$secondary};
    :hover {
      border-radius: 50%;
    }
  }
  .react-datepicker__day--outside-month {
    color: ${colors.$secondary2};
  }
  .react-datepicker__month {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 0px;
  }
  .react-datepicker__header {
    padding: 0px;
    margin-bottom: 16px;
  }
  .react-datepicker__day--selected {
    background-color: ${colors.$blue};
    border-radius: 50%;
    color: white;
  }
  .react-datepicker__day--keyboard-selected {
    background-color: ${colors.$lightBlue};
    border-radius: 50%;
    color: white;
  }
  .react-datepicker__day--disabled {
    color: #ccc !important;
  }
`

const SwitchContainer = styled(Box)`
  background-color: rgba(212, 219, 231, 0.16);
  padding: 10px;
`
