// export type UserRoles = "user" | "viewer" | "Admin" | "SuperAdmin";

import { Fund } from 'src/types/common.types'
import { UserRoles } from 'src/constants'

export type WhitelistTypes = 'PENDING' | 'ACCEPTED' | 'REJECTED'

export enum ECountry {
  NonUS = 'NonUS',
  US = 'US'
}

export enum EGetBuddyStatusOfUser {
  MotherTribeUS = 'MotherTribeUS',
  MotherTribe = 'MotherTribe',
  SpecificTriber = 'SpecificTriber'
}

export enum EIntroducerAddressTypes {
  NonUS = 'NonUS',
  US = 'US',
  Specific = 'Scepific'
}

export interface User {
  principal: string
  id: number
  email?: string
  name?: string
  tenant: string
  hidden?: boolean
  language: string
  role: UserRoles
  active: boolean
  ethAddress: string
  photoId?: string
  buddyId: number
  isBuddy: boolean
  mode: string
  country: ECountry
  deactivated?: boolean
  sales: number
  emailSent: boolean
  whiteListStatus?: WhitelistTypes
  createdAt: Date
  memberFrom?: Date
  updatedAt: Date
  deletedAt?: string
  photo?: any
  introducer?: User
  teamMemberOf: [
    {
      id: number
      userId: number
      fundId: number
      createdAt: string
      updatedAt: string
      deletedAt: string | null
      fund: Fund
    }
  ]
  funds: {
    fundId: number
    role: UserRoles
  }[]
}

export interface GetUsersParams {
  page: number
  offset: number
  whitelist: WhitelistTypes | null
  wallet?: string
  introducer?: string
  introducerName?: string
  name?: string
  all?: boolean
  showTeamMembers?: boolean
  showHiddenUsers?: boolean
  showPendingTribers?: boolean
  showPastTeamMembers?: boolean
  roles?: string
}

export interface AddMember {
  name?: string
  email?: string
  ethAddress: string
  introducerAddress: string
  whiteListStatus: WhitelistTypes
}

export interface AddTeamMember {
  name?: string
  ethAddress: string
  role: UserRoles
  deactivated?: boolean
}

export interface EditMember {
  name?: string
  email?: string
  ethAddress: string
  introducerAddress?: string
  whiteListStatus: WhitelistTypes
}

export interface AddMemberValidateParams {
  memberWallet?: string
  introducerWallet?: string
}

export interface AddTeamMemberValidateParams {
  ethAddress: string
  deactivated: boolean
}

export enum EUsersHiddenTypes {
  allTribers = 'allTribers',
  pendingWhitelist = 'pendingWhitelist',
  pendingTribers = 'pendingTribers'
}

export interface IShowHiddenUsers {
  allTribers: boolean
  pendingWhitelist: boolean
  pendingTribers: boolean
}
