import React, { useState } from 'react'
import { css, TableContainer, Tooltip } from '@mui/material'
import moment from 'moment/moment'
import styled from '@emotion/styled'
import { Box } from '@mui/system'

import { colors } from 'src/constants/colors'
import usFlagIcon from 'src/assets/usFlag.svg'
import internationalIcon from 'src/assets/international.png'
import apiService from 'src/services/api'
import LoadingIndicator from 'src/components/LoadingIndicator'
import pendingIcon from 'src/assets/pending.png'
import errorIcon from 'src/assets/error-filled.png'
import successIcon from 'src/assets/success-filled.png'

import { Table, HeaderRow, BodyRow, CellPrimary } from '../../../Table'
import { TablePagination } from '../../../Table/Pagination'
import { DetailsPopup } from '../details-popup'

const headerData: string[] = ['Date', 'Name', 'Budget', 'Status', '']

const statusIcon = {
  Done: <img src={successIcon} alt="successIcon" />,
  InProgress: <img src={pendingIcon} alt="pendingIcon" />,
  Failed: <img src={errorIcon} alt="errorIcon" />
} as Record<string, JSX.Element>

const statusLabel = {
  InProgress: 'Going',
  Failed: 'Failed',
  Done: 'Done'
} as Record<string, string>
interface Props {
  events: any
  handleChangePagination: (event: React.ChangeEvent<unknown>, newPage: number) => void
}

export const TemplatesHistory = ({ events, handleChangePagination }: Props) => {
  const [event, handleEvent] = useState<any>(null)
  const [calculations, handleCalculations] = useState<any>({})
  const [isLoading, handleIsLoading] = useState(false)

  const countryData = {
    International: {
      icon: (
        <img
          src={internationalIcon}
          alt="internationalIcon"
          style={{ marginRight: '10px', height: '18px' }}
        />
      ),
      label: 'International'
    },
    US: {
      icon: (
        <img src={usFlagIcon} alt="usFlagIcon" style={{ marginRight: '10px', height: '18px' }} />
      ),
      label: 'US'
    },
    all: { icon: null, label: 'All' }
  } as Record<string, { icon: JSX.Element | null; label: string }>

  const onCloseDetails = () => {
    handleEvent(null)
  }

  const openDetails = async (data: any) => {
    try {
      handleIsLoading(true)
      const res = await apiService.getAirdropEvent(data.id)
      handleEvent({
        ...res.data,
        isVotingRequired: res.data.isVotingRequired ? 'yes' : 'no'
      })
      handleCalculations({
        budget: res.data.budget,
        calculationPeriod: { start: res.data.startDate, end: res.data.endDate },
        membersCount: res.data.members.length,
        totalPoints: res.data.totalPoints,
        pointAmount: res.data.pointAmount
      })
    } catch (error) {
    } finally {
      handleIsLoading(false)
    }
  }

  return (
    <>
      {isLoading && <LoadingIndicator />}
      {event && (
        <DetailsPopup
          onlyView
          handleClose={onCloseDetails}
          calculations={calculations}
          draftId={null}
          event={event}
        />
      )}
      <Header id="template-history">Airdrops History</Header>
      {events?.items?.length > 0 ? (
        <>
          <TableContainer>
            <Table
              aria-label="customized table"
              sx={{ minWidth: '610px', border: '1px solid #E8E8E8' }}
            >
              <HeaderRow rowTemplate={rowTemplate}>
                {headerData.map((header) => (
                  <CellPrimary key={header}>{header}</CellPrimary>
                ))}
              </HeaderRow>
              <>
                {events.items.map((row: any) => (
                  <StyledBodyRow key={row.id} rowTemplate={rowTemplate}>
                    <CellPrimary>
                      <TowRowsCell>
                        <Tooltip
                          title={
                            <>
                              {moment(row.createdAt).format('DD MMM YYYY HH:mm')}&nbsp;(Your time)
                              <br />
                              {moment(row.createdAt).utc().format('DD MMM YYYY HH:mm')}&nbsp;(GMT)
                            </>
                          }
                          placement="top"
                          arrow
                        >
                          <div>{moment(row.createdAt).utc().format('D MMM')}</div>
                        </Tooltip>
                        <Tooltip title={row.user.name} placement="top" arrow>
                          <div>by {row.user.name}</div>
                        </Tooltip>
                      </TowRowsCell>
                    </CellPrimary>
                    <CellPrimary>
                      <Tooltip title={row.name} placement="top" arrow>
                        <div className="airdrop-name">{row.name} </div>
                      </Tooltip>
                    </CellPrimary>

                    <CellPrimary>
                      {(+row.budget).toLocaleString('en-US', { maximumFractionDigits: 6 })} USDC
                    </CellPrimary>

                    <CellPrimary>
                      <Box display="flex" alignItems="center">
                        {statusIcon[row.status]}&nbsp;{statusLabel[row.status]}
                      </Box>
                    </CellPrimary>

                    <CellPrimary onClick={() => openDetails(row)}>
                      <Details>Details</Details>
                    </CellPrimary>
                  </StyledBodyRow>
                ))}
              </>
            </Table>
          </TableContainer>
          <TablePagination
            response={events}
            handleChange={handleChangePagination}
            page={events.page}
          />
        </>
      ) : (
        <NoRecords>No records </NoRecords>
      )}
    </>
  )
}

const StyledBodyRow = styled(BodyRow)`
  background-color: white !important;
`
const rowTemplate = css`
  * {
    color: ${colors.$secondary};
    line-height: 16px;
  }
  grid-template-columns: repeat(4, minmax(130px, 1fr)) 100px;
`

const NoRecords = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 138%;
  color: ${colors.$secondary};
  padding: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed ${colors.$borderSecondary};
`

const TowRowsCell = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  > div:last-of-type {
    color: ${colors.$primaryLight};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .airdrop-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

const Details = styled.div`
  color: ${colors.$blue} !important;
  text-decoration: underline;
  /* font-weight: 700; */
  cursor: pointer;
`
const Header = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: ${colors.$secondary};
  margin-bottom: -8px;
`
