import React from 'react'
import { css, TableContainer } from '@mui/material'
import styled from '@emotion/styled'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'

import { colors } from 'src/constants/colors'
import { Fund } from 'src/types/common.types'

import { Table, HeaderRow, BodyRow, CellPrimary } from '../Table'
import { AddressesCell } from '../Table/AddressesCell'

const headerData: string[] = ['Fund name', 'Fund investors', 'Created by', 'Created at']

export const FundsTable = ({ items }: { items: Fund[] }) => {
  const navigate = useNavigate()

  const goToFundPage = (name: string) => {
    navigate(`/admin/fund/${name.replaceAll(' ', '-')}/dashboard`)
  }

  return (
    <TableContainer>
      <Table aria-label="customized table" sx={{ minWidth: '600px' }}>
        <HeaderRow rowTemplate={rowTemplate}>
          {headerData.map((header) => (
            <CellPrimary key={header}>{header}</CellPrimary>
          ))}
        </HeaderRow>
        <>
          {items.map((row, idx) => (
            <BodyRow key={row.id} rowTemplate={rowTemplate}>
              <CellPrimary>
                <ViewBtn onClick={() => goToFundPage(row.name)}>{row.name}</ViewBtn>
              </CellPrimary>
              <CellPrimary>{row.investorCount}</CellPrimary>
              <CellPrimary>
                <AddressesCell
                  address={row.createdBy.ethAddress || '-'}
                  name={row.createdBy.name}
                  id={`${idx}_created_by`}
                />
              </CellPrimary>
              <CellPrimary>{moment(row.createdAt).utc().format('DD MMM YYYY')}</CellPrimary>
            </BodyRow>
          ))}
        </>
      </Table>
    </TableContainer>
  )
}

const rowTemplate = css`
  grid-template-columns: 1fr 1fr minmax(160px, 1fr) 1fr;

  a {
    text-decoration: underline;
  }
`

const ViewBtn = styled.div`
  cursor: pointer;
  color: ${colors.$blue} !important;

  font-weight: 500;
  text-decoration-line: underline;
`
