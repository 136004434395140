import React from 'react'
import { toast } from 'react-toastify'
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { Box } from '@mui/material'
import styled from '@emotion/styled'

import apiService from 'src/services/api'
import { Investor } from 'src/types/common.types'
import { colors } from 'src/constants/colors'

import { Dropdown } from '../../common/Dropdown'
import editIcon from 'src/assets/edit.svg'

interface Props {
  data: Investor
  callback?: () => void
  disabled?: boolean
  onEdit?: (data: Investor) => void
}

export const HideUnhideFunctionality = ({ data, callback, disabled, onEdit }: Props) => {
  const undoAction = async () => {
    await apiService.hideInvestor({ investorId: data.id, hidden: false }).then(() => {
      toast.success(<BoltToastMessage>User has been unhidden</BoltToastMessage>)
      if (callback) callback()
    })
  }

  const onClick = async () => {
    const content = (
      <BoltToastMessage>
        <div>User has been {data.hidden ? 'unhidden' : 'hidden'}</div>
        {!data.hidden && <UndoButton onClick={undoAction}>Undo action</UndoButton>}
      </BoltToastMessage>
    )

    await apiService.hideInvestor({ investorId: data.id, hidden: !data.hidden }).then(() => {
      toast.success(content)
      if (callback) callback()
    })
  }

  return (
    <Dropdown
      disabled={disabled}
      menuContent={
        <ItemsContainer>
          <StyledBox onClick={onClick} display="flex" alignItems="center" justifyContent="start">
            {data.hidden ? (
              <VisibilityOutlinedIcon fontSize="small" sx={{ color: '#323232' }} />
            ) : (
              <VisibilityOffOutlinedIcon fontSize="small" sx={{ color: '#323232' }} />
            )}

            {data.hidden ? <div>Unhide</div> : <div>Hide</div>}
          </StyledBox>
          {onEdit && (
            <StyledBox
              onClick={() => onEdit(data)}
              display="flex"
              alignItems="center"
              justifyContent="start"
            >
              <img src={editIcon} alt="editIcon" />
              Edit
            </StyledBox>
          )}
        </ItemsContainer>
      }
    >
      <IconWrapper>
        <MoreVertIcon fontSize="small" />
      </IconWrapper>
    </Dropdown>
  )
}

const BoltToastMessage = styled('div')`
  font-weight: 600;
`

const UndoButton = styled('div')`
  text-decoration: underline;
  cursor: pointer;
  opacity: 0.9;
  &:hover {
    opacity: 1;
  }
`

const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const StyledBox = styled(Box)`
  cursor: pointer;
  font-size: 16px;
  padding: 4px 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${colors.$primary2};
  width: 132px;
  &:hover {
    border-radius: 3px;
  }
`
const IconWrapper = styled('div')`
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  &:hover,
  &:focus,
  &:active {
    background-color: ${colors.$border};
  }

  svg {
    fill: ${colors.$blue};
  }
`
