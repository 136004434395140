import React from 'react'
import { css, TableContainer, Tooltip } from '@mui/material'
import moment from 'moment/moment'

import { ENetworkTypes, Investor } from 'src/types/common.types'

import { Table, HeaderRow, BodyRow, CellPrimary } from '../../Table'
import { AddressesCell } from '../../Table/AddressesCell'

interface PaymentItem {
  country: string
  createdAt: string
  currency: string
  deletedAt: string | null
  eventName: string
  executionType: string
  id: number
  network: ENetworkTypes
  reward: string
  sourceName: string
  sourceOfMoney: string
  transactionType: string
  txHash: string
  updatedAt: string
  investor: Investor
}

const headerData: string[] = ['Date', 'Investor', 'Payment', 'Event name']

export const PaymentsTable = ({ items }: { items: PaymentItem[] }) => {
  const getFormattedLink = (network: ENetworkTypes, txHash: string) =>
    (network === ENetworkTypes.SEPOLIA && `https://sepolia.etherscan.io/tx/${txHash}`) ||
    (network === ENetworkTypes.ETH && `https://etherscan.io/tx/${txHash}`) ||
    '#'

  return (
    <TableContainer>
      <Table aria-label="customized table" sx={{ minWidth: '620px' }}>
        <HeaderRow rowTemplate={rowTemplate}>
          {headerData.map((header) => (
            <CellPrimary key={header}>{header}</CellPrimary>
          ))}
        </HeaderRow>
        <>
          {items.map((row, idx) => (
            <BodyRow key={row.id} rowTemplate={rowTemplate}>
              <CellPrimary>
                <Tooltip
                  title={
                    <>
                      {moment(row.createdAt).format('DD MMM YYYY HH:mm')}&nbsp;(Your time)
                      <br />
                      {moment(row.createdAt).utc().format('DD MMM YYYY HH:mm')}&nbsp;(GMT)
                    </>
                  }
                  placement="top"
                  arrow
                >
                  <a
                    href={getFormattedLink(row.network, row.txHash)}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {moment(row.createdAt).utc().format('DD MMM YYYY HH:mm')}&nbsp;(GMT)
                  </a>
                </Tooltip>
              </CellPrimary>
              <CellPrimary>
                <AddressesCell
                  address={row.investor.walletAddress || '-'}
                  name={row.investor.name}
                  id={`${idx}_member`}
                />
              </CellPrimary>
              <CellPrimary>
                {Number(row.reward.replaceAll(',', '')).toLocaleString('en-US', {
                  maximumFractionDigits: 6
                })}{' '}
                {row.currency}
              </CellPrimary>

              <CellPrimary>{row.eventName}</CellPrimary>
            </BodyRow>
          ))}
        </>
      </Table>
    </TableContainer>
  )
}

const rowTemplate = css`
  grid-template-columns: minmax(115px, 1fr) minmax(170px, 1fr) minmax(125px, 1fr) minmax(
      150px,
      200px
    );

  a {
    text-decoration: underline;
  }
`
