import React, { useEffect } from 'react'
import moment from 'moment'
import { useStore } from 'effector-react'

import useMetaMask from 'src/hooks/useMetaMask'
import LoadingIndicator from 'src/components/LoadingIndicator'
import {
  MetamaskAuth,
  InstallMetamask,
  PendingSignature,
  RequestToSign,
  NotAllowed
} from 'src/components/MetamaskComponents'
import { $auth, $triggerSignature, setAuth } from 'src/state/effector/store'
import { $user } from 'src/state/effector/users/users.store'
import { useHaveAccess } from 'src/hooks/useAccessController'
import { UserRoles } from 'src/constants'

const ProtectedRoute = ({ children }: any) => {
  const { account, isLoading } = useMetaMask()

  const { ethereum } = window as any
  const isMetamaskInstalled = typeof ethereum !== 'undefined'
  const auth = useStore($auth)
  const user = useStore($user)
  const triggerSignature = useStore($triggerSignature)

  const { isNotAllowed } = useHaveAccess({
    roles: [UserRoles.SuperAdmin, UserRoles.Admin, UserRoles.Viewer]
  })

  useEffect(() => {
    if (auth?.[account]?.logoutTime && moment(auth?.[account]?.logoutTime).isBefore(moment())) {
      delete auth?.[account]
      setAuth(auth)
    }
  }, [account, auth, triggerSignature])

  if (!isMetamaskInstalled) {
    return <InstallMetamask />
  }

  if (!account) {
    return <MetamaskAuth />
  }

  if (triggerSignature) {
    return <PendingSignature />
  }

  if (!auth?.[account]) {
    return <RequestToSign />
  }

  if (user && isNotAllowed) {
    return <NotAllowed />
  }

  // if (!user && location.pathname.includes('admin')) {
  //   return <Navigate to="/" />
  // }

  if (isLoading) {
    return <LoadingIndicator />
  }

  return children
}

export default ProtectedRoute
