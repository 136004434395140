import { Link } from 'react-router-dom'
import styled from '@emotion/styled'
import { colors } from 'src/constants/colors'
import { css } from '@emotion/react'

interface StylesProps {
  isFullMode: boolean
  mobile?: boolean
  isShow?: boolean
  isMaintenanceModeActive?: boolean
}

const navigationWidth = '272px'
const navigationWidthSm = '72px'

export const NavigationWrapper = styled.div<StylesProps>`
  display: flex;
  transition-duration: 0.5s;
  height: calc(
    100vh - ${({ isMaintenanceModeActive }) => (isMaintenanceModeActive ? '37px' : '0px')}
  );

  width: 100%;
  max-width: ${navigationWidth};
  min-width: ${navigationWidth};
  flex-direction: column;
  background-color: ${colors.$primaryDarkLight};
  padding-top: ${({ mobile }) => (mobile ? '30px' : '46px')};
  position: ${({ mobile }) => (mobile ? 'fixed' : 'default')};
  z-index: 1300;

  @media screen and (max-width: 1000px) {
    transform: ${({ isShow }) => (!isShow ? 'translateX(-100%)' : 'translateX(0)')};
  }

  ${({ isFullMode }) =>
    !isFullMode &&
    css`
      min-width: ${navigationWidthSm};
      width: ${navigationWidthSm};

      span {
        // width: 0%;
        // transition-timing-function: ease-in-out;
      }
    `}
`

export const NavItemText = styled.span<StylesProps>`
  width: 100%;
  transition: font-weight 0s, width 0.5s;
  transition-timing-function: ease-in-out;
  overflow: hidden;

  ${({ isFullMode }) =>
    !isFullMode &&
    css`
      width: 0%;
      transition-timing-function: ease-in-out;
    `}
`

export const NavListWrapper = styled.div<StylesProps>`
  margin-top: 75px;
  width: 100%;
  display: flex;
  justify-content: ${({ isFullMode }) => (isFullMode ? 'flex-start' : 'center')};
  align-items: ${({ isFullMode }) => (isFullMode ? 'flex-start' : 'center')};
  flex-direction: column;
  margin-bottom: 46px;

  .active {
    position: relative;
    ::before {
      content: '';
      position: absolute;
      width: 3px;
      height: 100%;
      top: 0px;
      left: 0px;
      border-radius: 4px;
      background-color: white;
    }
    opacity: 1;
    font-weight: 700;

    svg,
    img {
      margin-left: -3px;
    }
  }
`

export const NavItem = styled.div<StylesProps>`
  width: 100%;
  font-weight: 700;
  font-size: 16px;
  cursor: pointer;
  // height: 22px;
  transition-duration: 0.5s;

  padding-left: ${({ isFullMode }) => (isFullMode ? '40px' : '24px')};
  padding-right: ${({ isFullMode }) => (isFullMode ? '20px' : '0')};

  display: flex;
  align-items: center;

  opacity: 0.5;
  color: white;

  &:hover {
    opacity: 1;
  }
  span {
    margin-left: 12px;
  }
`

export const MenuWrapper = styled(NavItem)`
  opacity: 1;
`

export const NavLinkWrapper = styled(NavItem)`
  /* margin-bottom: 30px; */
  height: 40px;
  margin: 10px 0px;
  .icon {
    width: 20px;
    min-width: 20px;
    display: flex;
    align-items: center;
  }
`

export const Footer = styled('div')`
  margin-top: auto;
  color: ${colors.$primaryLight};
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  .content {
    padding-left: 24px;
    margin-top: 24px;
    font-size: 12px;
    font-weight: 300;
    line-height: 100%;
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
`

export const LinkOverrides = styled(Link)`
  background: transparent;
  color: ${colors.$primaryLight};
  border: 0;
  padding: 0;
  text-decoration: underline;
  cursor: pointer;
`

export const SwitchContainer = styled.div`
  padding-bottom: 24px;
  margin: 0 -40px;
  margin-bottom: 24px;
  padding-left: 40px;
  padding-right: 29px;
  opacity: 1 !important;
  border-bottom: 1px solid ${colors.$footer};
`
export const SwitchContainerSm = styled.div`
  padding-left: 19px;
  height: 100%;
  padding-bottom: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
`

export const Hr = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${colors.$footer};
`

export const FundsMenu = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 46px;
  overflow: auto;
  .title {
    padding-left: 30px;
    font-size: 16px;
    font-weight: 400;
    color: #73787d;
  }
  /* .active {
    position: relative;
    ::before {
      content: '';
      position: absolute;
      width: 4px;
      height: 100%;
      top: 0px;
      left: 0px;
      border-radius: 4px;
      background-color: white;
    }
    .label {
      font-weight: 700;
    }
  } */
`

export const FundRow = styled.div<{ open: boolean }>`
  padding-left: 30px;
  ${({ open }) =>
    open &&
    css`
      position: relative;
      ::before {
        content: '';
        position: absolute;
        top: 10px;
        left: 0px;
        width: 3px;
        height: calc(100% - 10px);
        background-color: white;
        border-radius: 4px;
      }
    `};
  .header {
    display: flex;
    align-items: center;
    gap: 4px;
    height: 40px;
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
    .arrow {
      transform: rotate(-90deg);
      transition: transform 500ms ease-in-out;
      ${({ open }) =>
        open &&
        css`
          transform: rotate(0deg);
        `}
    }
    .icon {
      display: flex;
      font-size: 13px;
      font-weight: 700;
      width: 20px;
      height: 20px;
      padding: 4px 3px;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      background: #4ab5cc;
      color: ${colors.$primaryDarkLight};
      margin-right: 8px;
    }
  }

  .list {
    padding-left: 24px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    font-size: 14px;
    line-height: 100%;
    color: ${colors.$secondary2};
    > div {
      cursor: pointer;
    }

    > div[class='active-item'] {
      font-weight: 700;
      color: white;
    }

    height: 0px;
    overflow: hidden;
    transition: height 500ms ease-in-out;
    ${({ open }) =>
      open &&
      css`
        height: 200px;
      `}
  }
`
