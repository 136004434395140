import React, { useCallback, useState } from 'react'
import { Box, css } from '@mui/system'
import { Button, TableContainer } from '@mui/material'
import moment from 'moment/moment'
import styled from '@emotion/styled'

import { ROLES, useUserRole } from 'src/hooks/useAccessController'
import { colors } from 'src/constants/colors'
import { useFundData } from 'src/hooks/use-fund-data'
import { UserRoles } from 'src/constants'
import { TeamMember } from 'src/types/common.types'
import useMetaMask from 'src/hooks/useMetaMask'

import { renderNameWithTruncate, Table, HeaderRow, BodyRow, CellPrimary } from '../Table'
import { TeamModal, EModalTypes } from './TeamModal'
import { AddressesCell } from '../Table/AddressesCell'

const headerData: string[] = ['Name', 'Wallet address', 'Role', 'Registration', 'Fund', '']

interface Props {
  data: TeamMember[]
  getTeamMembers: (page: number) => void
  page: number
}

export const TeamTable = ({ data, getTeamMembers, page }: Props) => {
  const [editData, setEditData] = useState<TeamMember | null>(null)

  const { fundId } = useFundData()
  const { account } = useMetaMask()

  const handleOpenModal = (value: TeamMember) => {
    setEditData(value)
  }
  const handleCloseModal = () => {
    getTeamMembers(page)
    setEditData(null)
  }

  const userRole = useUserRole()

  const isNotAllowedEditBtn = useCallback(
    (accessRole: UserRoles, row: TeamMember) => {
      if (!account || row.user.ethAddress.toLowerCase() === account.toLowerCase()) return true
      const roles = [UserRoles.Admin, UserRoles.SuperAdmin]
      if (userRole === UserRoles.SuperAdmin) {
        return false
      } else if (roles.includes(userRole)) {
        return !(ROLES.indexOf(userRole) > ROLES.indexOf(accessRole))
      } else {
        return true
      }
    },
    [userRole, account]
  )

  return (
    <Box>
      <TableContainer>
        <Table aria-label="customized table" sx={{ minWidth: fundId ? '800px' : '900px' }}>
          <HeaderRow rowTemplate={rowTemplate(fundId)}>
            {headerData.map((header, index) =>
              fundId && index === 4 ? null : <CellPrimary key={header}>{header}</CellPrimary>
            )}
          </HeaderRow>
          <>
            {data.length
              ? data.map((row, idx) => (
                  <StyledBodyRow
                    key={row.id}
                    deactivated={row.deactivated}
                    rowTemplate={rowTemplate(fundId)}
                  >
                    <CellPrimary>
                      {renderNameWithTruncate(row.name || row.user.name || '-')}
                    </CellPrimary>
                    <CellPrimary>
                      <AddressesCell address={row.user.ethAddress} id={`${idx}_address`} />
                    </CellPrimary>
                    <StyledRoleCellPrimary>
                      {row.role === UserRoles.SuperAdmin ? 'Super-Admin' : row.role}
                      <span>{row.deactivated ? ' (deactivated)' : ''}</span>
                    </StyledRoleCellPrimary>
                    <CellPrimary>{moment(row.createdAt).format('DD MMM YYYY')}</CellPrimary>
                    {!fundId && (
                      <CellPrimary>
                        {row.role === UserRoles.SuperAdmin ? 'All' : row.fund.name}
                      </CellPrimary>
                    )}
                    <CellPrimary>
                      <Button
                        color="info"
                        onClick={() => handleOpenModal(row)}
                        disabled={isNotAllowedEditBtn(row.role, row)}
                        sx={{
                          textTransform: 'none',
                          textDecoration: 'underline',
                          fontWeight: 500,
                          fontSize: '14px',
                          marginTop: '-4px',
                          color: colors.$darkBlue
                        }}
                      >
                        Edit
                      </Button>
                    </CellPrimary>
                  </StyledBodyRow>
                ))
              : '-'}
          </>
        </Table>
      </TableContainer>
      {editData && (
        <TeamModal
          isOpen
          type={EModalTypes.Edit}
          handleClose={handleCloseModal}
          initialValues={editData}
        />
      )}
    </Box>
  )
}

const rowTemplate = (fundId?: number) =>
  fundId
    ? css`
        grid-template-columns: repeat(4, minmax(170px, 1fr)) 100px;
      `
    : css`
        grid-template-columns: repeat(3, minmax(170px, 1fr)) repeat(2, minmax(135px, 1fr)) 100px;
      `

const StyledRoleCellPrimary = styled(CellPrimary)`
  text-transform: capitalize;
  span {
    text-transform: none;
  }
`

const StyledBodyRow = styled(BodyRow)<{ deactivated?: boolean }>`
  ${({ deactivated }) =>
    deactivated &&
    css`
      *:not(svg, path, .copied, button) {
        color: ${colors.$primaryLight} !important;
      }
    `};
`
