import styled from '@emotion/styled'
import { css } from '@mui/material'

import { colors } from 'src/constants/colors'

interface Props {
  mobile?: boolean
  isMaintenanceModeActive?: boolean
  withoutMaxWidth?: boolean
}

export const AdminLayoutWrapper = styled('div')<Props>`
  display: flex;
  background-color: ${colors.$primaryDarkLight};
  color: white;
  height: calc(
    100vh - ${({ isMaintenanceModeActive }) => (isMaintenanceModeActive ? '37px' : '0px')}
  );
  max-height: 100vh;
  transition: left 0.3s;
`

export const BoxWrapper = styled('div')<Props>`
  height: ${({ mobile, isMaintenanceModeActive }) =>
    mobile ? '100vh' : `calc(100vh - (${isMaintenanceModeActive ? '83px' : '46px'}))`};
  width: 100%;
  border-radius: ${({ mobile }) => (mobile ? '8px 8px 0px 0px' : '30px 30px 0px 0px')};
  background-color: #fdfcfc;
  /* background-color: white; */
  color: black;
  margin-top: ${({ mobile }) => (mobile ? '0' : '46px')};
  margin-right: ${({ mobile }) => (mobile ? '0' : '20px')};

  overflow-y: scroll;
  overflow-x: hidden;
  padding-bottom: 30px;
  .MuiInputBase-root {
    background-color: white;
  }
  .MuiButton-root {
    border-radius: 0px;
    line-height: 19px;
  }
  ${({ withoutMaxWidth, mobile }) =>
    !withoutMaxWidth &&
    css`
      > div:first-of-type {
        max-width: 1532px;
      }
      > div:last-of-type {
        max-width: ${mobile ? '936px' : '1532px'};
      }
    `}
`

export const ContentWrapper = styled('div')<Props>`
  padding: ${({ mobile }) => (mobile ? '0 16px' : '0 44px 0px 100px')};
  /* height: 100%;; */
`
