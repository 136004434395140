import React from 'react'
import { useStore } from 'effector-react'
import { Outlet } from 'react-router-dom'

import Header from '../Header'
import Footer from '../Footer'
import { $isMaintenanceModeActive, $isNotAllowedServer } from '../../state/effector/store'
import { MaintenanceModePage } from 'src/pages/MaintenanceModePage'
import { useAccessController } from '../../hooks/useAccessController'
import { UserRoles } from 'src/constants'
import useMetaMask from '../../hooks/useMetaMask'

const AppWrapper = () => {
  const isMaintenanceModeActive = useStore($isMaintenanceModeActive)
  const isNotAllowedServer = useStore($isNotAllowedServer)
  const { isAuth } = useMetaMask()

  const { isNotAllowed: isNotAllowedForUser } = useAccessController(UserRoles.Viewer)

  return (
    <>
      <Header />
      {(isMaintenanceModeActive && isAuth && isNotAllowedForUser) || isNotAllowedServer ? (
        <MaintenanceModePage />
      ) : (
        <Outlet />
      )}
      <Footer />
    </>
  )
}

export default AppWrapper
