import React, { lazy } from 'react'
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom'
import { useStore } from 'effector-react'

import AppLayout from 'src/components/AppLayout'
import AdminLayout from 'src/components/AdminLayout'
import AppWrapper from 'src/components/AppWrapper'
import AuthLayout from 'src/components/AuthLayout'
import { Dashboard as DashboardPage } from 'src/components/AdminPanel/fund/dashboard'
import { Investors as InvestorsPage } from 'src/components/AdminPanel/fund/investors'
import { Investments as InvestmentsPage } from 'src/components/AdminPanel/fund/investments'
import { ManualAirdrop as ManualAirdropPage } from 'src/components/AdminPanel/fund/airdrops/manual-airdrop'
import { Payments as PaymentsPage } from 'src/components/AdminPanel/fund/payments'
import { $fundsList } from 'src/state/effector/store'
import { UserRoles } from 'src/constants'
import { LogsPage, TeamPage, AirdropsPage, FundsPage } from 'src/pages/AdminPanel'
import { useHaveAccess } from 'src/hooks/useAccessController'

import ProtectedRoute from './ProtectedRoute'

const MainPage = lazy(() => import('./MainPage'))
const PrivacyAndPolicy = lazy(() => import('./PrivacyAndPolicy'))
const NotFoundPage = lazy(() => import('./NotFound'))

const App = () => {
  const { isAllowed: isSuperAdmin, isNotAllowed: isNotSuperAdmin } = useHaveAccess({
    roles: [UserRoles.SuperAdmin]
  })
  const fundsList = useStore($fundsList)

  return (
    <Router>
      <Routes>
        <Route element={<AuthLayout />}>
          <Route element={<AppLayout />}>
            {/* Application routes */}
            <Route element={<AppWrapper />}>
              <Route path="*" element={<NotFoundPage />}></Route>
              <Route
                path="/"
                element={
                  <ProtectedRoute>
                    <MainPage />
                  </ProtectedRoute>
                }
              ></Route>

              <Route path="/privacy-policy" element={<PrivacyAndPolicy />}></Route>
            </Route>
          </Route>

          {/* Admin panel routes */}
          <Route path="/admin" element={<AdminLayout />}>
            <Route
              index
              path="*"
              element={
                fundsList[0] && (
                  <Navigate
                    to={
                      isNotSuperAdmin
                        ? `./fund/${fundsList[0].name.replaceAll(' ', '-')}/dashboard`
                        : './funds'
                    }
                  />
                )
              }
            ></Route>
            {isSuperAdmin && (
              <Route
                path="funds"
                element={
                  <ProtectedRoute>
                    <FundsPage />
                  </ProtectedRoute>
                }
              />
            )}
            {isSuperAdmin && (
              <Route
                path="team"
                element={
                  <ProtectedRoute>
                    <TeamPage />
                  </ProtectedRoute>
                }
              />
            )}
            {isSuperAdmin && (
              <Route
                path="logs"
                element={
                  <ProtectedRoute>
                    <LogsPage />
                  </ProtectedRoute>
                }
              />
            )}
            <Route
              path="fund/:fund/dashboard"
              element={
                <ProtectedRoute>
                  <DashboardPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="fund/:fund/investors"
              element={
                <ProtectedRoute>
                  <InvestorsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="fund/:fund/prospects"
              element={
                <ProtectedRoute>
                  <InvestorsPage prospects />
                </ProtectedRoute>
              }
            />
            <Route
              path="fund/:fund/investments"
              element={
                <ProtectedRoute>
                  <InvestmentsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="fund/:fund/airdrops"
              element={
                <ProtectedRoute>
                  <AirdropsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="fund/:fund/airdrops/manual"
              element={
                <ProtectedRoute>
                  <ManualAirdropPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="fund/:fund/payments"
              element={
                <ProtectedRoute>
                  <PaymentsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="fund/:fund/team"
              element={
                <ProtectedRoute>
                  <TeamPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="fund/:fund/logs"
              element={
                <ProtectedRoute>
                  <LogsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="logs"
              element={
                <ProtectedRoute>
                  <LogsPage />
                </ProtectedRoute>
              }
            />
          </Route>
        </Route>
      </Routes>
    </Router>
  )
}

export default App
