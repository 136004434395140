import React from 'react'
import styled from '@emotion/styled'
import { Box, Typography } from '@mui/material'

import { colors } from 'src/constants/colors'
import { isMobile } from 'src/utils/isMobile'
import { WhitelistTypes } from 'src/state/effector/users/user.types'
import { RadioButton } from 'src/components/common'

interface Props {
  type: string
  disabled?: boolean
  onClick: (val: string) => void
}

export const ActionSelect = ({ type = 'add', disabled, onClick }: Props) => {
  return (
    <Container>
      <Label>Action</Label>

      <WhitelistItem
        status="add"
        disabled={disabled || type === 'add'}
        onClick={() => !disabled && onClick('add')}
      >
        <RadioButton checked={type === 'add'} disabled={disabled} />+ Add
      </WhitelistItem>

      <WhitelistItem
        status="subtract"
        disabled={disabled || type === 'subtract'}
        onClick={() => !disabled && onClick('subtract')}
      >
        <RadioButton checked={type === 'subtract'} disabled={disabled} />- Subtract
      </WhitelistItem>
    </Container>
  )
}

interface StyledProps {
  mobile: boolean
}

interface WhitelistItemProps {
  status: string
  disabled: boolean
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const Label = styled.div`
  font-weight: 500;
  color: ${colors.$secondary};
`

const WhitelistItem = styled('div')<WhitelistItemProps>`
  background: #f8fafc;
  cursor: ${({ disabled }) => !disabled && 'pointer'};

  border: 1px solid #d4dbe7;
  border-radius: 8px;
  padding: 12px;
  width: 100%;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;

  color: ${({ status }) => (status === 'add' && '#0FD884') || (status === 'subtract' && '#E55252')};

  display: flex;
  justify-content: flex-start;
  align-items: center;

  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

  img {
    margin-right: 10px;
  }
`
