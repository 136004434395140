import React, { useLayoutEffect, Suspense } from 'react'

import { Box, css } from '@mui/material'
import styled from '@emotion/styled'
import { Outlet, useLocation } from 'react-router-dom'
import { useStore } from 'effector-react'

import useMetaMask from '../../hooks/useMetaMask'
import { $isMaintenanceModeActive } from '../../state/effector/store'
import LoadingIndicator from '../LoadingIndicator'
import EditNameModal from '../Modal/EditNameModal'
import { MaintenanceModeOnBlock } from '../AdminPanel/MaintenanceMode/MaintenanceModeOnBlock'
import { useAccessController } from '../../hooks/useAccessController'
import { UserRoles } from 'src/constants'

const AppLayout = () => {
  const location = useLocation()

  const { isAuth } = useMetaMask()

  const { isAllowed: isUser } = useAccessController(UserRoles.User, true)
  const { isAllowed: isViewer } = useAccessController(UserRoles.Viewer, true)
  const isMaintenanceModeActive = useStore($isMaintenanceModeActive)

  //Effects
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0)
  }, [location.pathname])

  const showMaintenanceStrip = isAuth && isMaintenanceModeActive && !isUser && !isViewer

  return (
    <>
      <Suspense fallback={<LoadingIndicator />}>
        <EditNameModal />
        {showMaintenanceStrip && <MaintenanceModeOnBlock />}
        <BoxWrapper className="test" showMaintenanceStrip={showMaintenanceStrip}>
          <Outlet />
        </BoxWrapper>
      </Suspense>
    </>
  )
}

const BoxWrapper = styled(Box)<{ showMaintenanceStrip: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  ${({ showMaintenanceStrip }) =>
    showMaintenanceStrip &&
    css`
      margin-top: 37px;
    `}
`

export default AppLayout
