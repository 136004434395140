import React from 'react'
import { Typography, Box } from '@mui/material'
import styled from '@emotion/styled'
import { AppLogo } from '../Logo'
import { colors } from '../../constants/colors'
import { isMobile } from '../../utils/isMobile'
// import { Navigate } from "react-router-dom";
import { Link } from 'react-router-dom'

const Footer = () => {
  const mobile = isMobile()

  // const openInNewTab = (url: string) => {
  // <Navigate to="/privacy-policy" replace={true} />;
  // window.open(url, "_blank", "noopener,noreferrer");
  // }

  return (
    <FooterWrapper>
      <Box padding="34px 0px 24px">
        <AppLogo logoView={'light'} />
      </Box>
      <Typography
        variant={mobile ? 'h6' : 'subtitle2'}
        sx={{ textAlign: 'center', fontWeight: 'bold', marginBottom: '69px' }}
      >
        Need help?{' '}
        <span style={{ fontWeight: 'normal' }}>
          {' '}
          Click the chat button or email support@tokenee.com
        </span>
      </Typography>
      <Typography
        variant={mobile ? 'h6' : 'subtitle2'}
        sx={{ m: '0 0 24px', opacity: 0.5, textAlign: 'center' }}
      >
        © {new Date().getFullYear()} Tokenee Ltd <br />
        <LinkOverrides to="privacy-policy" target="_blank">
          Privacy & Cookies Policy
        </LinkOverrides>
        {/* <a
          target="_blank"
          style={{
            background: "transparent",
            color: "#fff",
            border: 0,
            padding: 0,
            cursor: "pointer",
            textDecoration: "underline",
          }}
          onClick={() => navigate("privacy-policy")}
        >
          Privacy & Cookies Policy
        </a> */}
      </Typography>
    </FooterWrapper>
  )
}

export default Footer

const LinkOverrides = styled(Link)`
  background: transparent;
  color: #fff;
  border: 0;
  padding: 0;
  cursor: pointer;
  text-decoration: underline;
`

const FooterWrapper = styled('div')`
  background: ${() => colors.$footer};
  color: ${({ theme }: any) => theme.palette.footer};
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 599px) {
  }
`
