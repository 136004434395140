import React, { useMemo, useState } from 'react'
import { Box, TableContainer, Tooltip, css } from '@mui/material'
import moment from 'moment'
import styled from '@emotion/styled'
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined'

import { EWhitelistStatuses, ResponseWithPagination } from 'src/state/response/response.types'
import { colors } from 'src/constants/colors'
import { truncateAddress } from 'src/utils/truncateAddress'
import { UserRoles } from 'src/constants'
import { useHaveAccess } from 'src/hooks/useAccessController'
import { useIsOpen } from 'src/hooks/use-is-open'
import wlAccepted from 'src/assets/wl-accepted.svg'
import wlPending from 'src/assets/wl-pending.svg'
import wlRejected from 'src/assets/wl-rejected.svg'
import { Investor } from 'src/types/common.types'

import { Table, HeaderRow, BodyRow, CellPrimary } from '../../Table'
import { HideUnhideFunctionality } from '../../Table/HideUnhideFunctionality'
import { AddressesCell } from '../../Table/AddressesCell'
import { EmptyStateBox } from '../../common'
import { AllotTokens } from './allot-tokens-modal'
import { InvestorModal } from './investor-modal'

const headerDataProspects = [
  'Registration date',
  'Investor',
  'Introducer',
  'Whitelist status',
  'Tokens',
  ''
]
const headerDataInvestors = [
  'Investor from',
  'Investor',
  'Introducer',
  'Whitelist status',
  'Tokens',
  ''
]

interface Props {
  fetchInvestors: (data: Record<string, any>) => Promise<void>
  investors: ResponseWithPagination<any>
  prospects: boolean
  isPastInvestor: boolean
  showHidden: boolean
}

const whitelistIcon = {
  [EWhitelistStatuses.ACCEPTED]: <img src={wlAccepted} alt="wlAccepted" />,
  [EWhitelistStatuses.PENDING]: <img src={wlPending} alt="wlPending" />,
  [EWhitelistStatuses.REJECTED]: <img src={wlRejected} alt="wlRejected" />
} as Record<EWhitelistStatuses, JSX.Element>

export const InvestorsTable = ({
  investors,
  fetchInvestors,
  prospects,
  isPastInvestor,
  showHidden
}: Props) => {
  const [ethAddress, setEthAddress] = useState('')
  const [investor, setInvestor] = useState<Investor | null>(null)

  const { isAllowed: isAdmin } = useHaveAccess({ roles: [UserRoles.SuperAdmin, UserRoles.Admin] })

  const { isOpen: isAllotTokensOpen, close: closeAllotTokens, open: openAllotTokens } = useIsOpen()

  const headerData = useMemo(
    () => (prospects ? headerDataProspects : headerDataInvestors),
    [prospects]
  )

  const investments = investors.totalInvestments || 0

  const onAddTx = (address: string) => {
    setEthAddress(address)
    openAllotTokens()
  }

  const onCloseAddTx = async () => {
    setEthAddress('')
    closeAllotTokens()
    await fetchInvestors({ page: 1 })
  }

  const onEdit = (data: Investor) => {
    if (!isAdmin) return
    setInvestor(data)
  }

  const onCloseEdit = () => {
    setInvestor(null)
    fetchInvestors({ page: 1 })
  }

  const getTruncatedAddress = (address: string) => truncateAddress(address, 2)

  const renderAddressesRow = (row: Investor, id: string, isIntroducerColumn = false) => {
    const address = isIntroducerColumn ? row?.introducer?.walletAddress || '' : row.walletAddress
    const name = isIntroducerColumn ? row?.introducer?.name || '' : row.name
    const truncatedAddress = getTruncatedAddress(address)

    return (
      <AddressesCell
        address={address}
        name={name}
        id={id}
        isNoAccount={truncatedAddress === 'No Account'}
        isNoAccountMsg={'dApp not completed'}
      />
    )
  }

  const triggerGetUsers = () => {
    fetchInvestors({ page: investors.page })
  }

  const whitelistStatusText = {
    [EWhitelistStatuses.ACCEPTED]: 'Approved',
    [EWhitelistStatuses.PENDING]: 'Pending',
    [EWhitelistStatuses.REJECTED]: 'Rejected'
  } as Record<EWhitelistStatuses, string>

  return (
    <>
      {isAllotTokensOpen && <AllotTokens ethAddress={ethAddress} onClose={onCloseAddTx} />}
      {investor && <InvestorModal initialValues={investor} onClose={onCloseEdit} />}

      {investors?.items?.length ? (
        <>
          <TableContainer>
            <Table aria-label="customized table" sx={{ minWidth: '900px' }}>
              <HeaderRow rowTemplate={rowTemplate}>
                {headerData.map((header) => (
                  <CellPrimary key={header}>{header}</CellPrimary>
                ))}
              </HeaderRow>
              <>
                {investors?.items
                  ? investors.items.map((row: Investor, idx: number) => {
                      let investmentInPercents
                      if (row.investment) {
                        investmentInPercents = investments
                          ? Number(((row.investment / investments) * 100).toFixed(2)) * 1
                          : '-'
                      } else {
                        investmentInPercents = 0
                      }
                      const date = prospects ? row.createdAt : row.investorFrom

                      return (
                        <BodyRow key={`${row.id}-${idx}`} rowTemplate={rowTemplate}>
                          <CellPrimary>
                            {date ? (
                              <Tooltip
                                title={
                                  <>
                                    {moment(date).format('DD MMM YYYY')}
                                    &nbsp;(Your time)
                                    <br />
                                    {moment(date).utc().format('DD MMM YYYY')}
                                    &nbsp;(GMT)
                                  </>
                                }
                                placement="top"
                                arrow
                              >
                                <div>{moment(date).utc().format('DD MMM YYYY')}</div>
                              </Tooltip>
                            ) : (
                              '-'
                            )}

                            {row.hidden && (
                              <HideIconWrapper mt={1}>
                                <VisibilityOffOutlinedIcon fontSize="small" />
                              </HideIconWrapper>
                            )}
                          </CellPrimary>
                          <CellPrimary>{renderAddressesRow(row, `${idx}-1`, false)}</CellPrimary>
                          <CellPrimary>{renderAddressesRow(row, `${idx}-2`, true)}</CellPrimary>
                          <CellPrimary>
                            {row.whitelistStatus ? (
                              <WhiteListStatusBox>
                                {whitelistIcon[row.whitelistStatus]}
                                {whitelistStatusText[row.whitelistStatus]}
                              </WhiteListStatusBox>
                            ) : (
                              'Not Registred'
                            )}
                          </CellPrimary>
                          <CellPrimary>
                            {row.investment.toLocaleString('en-US')}{' '}
                            {!prospects && <>({investmentInPercents}%)</>}
                          </CellPrimary>
                          <StyledActionCell>
                            <AddTx
                              isDisabled={
                                row.whitelistStatus !== EWhitelistStatuses.ACCEPTED || !isAdmin
                              }
                              onClick={() => {
                                if (
                                  row.whitelistStatus === EWhitelistStatuses.ACCEPTED &&
                                  isAdmin
                                ) {
                                  onAddTx(row.walletAddress)
                                }
                              }}
                            >
                              Add txn
                            </AddTx>
                            <StyledWrapper>
                              <HideUnhideFunctionality
                                data={row}
                                callback={() => triggerGetUsers()}
                                disabled={!isAdmin}
                                onEdit={(data) => onEdit(data)}
                              />
                            </StyledWrapper>
                          </StyledActionCell>
                        </BodyRow>
                      )
                    })
                  : null}
              </>
            </Table>
          </TableContainer>
        </>
      ) : (
        <EmptyStateBox message="No records found" />
      )}
    </>
  )
}

const rowTemplate = css`
  grid-template-columns: minmax(140px, 1fr) repeat(2, minmax(170px, 1fr)) 115px 150px 150px;
  > div {
    align-self: center;
  }
`

const AddTx = styled.div<{ isDisabled: boolean }>`
  cursor: pointer;
  color: ${colors.$blue} !important;
  text-decoration: underline;
  ${({ isDisabled }) =>
    isDisabled &&
    css`
      color: ${colors.$disabledBg2} !important;
      cursor: not-allowed;
    `}
`

const StyledActionCell = styled(CellPrimary)`
  display: flex;
  align-items: center;
  gap: 16px;
`

const WhiteListStatusBox = styled(Box)`
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${colors.$black};
`

const HideIconWrapper = styled(Box)`
  margin-top: 13px;
  margin-bottom: -10px;
  height: 20px;
  svg {
    fill: #293f6a;
  }
`
const StyledWrapper = styled(Box)`
  width: 30px;
`
