import React, { useEffect, useMemo, useState } from 'react'
import { useStore } from 'effector-react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Box, useMediaQuery } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'

import {
  $fundsList,
  $isMaintenanceModeActive,
  $isShowNavigation,
  setIsShowNavigation
} from 'src/state/effector/store'
import PeopleOutlinedIcon from 'src/assets/people_outlined_icon.svg'
import dropdownIcon from 'src/assets/dropdown.svg'
import FundsIcon from 'src/assets/funds.svg'
import LogsIcon from 'src/assets/logs.svg'
import { UserRoles } from 'src/constants'
import { useUserRole } from 'src/hooks/useAccessController'

import {
  NavigationWrapper,
  MenuWrapper,
  NavListWrapper,
  NavLinkWrapper,
  Footer,
  NavItemText,
  LinkOverrides,
  Hr,
  FundsMenu,
  FundRow
} from './styles'

interface NavList {
  name: string
  path: string
  icon: JSX.Element
  isNotAllowed?: (role: UserRoles) => boolean
}

const navList: NavList[] = [
  {
    name: 'Funds',
    path: '/admin/funds',
    icon: <img src={FundsIcon} />
  },
  {
    name: 'Team',
    path: '/admin/team',
    icon: <img src={PeopleOutlinedIcon} />
  },
  {
    name: 'Logs',
    path: '/admin/logs',
    icon: <img src={LogsIcon} />
  }
]

const menuList = [
  'Dashboard',
  'Investors',
  'Prospects',
  'Investments',
  'Airdrops',
  'Payments',
  'Team',
  'Logs'
]

export const Navigation = () => {
  const [extendedFund, setExtendedFund] = useState(0)

  const funds = useStore($fundsList)
  const isShowNavigation = useStore($isShowNavigation)
  const isMaintenanceModeActive = useStore($isMaintenanceModeActive)

  const { fund = '' } = useParams<{ fund?: string }>()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const isLaptop = useMediaQuery('@media(min-width: 1000px)')

  const userRole = useUserRole()

  const fundColors = ['#4AB5CC', '#4D4ACC', '#CC4ABF', '#934ACC', '#CC4A52', '#C9CC4A']

  useEffect(() => {
    if (fund && funds.length) {
      const selectedId = funds.find(({ name }) => name.replaceAll(' ', '-') === fund)?.id
      if (selectedId) {
        setExtendedFund(selectedId)
      }
    }
  }, [fund, funds])

  const handleMenu = () => !isLaptop && setIsShowNavigation(false)

  const currentYear = useMemo(() => {
    return new Date().getFullYear()
  }, [])

  useEffect(() => {
    if (!isLaptop) setIsShowNavigation(false)
  }, [isLaptop])

  const goToFundPage = (name: string, page: string) => {
    navigate(`/admin/fund/${name.replaceAll(' ', '-')}/${page}`)
  }

  const isFundPageActive = (name: string, page: string) => {
    return pathname.includes(name.replaceAll(' ', '-')) && pathname.includes(page)
  }

  return (
    <NavigationWrapper
      isFullMode={true}
      mobile={!isLaptop}
      isShow={isShowNavigation}
      isMaintenanceModeActive={isMaintenanceModeActive}
    >
      <MenuWrapper onClick={handleMenu} isFullMode={true}>
        <MenuIcon fontSize="small" />
        <NavItemText isFullMode={true}>
          <Box display="flex" alignItems="center" fontSize={18}>
            TOKENEE
          </Box>
        </NavItemText>
      </MenuWrapper>
      {userRole === UserRoles.SuperAdmin && (
        <NavListWrapper isFullMode={true}>
          {navList.length
            ? navList.map((item, idx) =>
                item.isNotAllowed && item.isNotAllowed(userRole) ? null : (
                  <NavLinkWrapper
                    key={`${item.name}-${idx}`}
                    // to={item.path}
                    className={pathname.includes(item.path) ? 'active' : ''}
                    isFullMode={true}
                    onClick={() => {
                      navigate(item.path)
                      if (!isLaptop) setIsShowNavigation(false)
                    }}
                  >
                    <div className="icon">{item.icon}</div>
                    <NavItemText isFullMode={true}>{item.name}</NavItemText>
                  </NavLinkWrapper>
                )
              )
            : null}
        </NavListWrapper>
      )}
      <Hr style={{ marginTop: userRole !== UserRoles.SuperAdmin ? '46px' : '0px' }} />

      <FundsMenu>
        <div className="title">Funds</div>
        {funds.map(({ name, id }, index) => (
          <FundRow
            open={extendedFund === id}
            key={id}
            className={`${pathname.includes(name.replaceAll(' ', '-')) ? 'active' : ''}`}
          >
            <div
              className="header"
              onClick={() => {
                setExtendedFund(extendedFund === id ? 0 : id)
              }}
            >
              <img className="arrow" src={dropdownIcon} alt="dropdownIcon" />
              <div
                className="icon"
                style={{
                  ...(fundColors[index] && {
                    backgroundColor: fundColors[index]
                  })
                }}
              >
                {name[0]}
              </div>
              <div className="label">{name}</div>
            </div>
            <div className="list">
              {menuList.map((item) => (
                <div
                  key={item}
                  className={isFundPageActive(name, item.toLowerCase()) ? 'active-item' : ''}
                  onClick={() => {
                    goToFundPage(name, item.toLowerCase())
                    if (!isLaptop) setIsShowNavigation(false)
                  }}
                >
                  {item}
                </div>
              ))}
            </div>
          </FundRow>
        ))}
      </FundsMenu>

      {/* {!isShowFullMenu && (
        <SwitchContainerSm>
          <MaintenanceModeSwitcher />
        </SwitchContainerSm>
      )} */}

      {isLaptop ? (
        <>
          <Footer>
            <Hr />
            <div className="content">
              {/* <SwitchContainer>
              <MaintenanceModeSwitcher isFullMode={isShowFullMenu} />
            </SwitchContainer> */}
              <div>© {currentYear} Tokenee Ltd.</div>
              <div>
                <LinkOverrides to={'/privacy-policy'} target="_blank">
                  <div>{`Privacy & Cookies Policy`}</div>
                </LinkOverrides>
              </div>
            </div>
          </Footer>
        </>
      ) : null}
    </NavigationWrapper>
  )
}
