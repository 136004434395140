import React from 'react'
import styled from '@emotion/styled'
import { SelectChangeEvent } from '@mui/material/Select/SelectInput'

import SearchBar from 'src/components/SearchBar'
import { ButtonTransparent } from 'src/components/common'
import { MEDIA_WIDTHS } from 'src/constants'

import { WhitelistSelect } from './whitelist-select'

interface Props {
  setFilters: any
  filters: Record<string, any>
  prospects: boolean
}

export const Filters = ({ prospects, filters, setFilters }: Props) => {
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const search = event.currentTarget.value
    const name = event.currentTarget.name

    setFilters((state: any) => ({ ...state, [name]: search }))
  }

  const onChangeWhitelist = (e: SelectChangeEvent) => {
    const { value } = e.target
    setFilters((state: any) => ({ ...state, whitelistStatus: value === 'ALL' ? null : value }))
  }

  const onClearFiters = () => {
    setFilters({
      investorSearch: '',
      introducerSearch: '',
      ...(prospects && {
        whitelistStatus: null
      })
    })
  }

  return (
    <Container>
      <SearchBar
        name="investorSearch"
        value={filters.investorSearch}
        onChange={handleSearch}
        placeholder="Search by Wallet or Name"
      />
      <SearchBar
        name="introducerSearch"
        value={filters.introducerSearch}
        onChange={handleSearch}
        placeholder="Search by Introducer"
      />
      {prospects && (
        <WhitelistSelect value={filters.whitelistStatus || 'ALL'} onChange={onChangeWhitelist} />
      )}
      <ClearFiltersButton onClick={onClearFiters}>Clear</ClearFiltersButton>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  gap: 20px;
  > * {
    flex: 1;
  }

  @media (max-width: ${MEDIA_WIDTHS.upToSmall}px) {
    flex-direction: column;
  }
`

const ClearFiltersButton = styled(ButtonTransparent)`
  background-color: #1e2223 !important;
  color: white;
  text-transform: none;
  white-space: nowrap;
  padding: 15px 22px !important;
  max-width: 136px;
  @media (max-width: ${MEDIA_WIDTHS.upToSmall}px) {
    max-width: 100%;
  }
`
