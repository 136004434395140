import React from 'react'
import styled from '@emotion/styled'
import { InputLabel, MenuItem, Select } from '@mui/material'
import { SelectChangeEvent } from '@mui/material/Select/SelectInput'

import SearchBar from 'src/components/SearchBar'
import { colors } from 'src/constants/colors'
import { RadioButton } from 'src/components/common'
import { ButtonTransparent } from 'src/components/common'
import { MEDIA_WIDTHS } from 'src/constants'

interface Props {
  handleFilters: any
  filters: Record<string, any>
}

export const Filters = ({ filters, handleFilters }: Props) => {
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const search = event.currentTarget.value

    handleFilters((state: any) => ({ ...state, investorSearch: search }))
  }

  const onClearFiters = () => {
    handleFilters({
      investorSearch: '',
      operation: 'all'
    })
  }

  return (
    <Container>
      <SearchBar
        name="investorSearch"
        value={filters.investorSearch}
        onChange={handleSearchChange}
        placeholder="Search by Name or Wallet"
      />
      <SelectFilter
        name="operation"
        value={filters.operation}
        onChange={handleFilters}
        options={[
          { label: 'Show All', value: 'all' },
          { label: 'Token addition', value: 'supplement' },
          { label: 'Token subtraction', value: 'subtract' }
        ]}
      />
      <ClearFiltersButton onClick={onClearFiters}>Clear</ClearFiltersButton>
    </Container>
  )
}

interface SelectProps {
  name: string
  value: string
  onChange: any
  options: Array<{ label: string; value: string }>
}

const SelectFilter = ({ name, value, onChange, options }: SelectProps) => {
  const getSelectedOption = () => {
    const item = options.find((itemSelect) => itemSelect.value === value)
    if (item) return item.label

    return '-'
  }

  const handleSelect = (event: SelectChangeEvent) => {
    const selectedValue: any = event.target.value
    onChange((state: any) => ({ ...state, [name]: selectedValue }))
  }

  return (
    <Select
      id="item_type"
      labelId="item_type_label"
      value={value || ''}
      size="medium"
      onChange={handleSelect}
      displayEmpty
      sx={{
        '.MuiOutlinedInput-notchedOutline': {
          border: `1px solid ${colors.$disabledBg2}`,
          borderRadius: 0
        },
        '.MuiOutlinedInput-input': {
          paddingTop: '14px',
          paddingBottom: '14px'
        },
        '.MuiFormLabel-root div': {
          color: colors.$primaryLight + ' !important'
        }
      }}
      MenuProps={{
        sx: {
          '.MuiPaper-root': {
            borderRadius: 0
          }
        }
      }}
      renderValue={() => (
        <InputLabel
          disableAnimation
          shrink={false}
          focused={value !== undefined}
          id="item_type_label"
          sx={{ cursor: 'pointer', fontSize: 16, fontWeight: 500 }}
        >
          {getSelectedOption()}
        </InputLabel>
      )}
    >
      {options.map((item) => (
        <MenuItemWrapper key={item.label} value={item.value}>
          {item.label}
          <RadioButton checked={item.value === value} />
        </MenuItemWrapper>
      ))}
    </Select>
  )
}

const Container = styled.div`
  display: flex;
  gap: 8px;

  > :first-child {
    flex: 1;
    min-width: 200px;
  }

  > :nth-child(2) {
    width: 200px;
    @media (max-width: ${MEDIA_WIDTHS.upToSmall}px) {
      width: 100%;
    }
  }

  @media (max-width: ${MEDIA_WIDTHS.upToLarge}px) {
    flex-wrap: wrap;
  }
  @media (max-width: ${MEDIA_WIDTHS.upToSmall}px) {
    flex-direction: column;
  }
`

const MenuItemWrapper = styled(MenuItem)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  /* overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; */
  &:hover {
    color: ${colors.$black};
  }
`

const ClearFiltersButton = styled(ButtonTransparent)`
  background-color: #1e2223 !important;
  color: white;
  text-transform: none;
  white-space: nowrap;
  padding: 15px 22px !important;
  max-width: 136px;
  @media (max-width: ${MEDIA_WIDTHS.upToSmall}px) {
    max-width: 100%;
  }
`
