import React, { useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { AxiosError } from 'axios'
import CircularProgress from '@mui/material/CircularProgress'
import styled from '@emotion/styled'

import { ButtonBasePrimary, TextFieldPrimary, ErrorMessage } from 'src/components/common'
import { DateSelect } from 'src/components/AdminPanel/common'
import { colors } from 'src/constants/colors'
import apiService from 'src/services/api'
import CustomModal from 'src/components/Modal/customModal'
import { useIsOpen } from 'src/hooks/use-is-open'
import { ConfirmModal } from 'src/components/Modal/confirm-modal'
import { useFundData } from 'src/hooks/use-fund-data'
import { isAddressValid } from 'src/utils/truncateAddress'

import { ActionSelect } from './action-select'
import { Investment } from 'src/types/common.types'

export const getErrorMessage = (error: AxiosError<Error>) =>
  error?.response?.data?.message || 'Something went wrong'

interface Props {
  onClose: () => void
  onSuccess?: (data?: any) => void

  ethAddress?: string
  isEdit?: boolean
  investment?: Investment
}

interface Values {
  ethAddress: string
  type: string
  tokens: string
  investmentDate: string
}

export const AllotTokens = ({ ethAddress = '', onClose, onSuccess, isEdit, investment }: Props) => {
  const [investor, setInvestor] = useState({ total: 0, id: 0, whitelistStatus: '' })
  const [values, setValues] = useState<Values>({
    ethAddress,
    type: !investment || investment.investment > 0 ? 'add' : 'subtract',
    tokens: Math.abs(investment?.investment || 0).toString() ?? '',
    investmentDate: investment
      ? new Date(investment.createdAt).toISOString()
      : new Date().toISOString()
  })
  const [errors, setErrors] = useState<{ ethAddress: string; tokens: string }>({
    ethAddress: '',
    tokens: ''
  })
  const [touched, setTouched] = useState<{ ethAddress: boolean; tokens: boolean }>({
    ethAddress: false,
    tokens: false
  })
  const [isLoading, setIsLoading] = useState(false)

  const { fundId } = useFundData()
  const { isOpen: isConfirmOpen, close: closeConfirm, open: openConfirm } = useIsOpen()

  const disableSubmit = useMemo(
    () =>
      isLoading ||
      !investor.id ||
      Object.values(errors).some((value) => value) ||
      Object.values(values).some((value) => !value),
    [isLoading, errors, investor]
  )

  const newTokens = useMemo(() => {
    switch (values.type) {
      case 'add':
        return investor.total + +values.tokens

      case 'subtract':
        return investor.total - +values.tokens

      default:
        return investor.total
    }
  }, [investor, values.tokens, values.type])

  const validate = () => {
    const tempErrors = { ethAddress: '', tokens: '' } as { ethAddress: string; tokens: string }
    if (!values.ethAddress) {
      tempErrors.ethAddress = 'Field is required'
    } else if (!isAddressValid(values.ethAddress)) {
      tempErrors.ethAddress = 'Invalid wallet format, should be an Ethereum address'
    } else if (investor?.whitelistStatus) {
      if (investor?.whitelistStatus === 'NOT_REGISTERED') {
        tempErrors.ethAddress = `Investor not registered`
      } else if (investor?.whitelistStatus !== 'ACCEPTED')
        tempErrors.ethAddress = 'This wallet is not approved yet'
    } else if (investor?.id) {
      tempErrors.ethAddress = 'Invalid investor'
    }

    if (!values.tokens) {
      tempErrors.tokens = 'Field is required'
    } else if (+values.tokens === 0) {
      tempErrors.tokens = `Zero is not allowed`
    } else if (values.type === 'subtract' && !isEdit && +values.tokens > investor.total) {
      tempErrors.tokens = `Maximum amount is ${investor.total || 0}`
    }
    setErrors(tempErrors)
  }

  useEffect(() => {
    validate()
  }, [values, investor, touched])

  useEffect(() => {
    if (values.ethAddress && isAddressValid(values.ethAddress)) {
      getInvestorByEthAddress(values.ethAddress)
    }
  }, [values.ethAddress])

  const onSubmit = async () => {
    try {
      closeConfirm()
      setIsLoading(true)
      if (isEdit) {
        await apiService.editInvestment({
          investmentId: investment?.id,
          investment: (investment?.investment || 0) > 0 ? +values.tokens : -+values.tokens,
          investmentDate: values.investmentDate
        })
      } else {
        await apiService.addInvestment({
          id: investor?.id || 0,
          investment: values.type === 'add' ? +values.tokens : -+values.tokens,
          investmentDate: values.investmentDate
        })
      }

      onClose()
      if (onSuccess) {
        onSuccess()
      }
      toast.success(isEdit ? `Record #${investment?.id} saved` : `Saved successfully`)
    } catch (e) {
      toast.error(`Failed to ${values.type} tokens. Please try again.`)
    } finally {
      setIsLoading(false)
    }
  }

  const getInvestorByEthAddress = async (walletAddress: string) => {
    try {
      setIsLoading(true)

      const res = await apiService.getFundInvestorByWallet({ fundId, walletAddress })
      if (res.data) {
        setInvestor(res.data)
      } else {
        setInvestor({ total: 0, id: 0, whitelistStatus: 'NOT_REGISTERED' })
      }
    } catch (e) {
    } finally {
      setIsLoading(false)
    }
  }

  const handleChangeValue = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    let tempValue = value
    if (name === 'tokens') {
      tempValue = value.match(/[0-9]*[.]?[0-9]{0,2}/g)?.[0] || ''
    }
    setTouched((state) => ({ ...state, [name]: true }))
    setValues((state) => ({ ...state, [name]: tempValue }))
  }

  const onTouch = async (e: any) => {
    const { name } = e.target

    setTouched((state) => ({ ...state, [name]: true }))
  }

  const handleChangeAction = (value: string) => {
    setValues((state) => ({ ...state, type: value }))
  }

  const onConfirmAdd = () => {
    onSubmit()
  }

  return (
    <>
      {isConfirmOpen && (
        <ConfirmModal
          isOpen
          handleClose={closeConfirm}
          onConfirm={onConfirmAdd}
          title={
            isEdit
              ? `Confirm saving changes for Investment #${investment?.id}`
              : `Confirm ${values.type === 'add' ? 'Add' : 'Subtract'} Tokens/shares`
          }
        />
      )}
      <CustomModal
        isOpen
        handleClose={onClose}
        title={isEdit ? `Edit investment #${investment?.id}` : 'Allot Tokens/Shares'}
        subtitle={''}
        isLoading={isLoading}
        footer={
          <SubmitButton size="small" disabled={disableSubmit} onClick={openConfirm}>
            {isLoading ? <CircularProgress color="info" size={25} /> : isEdit ? 'Save' : 'Confirm'}
          </SubmitButton>
        }
      >
        <Form className="form">
          {!isEdit && (
            <CurrentTokens>
              <div>Current Tokens/Shares No.</div>
              <div>{Number(investor.total || 0).toLocaleString('en-US')}</div>
            </CurrentTokens>
          )}
          <InputWithLabel>
            <div className="label">To investor</div>

            <TextFieldPrimary
              // onFocus={onTouch}
              name="ethAddress"
              value={values.ethAddress}
              placeholder="Paste wallet address"
              onChange={handleChangeValue}
              disabled={Boolean(ethAddress)}
              invalid={Boolean(errors.ethAddress)}
            />
            <ErrorMessage
              message={errors.ethAddress}
              isError={touched.ethAddress && Boolean(errors.ethAddress)}
            />
          </InputWithLabel>

          <ActionSelect disabled={!!investment} type={values.type} onClick={handleChangeAction} />

          <DateSelect
            value={values.investmentDate}
            onChange={(value) =>
              handleChangeValue({ target: { name: 'investmentDate', value } } as any)
            }
            label="Investment date"
            placeholder="Select date"
          />
          <InputWithLabel>
            <div className="label">Amount of tokens</div>

            <TextFieldPrimary
              name="tokens"
              // onFocus={onTouch}
              InputProps={{
                id: 'tokens',
                className: values.type,
                ...(values.tokens && {
                  startAdornment: values.type === 'add' ? <>+ </> : <>- </>
                })
              }}
              inputProps={{
                style: {
                  ...(values.tokens && {
                    WebkitBoxShadow: '0 0 0 1000px white inset',
                    WebkitTextFillColor: values.type === 'add' ? '#0fd884' : '#e55252'
                  })
                }
              }}
              value={values.tokens}
              onChange={handleChangeValue}
              invalid={Boolean(errors.tokens)}
            />
            <ErrorMessage
              message={errors.tokens}
              isError={touched.tokens && Boolean(errors.tokens)}
            />
          </InputWithLabel>
          {!isEdit && (
            <NewTokens>
              <div>Total Tokens/Shares</div>
              <div>{Number(newTokens).toLocaleString('en-US')}</div>
            </NewTokens>
          )}
        </Form>
      </CustomModal>
    </>
  )
}

const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`

const InputWithLabel = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  .label {
    color: ${colors.$secondary};
    font-weight: 500;
  }
  .error-message {
    margin-top: 0px;
  }
  .add {
    color: #0fd884;
  }
  .subtract {
    color: #e55252;
  }
  input[id='tokens'] {
    ::placeholder {
      color: initial !important;
    }
  }
`

const CurrentTokens = styled.div`
  margin-top: 40px;
  color: ${colors.$secondary};
  padding: 28px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: space-between;
  background: rgba(212, 219, 231, 0.16);
  line-height: 100%;
  > div:last-of-type {
    font-size: 20px;
    font-weight: 500;
    line-height: 100%;
  }
`

const NewTokens = styled.div`
  color: ${colors.$secondary};
  font-weight: 500;
  display: flex;
  flex-direction: column;
  gap: 12px;
  line-height: 100%;
  > div:last-of-type {
    font-size: 24px;
    line-height: 100%;
  }
`

const SubmitButton = styled(ButtonBasePrimary)`
  font-size: 16px;
  margin-top: 24px;
`
