import React from 'react'
import styled from '@emotion/styled'
import { css, SerializedStyles } from '@emotion/react'
import { Box, Tooltip } from '@mui/material'

import { colors } from '../../../constants/colors'
import { CopiedMessage } from '../../Header/AddressDropdown'
type SxType = Record<string, string | number> | SerializedStyles

interface Props {
  style?: Record<string, string | number>
  children: JSX.Element[]
  sx?: SxType
}

export const Table = ({ style, children, sx }: Props) => (
  <Wrap style={style} sx={sx}>
    {children}
  </Wrap>
)

const rowStyle = css`
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));

  > div:first-child {
    padding-left: 20px;
  }

  > div:last-child {
    padding-right: 20px;
  }

  > div {
    padding: 20px 10px;
    word-break: break-word;
    position: relative;
  }

  *:not(svg, path, .copied, button) {
    font-size: 16px !important;
    line-height: 16px;
    color: ${colors.$secondary};
  }
`

const Wrap = styled.div<{ sx?: Record<string, string | number> | SerializedStyles }>`
  overflow-x: auto;
  ${({ sx }) => sx};
  > div {
    position: relative;
    ::after {
      display: block;
      position: absolute;
      z-index: 1;
      content: '';
      left: 20px;
      bottom: 0px;
      width: calc(100% - 40px);
      height: 1px;
      background-color: ${colors.$secondary3};
      opacity: 0.5;
    }
  }
  > div:last-child,
  > div:first-child {
    ::after {
      display: none;
    }
  }
`

export const HeaderRow = styled.div<{ rowTemplate?: SerializedStyles }>`
  ${rowStyle};
  ${({ rowTemplate }) => rowTemplate};
  background-color: #000000;
  > div {
    padding: 16px 10px;
    color: white !important;
    font-weight: 700;
    font-size: 16px;
    line-height: 138%;
  }
`

export const BodyRow = styled.div<{ rowTemplate?: SerializedStyles }>`
  ${rowStyle};
  ${({ rowTemplate }) => rowTemplate};
  background: white;
`

export const CellPrimary = styled.div`
  color: ${colors.$default};
  font-weight: 500;
  font-size: 16px;
`

export const CellSecondary = styled.div`
  color: var(--black70);
  background-color: var(--black0);
`

export const renderNameWithTruncate = (name: string) => {
  return (
    <Tooltip title={name} placement="top" arrow>
      <NameBox>{name}</NameBox>
    </Tooltip>
  )
}

interface IOptions {
  minWidth?: string | number
}

export const renderCellWithTwoRows = (
  firstRowContent: React.ReactNode,
  secondRowContent: React.ReactNode,
  options?: IOptions
) => (
  <CellWithTwoRows {...options}>
    {firstRowContent && <Box width={'100%'}>{firstRowContent}</Box>}
    {secondRowContent && <Box width={'100%'}>{secondRowContent}</Box>}
  </CellWithTwoRows>
)

const CellWithTwoRows = styled('div')<IOptions>`
  height: 45px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: fit-content;
  min-width: ${({ minWidth }) => minWidth || 'auto'};
`

export const CellLightRow = styled('div')`
  color: ${colors.$default};
  font-weight: 500;
  font-size: 16px;
`

export const AddressWrapper = styled('div')`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #0b1730;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  letter-spacing: 1px;

  div:first-child {
    margin-right: 10px;
  }
`

export const CopyIconWrapper = styled(Box)`
  position: relative;
  z-index: 5;
  width: 24px;
  height: 24px;
`

export const MemberWrapper = styled('div')`
  font-weight: 500;
  font-size: 16px;
  line-height: 138%;
  color: ${colors.$default};
  /* margin-top: 10px; */

  display: flex;
  justify-content: flex-start;
  align-items: center;

  img {
    margin-right: 8px;
  }
`

export const CopiedMessageUpdated = styled(CopiedMessage)`
  word-break: normal;
  bottom: 100%;
  right: 0;
  top: unset;
  z-index: 5;
`

const NameBox = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* cursor: pointer; */
`
