import React from 'react'

import { css, TableContainer, Tooltip, Typography, Button } from '@mui/material'
import moment from 'moment'

import { Table, HeaderRow, BodyRow, CellPrimary, renderNameWithTruncate } from '../../Table'
import { useHaveAccess } from 'src/hooks/useAccessController'
import { AddressesCell } from '../../Table/AddressesCell'
import { Investment } from 'src/types/common.types'
import styled from '@emotion/styled'
import { useIsOpen } from 'src/hooks/use-is-open'
import { AllotTokens } from '../investors/allot-tokens-modal'
import { colors } from 'src/constants/colors'
import { UserRoles } from 'src/constants'
import { truncateAddress } from 'src/utils/truncateAddress'

interface Props {
  investments: Investment[]
  onSuccess?: (data?: any) => void
}

const headerData: string[] = ['#', 'Date', 'Investor', 'Token amount', 'Execution', '']

export const InvestmentsTable = ({ investments, onSuccess }: Props) => {
  const [investment, setInvestment] = React.useState<Investment>()
  const { isOpen: isAllotTokensOpen, close: closeAllotTokens, open: openAllotTokens } = useIsOpen()

  const { isAllowed: isAdmin } = useHaveAccess({
    roles: [UserRoles.SuperAdmin, UserRoles.Admin, UserRoles.Editor]
  })

  const openModal = (entry: Investment) => {
    setInvestment(entry)
    openAllotTokens()
  }

  const getTruncatedAddress = (address: string) => truncateAddress(address, 2)

  const renderAddressesRow = (row: Investment, id: string) => {
    const address = row.investor.walletAddress
    const name = row.investor.name
    const truncatedAddress = getTruncatedAddress(address)

    return (
      <AddressesCell
        address={address}
        name={name}
        id={id}
        isNoAccount={truncatedAddress === 'No Account'}
        isNoAccountMsg={'dApp not completed'}
      />
    )
  }

  return (
    <>
      {isAllotTokensOpen && (
        <AllotTokens
          isEdit
          investment={investment}
          ethAddress={investment?.investor.walletAddress}
          onClose={closeAllotTokens}
          onSuccess={onSuccess}
        />
      )}

      <TableContainer>
        <Table aria-label="customized table" sx={{ minWidth: '900px' }}>
          <HeaderRow rowTemplate={rowTemplate(isAdmin)}>
            {headerData.map((header, index) =>
              !isAdmin && index === 5 ? null : <CellPrimary key={header}>{header}</CellPrimary>
            )}
          </HeaderRow>
          <>
            {investments.map((row, idx) => (
              <StyledBodyRow key={row.id} rowTemplate={rowTemplate(isAdmin)}>
                <CellPrimary>{row.id}</CellPrimary>
                <CellPrimary>
                  <Tooltip
                    title={
                      <>
                        {moment(row.createdAt).format('DD MMM YYYY HH:mm')}&nbsp;(Your time)
                        <br />
                        {moment(row.createdAt).utc().format('DD MMM YYYY HH:mm')}&nbsp;(GMT)
                      </>
                    }
                    placement="top"
                    arrow
                  >
                    <div>{moment(row.createdAt).utc().format('DD MMM YYYY HH:mm')}&nbsp;(GMT)</div>
                  </Tooltip>
                </CellPrimary>
                <CellPrimary>{renderAddressesRow(row, row.id.toString())}</CellPrimary>
                <CellPrimary>
                  <Tokens type={row.investment > 0 ? 'add' : 'subtract'}>
                    {row.investment > 0 ? '+' : '-'}
                    {Math.abs(+row.investment).toLocaleString('en-US')}
                  </Tokens>
                </CellPrimary>
                <CellPrimary>Manual</CellPrimary>
                {isAdmin && (
                  <CellPrimary>
                    <Button
                      color="info"
                      onClick={() => openModal(row)}
                      sx={{
                        textTransform: 'none',
                        textDecoration: 'underline',
                        fontWeight: 500,
                        fontSize: '14px',
                        marginTop: '-4px',
                        color: colors.$darkBlue
                      }}
                    >
                      Edit
                    </Button>
                  </CellPrimary>
                )}
              </StyledBodyRow>
            ))}
          </>
        </Table>
      </TableContainer>
    </>
  )
}

const rowTemplate = (isAdmin?: boolean) =>
  isAdmin
    ? css`
        grid-template-columns: 5rem repeat(4, minmax(170px, 1fr)) 8rem;
      `
    : css`
        grid-template-columns: 5rem repeat(4, minmax(170px, 1fr));
      `

const Tokens = styled.div<{ type: string }>`
  color: ${({ type }) => (type === 'add' ? '#1EB173' : '#E55252')} !important;
`

const StyledBodyRow = styled(BodyRow)<{ deactivated?: boolean }>`
  ${({ deactivated }) =>
    deactivated &&
    css`
      *:not(svg, path, .copied, button) {
        color: ${colors.$primaryLight} !important;
      }
    `};
`
