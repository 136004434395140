import React, { useCallback, useEffect, useState, useRef } from 'react'
import { Box } from '@mui/system'
import styled from '@emotion/styled'

import apiService from 'src/services/api'
import { ResponseWithPagination } from 'src/state/response/response.types'
import { Fund } from 'src/types/common.types'

import { TablePagination } from '../Table/Pagination'
import { EmptyStateBox, PageTitle } from '../common'
import { FundsTable } from './funds-table'
import { Filters } from './filters'

export const Funds = () => {
  const [funds, setFundsList] = useState<ResponseWithPagination<Fund[]>>({
    items: [],
    page: 1,
    offset: 10,
    itemCount: 0,
    totalItems: 0,
    totalPages: 1
  })
  const [filters, setFilters] = useState({
    searchByName: '',
    searchByTeamWallet: ''
  })
  const timer = useRef(null as any)

  const getFunds = useCallback(
    async ({ page }: { page: number }) => {
      const res = await apiService.getFunds({
        page,
        offset: 10,
        searchByName: filters.searchByName.trim(),
        searchByTeamWallet: filters.searchByTeamWallet.trim()
      })
      setFundsList(res.data)
    },
    [filters]
  )

  useEffect(() => {
    getFunds({ page: 1 })
  }, [])

  useEffect(() => {
    clearTimeout(timer.current)
    timer.current = setTimeout(() => getFunds({ page: 1 }), 250)
  }, [filters])

  const handleChangePagination = (event: React.ChangeEvent<unknown>, value: number) => {
    getFunds({ page: value })
  }

  return (
    <Container>
      <PageTitle title="Funds" />
      <Filters filters={filters} setFilters={setFilters} />
      {funds?.items?.length ? (
        <>
          <Box>
            <FundsTable items={funds?.items || []} />
          </Box>
          <TablePagination
            response={funds}
            handleChange={handleChangePagination}
            page={funds.page}
          />
        </>
      ) : (
        <EmptyStateBox message="No records found" />
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-top: 32px;
  max-width: 900px;
`
