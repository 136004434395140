import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react'
import { Box, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import styled from '@emotion/styled'
import { useParams } from 'react-router-dom'

import { useHaveAccess } from 'src/hooks/useAccessController'
import { FundName } from 'src/components/AdminPanel/fund/fund-name'
import apiService from 'src/services/api'
import { ResponseWithPagination, TeamMember } from 'src/types/common.types'
import LoadingIndicator from 'src/components/LoadingIndicator'
import { useFundData } from 'src/hooks/use-fund-data'
import { UserRoles } from 'src/constants'
import { Switch, ButtonPrimary } from 'src/components/common'

import { EModalTypes, TeamModal } from './TeamModal'
import { TeamTable } from './TeamTable'
import { TablePagination } from '../Table/Pagination'
import { PageTitle, EmptyStateBox } from '../common'
import { Filters } from './filters'

const OFFSET_VALUE = 10

export const Team = () => {
  const [team, setTeam] = useState<ResponseWithPagination<TeamMember> | null>(null)
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [filters, setFilters] = useState({
    search: '',
    role: []
  })

  const [showPastTeamMembers, setShowPastTeamMembers] = useState(false)

  const { fund = '' } = useParams()

  const timerRef = useRef(null as any)
  const getRef = useRef(false)
  const { fundId } = useFundData()

  const { isAllowed: isAdmin } = useHaveAccess({ roles: [UserRoles.SuperAdmin, UserRoles.Admin] })

  const teamMembersParams = useMemo(() => {
    const data = {
      search: filters.search.trim(),
      offset: OFFSET_VALUE,
      showPastTeamMembers
    } as Record<string, any>

    data.roles = filters.role.join(',') || 'superAdmin,admin,viewer'
    return data
  }, [filters, showPastTeamMembers, fundId])

  const getTeamMembers = useCallback(
    async (page = 1) => {
      try {
        if ((fund && !fundId) || getRef.current) return
        getRef.current = true
        setIsLoading(true)

        if (fundId) {
          const res = await apiService.getFundTeamMembers({
            fundId,
            ...teamMembersParams,
            page
          })
          setTeam(res.data)
        } else {
          const res = await apiService.getTeamMembers({
            ...teamMembersParams,
            page
          })
          setTeam(res.data)
        }
      } catch (error) {
      } finally {
        setIsLoading(false)
        getRef.current = false
      }
    },
    [teamMembersParams, fundId, fund]
  )

  useEffect(() => {
    setTeam(null)
    setFilters({ search: '', role: [] })
  }, [fundId])

  useEffect(() => {
    clearTimeout(timerRef.current)
    timerRef.current = setTimeout(() => getTeamMembers(1), 250)
  }, [getTeamMembers])

  const handleOpenModal = () => setIsOpenModal(true)

  const handleCloseModal = useCallback(() => {
    setIsOpenModal(false)
    getTeamMembers(1)
  }, [getTeamMembers])

  const handleChangeSwitch = (value: boolean) => {
    setShowPastTeamMembers(value)
  }

  const handleChangePagination = (event: React.ChangeEvent<unknown>, value: number) => {
    getTeamMembers(value)
  }

  return (
    <Container>
      {isLoading && <LoadingIndicator />}
      {fundId && <FundName />}
      <Box display="flex" alignItems="center" gap="48px">
        <PageTitle
          title="Team"
          {...(fundId && {
            style: { fontSize: '20px' }
          })}
        />

        {isAdmin && (
          <>
            <ButtonPrimary
              fullWidth={false}
              endIcon={<AddIcon sx={{ width: '20px' }} />}
              sx={{
                width: '202px !important',
                height: '38px',
                minHeight: '38px !important'
              }}
              onClick={handleOpenModal}
            >
              <Typography
                sx={{
                  fontSize: '16px',
                  textTransform: 'capitalize',
                  fontWeight: 700
                }}
              >
                Add Team
              </Typography>
            </ButtonPrimary>
            <TeamModal isOpen={isOpenModal} type={EModalTypes.Add} handleClose={handleCloseModal} />
          </>
        )}
      </Box>
      <Switch
        checked={showPastTeamMembers}
        onChange={handleChangeSwitch}
        label="Show past Team Members"
        type="primary"
      />
      <Filters filters={filters} setFilters={setFilters} />
      {team?.items?.length ? (
        <>
          <TeamTable data={team.items} getTeamMembers={getTeamMembers} page={team.page} />
          <TablePagination response={team} page={team.page} handleChange={handleChangePagination} />
        </>
      ) : (
        <EmptyStateBox message="No records found" />
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-top: 32px;
`
