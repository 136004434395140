import React from 'react'
import { Box, Grid } from '@mui/material'
import styled from '@emotion/styled'

import {
  ButtonBasePrimary,
  ButtonDangerOutlined,
  ButtonPrimaryOutlined
} from 'src/components/common'
import CustomModal from 'src/components/Modal/customModal'
import { colors } from 'src/constants/colors'

interface Props {
  isOpen: boolean
  handleClose: () => void
  onSubmit: () => void
  title: string
  question?: string
  error?: boolean
  confirmText?: string
}
export const ConfirmModal = ({
  isOpen,
  handleClose,
  onSubmit,
  title,
  question,
  error = false,
  confirmText
}: Props) => {
  return (
    <CustomModal
      isOpen={isOpen}
      title={title}
      handleClose={handleClose}
      options={{ maxWidth: 'sm', padding: '40px' }}
    >
      <Grid container spacing={2}>
        {question && (
          <Grid item xs={12}>
            <StyledContentWrapper>{question}</StyledContentWrapper>
          </Grid>
        )}
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={6} md={6}>
              <StyledButtonWrapper>
                <ButtonPrimaryOutlined onClick={handleClose}>Cancel</ButtonPrimaryOutlined>
              </StyledButtonWrapper>
            </Grid>
            <Grid item xs={6} md={6}>
              <StyledButtonWrapper>
                {error ? (
                  <DangerButton onClick={onSubmit}>Archive</DangerButton>
                ) : (
                  <ButtonBasePrimary onClick={onSubmit}>
                    {confirmText || 'Confirm'}
                  </ButtonBasePrimary>
                )}
              </StyledButtonWrapper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </CustomModal>
  )
}

const StyledButtonWrapper = styled(Box)`
  margin-top: 20px;

  button {
    width: 100%;
    text-transform: none;
    font-size: 16px;
    padding: 10px;
    min-height: 0;
    border-radius: 8px;
  }
`

const DangerButton = styled(ButtonDangerOutlined)`
  background-color: ${colors.$error};
  color: white;
  :hover {
    background-color: ${colors.$error};
    color: white;
    opacity: 0.9;
  }
`
const StyledContentWrapper = styled(Box)`
  color: ${colors.$primary};
  /* padding: 0 10%; */
  margin-top: 20px;
  font-weight: 300;
  text-align: center;
`
