import { CircularProgress, Dialog, IconButton, styled } from '@mui/material'
import React, { Children } from 'react'
import { colors } from 'src/constants/colors'
import CloseIcon from '@mui/icons-material/Close'
import isPropValid from '@emotion/is-prop-valid'

interface Props {
  isOpen: boolean
  children?: React.ReactNode
  footer?: React.ReactNode
  handleClose: () => void
  onClose?: () => void
  title: string
  subtitle?: string
  hideCloseIcon?: boolean
  isLoading?: boolean
  isFullMobileMode?: boolean
  options?: {
    padding?: string
    overflow?: string
    textAlign?: string
    bgColor?: string
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | string
  }
  zIndex?: number
}

const maxWidths = ['xs', 'sm', 'md', 'lg', 'xl']

const CustomModal: React.FC<Props> = ({
  isOpen,
  onClose,
  title,
  handleClose,
  hideCloseIcon,
  footer,
  children,
  isFullMobileMode = true,
  options,
  isLoading,
  zIndex
}) => {
  return (
    <DialogStyled
      open={isOpen}
      onClose={onClose}
      className="styled-dialog-prezentation"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      isFullMobileMode={isFullMobileMode}
      maxWidth={options?.maxWidth as any}
      zIndex={zIndex}
    >
      <ModalContainer
        padding={options?.padding}
        overflow={options?.overflow}
        isLoading={isLoading}
        bgColor={options?.bgColor}
      >
        {isLoading && <CircularProgressStyled />}
        {!hideCloseIcon && (
          <CloseModalButton onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </CloseModalButton>
        )}

        <ModalTitle>{title}</ModalTitle>

        {(Children.count(children) &&
          Children.map(children, (child) => (
            <ModalBody className="hide-scroll">{child}</ModalBody>
          ))) || <ModalBody className="hide-scroll">{children}</ModalBody>}

        {footer ? <ModalFooter>{footer}</ModalFooter> : null}
      </ModalContainer>
    </DialogStyled>
  )
}

export default CustomModal

interface DialogStyledProps {
  isFullMobileMode?: boolean
  zIndex?: number
}

const DialogStyled = styled(Dialog)<DialogStyledProps>`
  ${({ zIndex }) => Boolean(zIndex) && 'z-index: ' + zIndex + ';'}
  .MuiPaper-root {
    border-radius: 16px;
    max-width: ${({ maxWidth }) => !maxWidths.includes(maxWidth || '') && (maxWidth || '610px')};
    width: 100%;
    min-width: 320px;
    position: relative;

    @media screen and (max-width: 600px) {
      max-width: ${({ isFullMobileMode }) => (isFullMobileMode ? '100vw' : '610px')};
      max-height: ${({ isFullMobileMode }) => (isFullMobileMode ? '100vh' : 'calc(100% - 64px)')};
      height: ${({ isFullMobileMode }) => (isFullMobileMode ? 'auto' : 'auto')};

      margin: ${({ isFullMobileMode }) => (isFullMobileMode ? '0' : '32px')};
      border-radius: ${({ isFullMobileMode }) => (isFullMobileMode ? '8px' : '16px')};

      ::-webkit-scrollbar {
        /* display: flex !important; */
      }
    }
  }

  max-width: 100vw;
  max-height: 100vh;
`

interface ModalContainerProps {
  padding?: string
  overflow?: string
  bgColor?: string
  isLoading?: boolean
}

const ModalContainer = styled('div')<ModalContainerProps>`
  padding: ${({ padding }) => padding || '20px'};
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  overflow: ${({ overflow }) => overflow || 'hidden'};
  background-color: ${({ bgColor }) => bgColor || 'inherit'};

  opacity: ${({ isLoading }) => (isLoading ? '0.7' : '1')};
  pointer-events: ${({ isLoading }) => (isLoading ? 'none' : 'auto')};

  div {
    pointer-events: ${({ isLoading }) => (isLoading ? 'none' : 'auto')};
  }

  @media screen and (max-width: 600px) {
    padding: ${({ padding }) => `${padding}` || '15px'};
  }
`

const ModalTitle = styled('div')`
  display: flex;
  justify-content: center !important;
  align-items: end;
  text-align: center;

  font-weight: 700;
  font-size: 23px;
  margin-top: 20px;
  padding-bottom: 5px;
`

const CloseModalButton = styled(IconButton)`
  position: absolute;
  z-index: 9999999;
  top: 15px;
  right: 15px;
  color: ${colors.$primaryLight};
  cursor: pointer;

  @media (max-width: 599px) {
    top: 10px;
    right: 10px;
  }
`

const ModalBody = styled('div')`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 1px;
`
const ModalFooter = styled('div')`
  padding-top: 16px;
`

const CircularProgressStyled = styled(CircularProgress)`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  top: 50%;
  z-index: 99999999;
`

export const ModalCentralizeContent = styled('div')`
  text-align: center;
`
