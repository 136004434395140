import * as Yup from 'yup'

export const validationSchema = (isEdit = false) =>
  Yup.object().shape({
    name: Yup.string()
      .min(1)
      .max(90, 'Name must be at most 90 characters')
      .required('Name is required field'),
    email: Yup.string()
      .email('Invalid email format')
      .max(90, 'Email Address must be at most 90 characters'),
    walletAddress: Yup.string().required("Investors's wallet required field"),
    introducerWallet: Yup.string().required('Introducer wallet required field'),
    whitelistStatus: Yup.string().required(),
    investment: Yup.string().test('positive', 'Amount must be positive', (value, { parent }) =>
      parent.whitelistStatus === 'ACCEPTED' && !isEdit ? +(value || '0') >= 0 : true
    )
  })
