import React from 'react'
import web3 from 'web3'

import useMetaMask from '../../hooks/useMetaMask'
import notSupportedImg from '../../assets/network-not-supported.png'
import { ButtonPrimary } from '../common/Button'
import { Container } from './styles'
import { CHAIN_INFO } from '../../constants'

const chainToSwitchName = {
  dev: 'Sepolia',
  staging: 'Sepolia',
  prod: 'Mainnet'
} as { [key: string]: string }

const chainToSwitchId = {
  dev: 11155111,
  staging: 11155111,
  prod: 1
} as { [key: string]: number }

export const WrongNetwork = () => {
  const { provider } = useMetaMask()
  const env = process.env.REACT_APP_ENV || 'dev'

  const changeNetwork = async () => {
    if (!provider) {
      return
    }

    const newChain = chainToSwitchId[env]
    try {
      await provider.provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: web3.utils.toHex(newChain) }]
      })
    } catch (switchError: any) {
      if (switchError.message.includes('Unrecognized chain ID')) {
        try {
          await provider.provider.request({
            method: 'wallet_addEthereumChain',
            params: [{ ...CHAIN_INFO[newChain], chainId: web3.utils.toHex(newChain) }]
          })
          await provider.provider.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: web3.utils.toHex(newChain) }]
          })
        } catch (error) {
          console.error(error)
        }
      }
    }
  }

  return (
    <Container>
      <div>
        <img src={notSupportedImg} alt="notSupportedImg" />
        <div className="title">Network not supported</div>
        <div className="info">
          Please switch to {chainToSwitchName[env]} in order to use the Dapp
        </div>
        <ButtonPrimary onClick={changeNetwork}>
          Switch to {chainToSwitchName[env]} Network
        </ButtonPrimary>
      </div>
    </Container>
  )
}
