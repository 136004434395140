import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react'
import styled from '@emotion/styled'
import moment from 'moment'

import LoadingIndicator from 'src/components/LoadingIndicator'
import apiService from 'src/services/api'
import { colors } from 'src/constants/colors'
import { useFundData } from 'src/hooks/use-fund-data'
import { Investment, ResponseWithPagination } from 'src/types/common.types'

import { useStore } from 'effector-react'

import { EmptyStateBox } from '../../common'
import { InvestmentsTable } from './investments-table'
import { TablePagination } from '../../Table/Pagination'
import { FundName } from '../fund-name'
import { Filters } from './filters'

import { useParams } from 'react-router-dom'
import { $fundsList } from 'src/state/effector/store'

export const Investments = () => {
  const { fund = '' } = useParams<{ fund?: string }>()
  const funds = useStore($fundsList)

  const fundName = useMemo(
    () => funds.find(({ name }) => name.replaceAll(' ', '-') === fund)?.name,
    [funds]
  )

  const [investments, setInvestments] = useState<ResponseWithPagination<Investment> | null>({
    items: []
  } as any)
  const [isLoading, setIsLoading] = useState(true)
  const [filters, setFilters] = useState({
    investorSearch: '',
    operation: 'all'
  })

  const timerRef = useRef(null as any)

  const { fundId } = useFundData()

  const fetchInvestments = useCallback(
    async ({ page }: Record<string, any>) => {
      try {
        setIsLoading(true)
        const res = await apiService.getInvestments({
          offset: 10,
          page,
          fundId,
          investorSearch: filters.investorSearch.trim(),
          ...(filters.operation !== 'all' && {
            operation: filters.operation
          })
        })
        setInvestments(res.data)
      } catch (e) {
      } finally {
        setIsLoading(false)
      }
    },
    [filters, fundId]
  )

  useEffect(() => {
    if (fundId) {
      clearTimeout(timerRef.current)
      timerRef.current = setTimeout(() => fetchInvestments({ page: 1 }), 250)
    }
  }, [filters, fundId])

  const handleChangePagination = (e: any, page: number) => {
    fetchInvestments({ page })
  }

  const onExport = async () => {
    const validFilters = Object.entries(filters).reduce(
      (acc, [key, value]) => ({
        ...acc,
        ...(value && value !== 'all' && { [key]: value })
      }),
      {}
    )
    const res = await apiService.getInvestments({
      page: 1,
      offset: investments?.totalItems || 10,
      fundId,
      ...validFilters
    })

    const data: ResponseWithPagination<Investment[]> = res?.data

    const rows = [
      ['Id', 'Date', `Investor's name`, `Investor's wallet`, 'Token amount', 'Execution']
    ]

    data.items?.forEach((item: any) => {
      const row = [
        item.id,
        moment(item.createdAt).format('YYYY-MM-DD'),
        item.investor?.name || '-',
        item.investor?.walletAddress || '-',
        item.investment || '-',
        'Manual'
      ]

      rows.push(row)
    })

    const csvContent = 'data:text/csv;charset=utf-8,' + rows.map((e) => e.join(',')).join('\n')

    const encodedUri = encodeURI(csvContent)
    const link = document.createElement('a')
    link.setAttribute('href', encodedUri)
    link.setAttribute(
      'download',
      `${fundName}-investments-export-${moment().format('DD-MM-YYYY')}.csv`
    )
    document.body.appendChild(link)

    link.click()
  }

  const onSuccess = () => {
    fetchInvestments({ page: 1 })
  }

  if (!investments) return <LoadingIndicator />

  return (
    <>
      {isLoading && <LoadingIndicator />}

      <Container>
        <FundName />
        <Header>
          <SectionLabel>Investments</SectionLabel>
        </Header>
        <Filters filters={filters} handleFilters={setFilters} />
        {investments.items?.length ? (
          <InvestmentsTable investments={investments.items || []} onSuccess={onSuccess} />
        ) : (
          <EmptyStateBox />
        )}
        <TotalInvestments>
          <div>Total Investments</div>
          <div>{investments.totalInvestments?.toLocaleString('en-US')}</div>
        </TotalInvestments>
        {Boolean(investments.items?.length) && (
          <TablePagination
            response={investments}
            handleChange={handleChangePagination}
            page={investments.page}
            onExport={onExport}
          />
        )}
      </Container>
    </>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-top: 32px;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 48px;
`

const SectionLabel = styled.div`
  font-size: 20px;
  font-weight: 700;
`

const TotalInvestments = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding: 28px;
  color: ${colors.$secondary};
  background: rgba(212, 219, 231, 0.16);
  font-weight: 600;
  line-height: 100%;
  > div:last-of-type {
    font-size: 20px;
    font-weight: 500;
    line-height: 100%;
  }
`
