import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { AxiosError } from 'axios'
import { useFormik } from 'formik'
import { Box, FormControl, Grid, SelectChangeEvent, Typography } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import styled from '@emotion/styled'

import {
  ButtonBasePrimary,
  ButtonBasePrimaryOutlined,
  TextFieldPrimary,
  ErrorMessage
} from 'src/components/common'
import { isMobile } from 'src/utils/isMobile'
import { colors } from 'src/constants/colors'

import { isAddressValid } from 'src/utils/truncateAddress'
import apiService from 'src/services/api'
import { WhitelistTypes, EIntroducerAddressTypes, User } from 'src/state/effector/users/user.types'
import { triggerUsers } from 'src/state/effector/users/users.store'
import { EWhitelistStatuses } from 'src/state/response/response.types'
import CustomModal from 'src/components/Modal/customModal'
import { TREASURY_ADDRESS } from 'src/constants/treasuryAddresses'
import { useFundData } from 'src/hooks/use-fund-data'
import { Investor } from 'src/types/common.types'
import { DateSelect } from 'src/components/AdminPanel/common'

import { WhitelistSelect } from './whitelist-select'
import { validationSchema } from './validation'
import { IntroducerWallet } from './introducer-wallet'

interface Error {
  message: string
}

export const getErrorMessage = (error: AxiosError<Error>) => {
  return error?.response?.data?.message || 'Something went wrong'
}

type StateFields = 'name' | 'email' | 'walletAddress' | 'introducerWallet' | 'investment'

export enum ETableTypes {
  pendingWhitelist = 'pendingWhitelist',
  allTriber = 'allTriber'
}

interface Props {
  onClose?: () => void
  disabled?: boolean
  onSuccess?: (data: any) => void
  initialValues?: Investor
}

interface IWalletErrors {
  walletAddress?: string
  introducerWallet?: string
}

enum EWalletAddressTypes {
  EthAddress = 'walletAddress',
  IntroducerAddress = 'introducerWallet'
}

export type AddWhitelistTypes = EWhitelistStatuses.ACCEPTED | EWhitelistStatuses.PENDING

const defaultValues = {
  name: '',
  email: '',
  walletAddress: '',
  investment: '',
  introducerWallet: TREASURY_ADDRESS,
  whitelistStatus: EWhitelistStatuses.ACCEPTED
}

export const InvestorModal: React.FC<Props> = ({ onClose, onSuccess, initialValues }) => {
  const isAdd = !Boolean(initialValues)
  const isEdit = Boolean(initialValues)

  const [isLoading, setIsLoading] = useState(false)
  const [isModalLoading, setIsModalLoading] = useState(false)
  const [isWithoutIntroducerWallet, setIsWithoutIntroducerWallet] = useState(false)
  const [selectedIntroducerType, setSelectedIntroducerType] = useState<EIntroducerAddressTypes>(
    EIntroducerAddressTypes.NonUS
  )
  const [isValidationLoading, setIsValidationLoading] = useState(false)
  const { fundId } = useFundData()
  const [walletErrors, setWalletErrors] = useState<IWalletErrors>({})
  const [isBothAddressesSimilar, setIsBothAddressesSimilar] = useState(false)

  const [introducer, setIntroducer] = useState<User | null>(null)

  useEffect(() => {
    if (initialValues) {
      setSelectedIntroducerType(
        initialValues?.introducer?.name === 'Mother Tribe'
          ? EIntroducerAddressTypes.NonUS
          : EIntroducerAddressTypes.Specific
      )
    }
  }, [initialValues])

  const onSubmit = async (submitValues: Record<string, string>) => {
    setIsLoading(true)

    if (isAdd) {
      const submitData = {
        whitelistStatus: submitValues.whitelistStatus as WhitelistTypes,
        name: submitValues.name,
        email: submitValues.email,
        walletAddress: submitValues.walletAddress,
        introducerWallet: submitValues.introducerWallet,
        ...(introducer?.id && { introducerId: introducer.id }),
        type:
          selectedIntroducerType === EIntroducerAddressTypes.Specific
            ? 'specificIntroducer'
            : 'motherTribe',
        investment: +submitValues.investment || 0,
        ...(isAdd && {
          investmentDate: submitValues.investmentDate
        }),
        fundId
      }

      await apiService
        .addInvestorToFund(submitData)
        .then(() => {
          handleClose()
          toast.success('Added successfully')
          triggerUsers()
          if (onSuccess) {
            onSuccess(submitData)
          }
        })
        .catch((error) => {
          toast.error('Error: ' + getErrorMessage(error))
        })
        .finally(() => setIsLoading(false))
    }

    if (isEdit) {
      const submitData = {
        whitelistStatus: submitValues.whitelistStatus as WhitelistTypes,
        name: submitValues.name,
        email: submitValues.email,
        id: initialValues?.id
      }

      await apiService
        .editInvestor(submitData as any)
        .then(() => {
          handleClose()
          toast.success('Changes saved')

          // if (tableType === ETableTypes.allTriber) {
          //   triggerUsers()
          //   if (values.whitelistStatus === EWhitelistStatuses.PENDING) {
          //     triggerPendingUsersCount(pendingUsersCountTrigger)
          //   }
          // }
          // if (
          //  pros ||
          //   submitData.whitelistStatus === EWhitelistStatuses.PENDING
          // ) {
          //   triggerPendingUsersCount(pendingUsersCountTrigger)
          //   triggerPendingUsers()
          // }
          // triggerPendingTribersCount(pendingTribersCountTrigger)
          if (onSuccess) {
            onSuccess(submitData)
          }
        })
        .catch(() => toast.error('Error: Changes not saved'))
        .finally(() => setIsLoading(false))
    }
  }

  const formikInitialValues = useMemo(
    () => ({
      name: initialValues?.name || defaultValues.name,
      email: initialValues?.email || defaultValues.email,
      walletAddress: initialValues?.walletAddress || defaultValues.walletAddress,
      investment: initialValues?.investment || defaultValues.investment,
      introducerWallet: initialValues?.introducer?.walletAddress || defaultValues.introducerWallet,
      whitelistStatus: initialValues?.whitelistStatus || defaultValues.whitelistStatus,
      investmentDate: new Date().toISOString()
    }),
    [initialValues]
  )

  const formik = useFormik({
    initialValues: formikInitialValues as any,
    enableReinitialize: true,
    onSubmit,
    validationSchema: validationSchema(isEdit)
  })

  const mobile = isMobile()

  const handleClose = useCallback(() => {
    setIsLoading(true)

    if (onClose) {
      onClose()
    }
    formik.resetForm()
    setIsWithoutIntroducerWallet(false)
    setIsModalLoading(false)
    setSelectedIntroducerType(EIntroducerAddressTypes.NonUS)
    setWalletErrors({})
    setIsValidationLoading(false)
    setIntroducer(null)
    setIsLoading(false)
  }, [formik, defaultValues, onClose])

  // Reset introducer data if introducerWallet not valid
  useEffect(() => {
    if (
      formik.touched.introducerWallet &&
      (walletErrors.introducerWallet || formik?.errors?.introducerWallet)
    ) {
      setIntroducer(null)
    }
  }, [
    formik.touched.introducerWallet,
    walletErrors.introducerWallet,
    formik?.errors?.introducerWallet
  ])

  const validateAddress = async (name: string, value: string) => {
    let errorMessage = ''
    if (!value.length) return null

    if (value.length && !isAddressValid(value)) {
      errorMessage = 'Invalid wallet format, should be an Ethereum address'
      setWalletErrors((prev) => ({ ...prev, [name]: errorMessage }))
      return
    }

    const currentName: 'introducerWallet' | 'investorWallet' =
      name === 'introducerWallet' ? 'introducerWallet' : 'investorWallet'
    const otherName: 'introducerWallet' | 'investorWallet' =
      name !== 'introducerWallet' ? 'introducerWallet' : 'investorWallet'
    const otherFormikName = name !== 'introducerWallet' ? 'introducerWallet' : 'walletAddress'

    const params = {
      [currentName]: value
    }

    // Checking if the addresses are the same
    setIsBothAddressesSimilar(formik.values[otherFormikName] === value)

    // Check for one address
    await apiService
      .validateInvestor({ ...params, fundId })
      .then(({ data }) => {
        errorMessage = ''
        if (currentName === 'introducerWallet') {
          setIntroducer(data)
        }
      })
      .catch((err) => {
        errorMessage = getErrorMessage(err)
      })

    // Rechecking introducerWallet validation
    if (name === EWalletAddressTypes.EthAddress && isBothAddressesSimilar) {
      let otherErrorMessage = ''

      await apiService
        .validateInvestor({ [otherName]: formik.values[otherFormikName], fundId })
        .then(() => {
          otherErrorMessage = ''
        })
        .catch((err) => {
          otherErrorMessage = getErrorMessage(err)
        })

      setWalletErrors((prev) => ({
        ...prev,
        [name]: errorMessage,
        [otherFormikName]: otherErrorMessage
      }))
      return
    }

    setWalletErrors((prev) => ({ ...prev, [name]: errorMessage }))
    return
  }

  const changeAddress = async (name: string, value: string, noError = false) => {
    await formik.setFieldValue(name, value)
    await formik.setFieldTouched(name, true)

    if (noError) return
    setIsValidationLoading(true)
    await validateAddress(name, value)
    setIsValidationLoading(false)
  }

  const handleChangeAddress = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    changeAddress(name, value)
  }

  const handleChangeTokens = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.match(/[0-9]*[.]?[0-9]{0,2}/g)?.[0]
    formik.setFieldValue('investment', value, true)
  }

  const handleChangeWhitelistByValue = (value: WhitelistTypes) => {
    formik.setFieldValue('investment', '')
    const event = { target: { value, name: 'whitelistStatus' } }
    formik.handleChange(event)
  }

  const handleChangeSelectedIntroducerWalletType = (
    event: SelectChangeEvent<EIntroducerAddressTypes>
  ) => {
    const { value } = event.target
    const name = 'introducerWallet'

    switch (value) {
      case EIntroducerAddressTypes.NonUS:
        setSelectedIntroducerType(value)
        setWalletErrors({})
        changeAddress(name, TREASURY_ADDRESS, true)
        setIntroducer(null)
        break

      case EIntroducerAddressTypes.Specific:
        setSelectedIntroducerType(value)
        changeAddress(name, '', true)
        break
    }
  }

  const isError = (fieldName: StateFields) =>
    (formik.touched &&
      formik.touched[fieldName] &&
      formik.errors &&
      formik.errors[fieldName] &&
      true) ||
    ((fieldName === 'walletAddress' || fieldName === 'introducerWallet') &&
      !!walletErrors[fieldName]) ||
    false

  const isValid = useMemo(
    () => !walletErrors.walletAddress && !walletErrors.introducerWallet,
    [walletErrors.walletAddress, walletErrors.introducerWallet]
  )

  return (
    <CustomModal
      isOpen
      handleClose={handleClose}
      title={`${isAdd ? 'Add' : 'Edit'} Investor`}
      subtitle={''}
      isLoading={isModalLoading || isValidationLoading}
      footer={
        <Grid container spacing={2} style={{ paddingTop: '24px' }}>
          <Grid item xs={12} md={6} order={mobile ? 1 : 2}>
            <SubmitButton
              size="small"
              type="submit"
              form="member-modal-form"
              style={{ fontSize: '16px' }}
              disabled={!(formik.isValid && formik.dirty && isValid) || isLoading}
            >
              {isLoading ? (
                <CircularProgress color="info" size={25} />
              ) : (
                (isAdd && 'Add Investor') || (isEdit && 'Save Changes')
              )}
            </SubmitButton>
          </Grid>
          <Grid item xs={12} md={6} order={mobile ? 2 : 1}>
            <CancelButton
              onClick={handleClose}
              size="small"
              variant="outlined"
              disabled={isLoading}
            >
              Cancel
            </CancelButton>
          </Grid>
        </Grid>
      }
    >
      <form onSubmit={formik.handleSubmit} id="member-modal-form">
        <FormControl variant="standard" fullWidth onFocus={formik.handleBlur}>
          <LabelStyled>
            <TypographyWrapperByName mobile={mobile} color={colors.$black} variant="body1">
              Name *
            </TypographyWrapperByName>

            <TextFieldPrimary
              name="name"
              value={formik.values.name}
              placeholder="Name Surname"
              onChange={formik.handleChange}
              invalid={isError('name')}
            />
          </LabelStyled>
          <ErrorMessage message={formik.errors?.name} isError={isError('name')} />
        </FormControl>

        <FormControl variant="standard" fullWidth onFocus={formik.handleBlur}>
          <TypographyWrapper mobile={mobile} color={colors.$footer} variant="body1">
            Email
          </TypographyWrapper>
          <TextFieldPrimary
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            placeholder="Email Address"
            invalid={isError('email')}
          />
          <ErrorMessage message={formik.errors?.email} isError={isError('email')} />
        </FormControl>

        {/* Member's wallet */}
        <FormControl variant="standard" fullWidth onFocus={formik.handleBlur}>
          <TypographyWrapper mobile={mobile} color={colors.$footer} variant="body1">
            {`Investor's wallet *`}
          </TypographyWrapper>
          <TextFieldPrimary
            name="walletAddress"
            value={formik.values.walletAddress}
            onChange={handleChangeAddress}
            invalid={isError('walletAddress')}
            placeholder="Please specify the wallet"
            disabled={isEdit}
          />
          <ErrorMessage
            message={formik.errors?.walletAddress || walletErrors.walletAddress}
            isError={isError('walletAddress')}
          />
        </FormControl>
        {/* Introducer's wallet */}
        <>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <TypographyWrapper mobile={mobile} color={colors.$footer} variant="body1">
              {"Introducer's wallet *"}
            </TypographyWrapper>
          </Box>
          <IntroducerWallet
            value={selectedIntroducerType}
            disabled={isEdit && !isWithoutIntroducerWallet}
            onChange={handleChangeSelectedIntroducerWalletType}
          />
          {selectedIntroducerType === EIntroducerAddressTypes.Specific ? (
            <FormControl variant="standard" fullWidth onFocus={formik.handleBlur}>
              <>
                <TextFieldPrimary
                  sx={{ marginTop: '15px' }}
                  value={formik.values.introducerWallet}
                  name="introducerWallet"
                  onChange={handleChangeAddress}
                  invalid={isError('introducerWallet')}
                  placeholder="Please specify the Triber"
                  disabled={isEdit && !isWithoutIntroducerWallet}
                />
                <ErrorMessage
                  message={formik.errors?.introducerWallet || walletErrors.introducerWallet}
                  isError={isError('introducerWallet')}
                />
              </>
            </FormControl>
          ) : null}
          {introducer?.name && <IntroducerNameBlock mt={1}>{introducer.name}</IntroducerNameBlock>}
        </>

        <FormControl fullWidth onFocus={formik.handleBlur}>
          <WhitelistSelect
            whitelist={formik.values.whitelistStatus as WhitelistTypes}
            onClick={handleChangeWhitelistByValue}
          />
        </FormControl>

        {formik.values.whitelistStatus === 'ACCEPTED' && isAdd && (
          <>
            <DateSelect
              value={formik.values.investmentDate}
              onChange={(value) => formik.setFieldValue('investmentDate', value)}
              label="Investment date"
              placeholder="Select date"
              style={{ marginTop: '15px' }}
            />

            <FormControl variant="standard" fullWidth onFocus={formik.handleBlur}>
              <TypographyWrapper mobile={mobile} color={colors.$footer} variant="body1">
                Allot tokens/shares
              </TypographyWrapper>
              <TextFieldPrimary
                name="investment"
                value={formik.values.investment}
                onChange={handleChangeTokens}
                placeholder="Enter amount of tokens"
                invalid={isError('investment')}
              />
              <ErrorMessage message={formik.errors?.investment} isError={isError('investment')} />
            </FormControl>
          </>
        )}
      </form>
    </CustomModal>
  )
}

interface StyledProps {
  mobile: boolean
}

const LabelStyled = styled('label')`
  & > div {
    width: 100%;
  }
`

const TypographyWrapper = styled(Typography)<StyledProps>`
  margin-top: 15px;
  margin-bottom: 5px;
  font-size: ${({ mobile }) => (mobile ? '14px' : '16px')};
  font-weight: 500;

  span {
    color: ${colors.$secondary2};
    font-size: ${({ mobile }) => (mobile ? '12px' : '14px')};
    font-weight: 400;
  }
`

const TypographyWrapperByName = styled(TypographyWrapper)`
  @media screen and (max-width: 1600px) {
    margin-top: 5px;
  }
`

const CancelButton = styled(ButtonBasePrimaryOutlined)`
  font-size: 16px;
`
const SubmitButton = styled(ButtonBasePrimary)`
  font-size: 16px;
`
const IntroducerNameBlock = styled(Box)`
  font-weight: 400;
  font-size: 12px;
  color: #293f6a;
`
