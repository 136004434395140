import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Box, Typography } from '@mui/material'
import styled from '@emotion/styled'
import { colors } from '../../constants/colors'
import CustomModal from './customModal'
import { ButtonPrimaryShort } from '../common/Button'

const EditNameModal = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [isModalOpen, setIsModalOpen] = useState(Boolean(searchParams.get('editNameModal')))

  useEffect(() => {
    if (searchParams.get('editNameModal')) {
      searchParams.delete('editNameModal')
      setSearchParams(searchParams)
    }
  }, [searchParams, setSearchParams])

  return (
    <CustomModal
      isOpen={isModalOpen}
      handleClose={() => setIsModalOpen(false)}
      title="Need to change your name or email?"
      subtitle={''}
    >
      <StyledDescription>
        If you want to change your name or email <br />
        get in touch with us via <a href="mailto:support@tokenee.com">support@tokenee.com</a>
      </StyledDescription>
      <Box mt={2.5} display="flex" justifyContent="center">
        <StyledButton onClick={() => setIsModalOpen(false)}>Got it</StyledButton>
      </Box>
    </CustomModal>
  )
}

export default EditNameModal

const StyledDescription = styled(Typography)`
  font-weight: 300;
  color: ${colors.$primary};
  text-align: center;
  margin-top: 15px;
  a {
    color: inherit;
    text-decoration: underline;
  }
`

const StyledButton = styled(ButtonPrimaryShort)`
  padding: 0 20px;
`
