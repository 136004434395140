import React, { useEffect, useState } from 'react'
import { Box, CircularProgress } from '@mui/material'
import isEqual from 'lodash/isEqual'
import { useParams } from 'react-router-dom'
import styled from '@emotion/styled'

import apiService from 'src/services/api'
import { GetLogsParams, ILog } from 'src/state/effector/history/logs.types'
import { ResponseWithPagination } from 'src/state/response/response.types'
import { FundName } from 'src/components/AdminPanel/fund/fund-name'
import LoadingIndicator from 'src/components/LoadingIndicator'
import { useFundData } from 'src/hooks/use-fund-data'

import { LogsTable } from './LogsTable'
import { EmptyStateBox, PageTitle } from '../common'
import { TablePagination } from '../Table/Pagination'
import { Filters } from './filters'

const OFFSET_VALUE = 10
let timeoutId = null as any

export const Logs = () => {
  const [filters, setFilters] = useState<Record<string, any>>({
    teamWallet: '',
    actionWallet: '',
    actions: []
  })

  const [logs, setLogs] = useState<ResponseWithPagination<ILog[]> | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  const { fund = '' } = useParams<{ fund?: string }>()
  const { fundId } = useFundData()

  useEffect(() => {
    setLogs(null)
    setFilters({
      teamWallet: '',
      actionWallet: '',
      actions: []
    })
  }, [fundId])

  const getLogs = (page = 1) => {
    if (fund && !fundId) return

    const logsParams: GetLogsParams = {
      page,
      offset: OFFSET_VALUE,
      teamWallet: filters.teamWallet.trim(),
      actionWallet: filters.actionWallet.trim()
    }

    if (!isEqual(filters.actions, [])) logsParams.actionTypes = filters.actions.join(',')
    setIsLoading(true)
    if (fundId) {
      apiService
        .getFundLogs({ fundId, ...logsParams })
        .then((response) => {
          const logsData: ResponseWithPagination<ILog[]> = response?.data
          setLogs(logsData)
        })
        .catch(() => {
          setLogs(null)
        })
        .finally(() => {
          setIsLoading(false)
        })
    } else {
      apiService
        .getLogs(logsParams)
        .then((response) => {
          const logsData: ResponseWithPagination<ILog[]> = response?.data
          setLogs(logsData)
        })
        .catch(() => {
          setLogs(null)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }

  useEffect(() => {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => getLogs(1), 250)
    return () => {
      clearTimeout(timeoutId)
    }
  }, [filters, fundId])

  const onFiltersChange = (data: Record<string, any>) => {
    setFilters(data)
  }

  const handleChangePagination = (event: React.ChangeEvent<unknown>, value: number) => {
    getLogs(value)
  }

  return (
    <Container>
      {isLoading && <LoadingIndicator />}
      {fund && <FundName />}

      <PageTitle
        title="Logs"
        {...(fund && {
          style: { fontSize: '20px' }
        })}
      />
      <Filters filters={filters} setFilters={onFiltersChange} />
      {logs ? (
        <>
          {logs?.items?.length ? (
            <>
              <Box>
                <LogsTable data={logs.items} />
              </Box>
              <TablePagination
                response={logs}
                handleChange={handleChangePagination}
                page={logs.page}
              />
            </>
          ) : (
            <EmptyStateBox message="No records found" />
          )}
        </>
      ) : (
        <Box display="flex" justifyContent="center" alignItems="center" mt={5}>
          <CircularProgress />
        </Box>
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-top: 32px;
`
