import { useStore } from 'effector-react'

import { $user } from 'src/state/effector/users/users.store'
import { UserRoles } from 'src/constants'
import { useFundData } from 'src/hooks/use-fund-data'

export const ROLES: UserRoles[] = [
  UserRoles.User,
  UserRoles.Viewer,
  UserRoles.Editor,
  UserRoles.Admin,
  UserRoles.SuperAdmin
]

// TO DO - replace with 'useHaveAccess' hook
export const useAccessController = (accessRole = UserRoles.User, only = false, onlyNot = false) => {
  let isAllowed
  let isNotAllowed

  const userRole = useUserRole()

  if (only && !onlyNot) {
    isAllowed = accessRole === userRole
    isNotAllowed = !(accessRole === userRole)
  } else if (onlyNot && !only) {
    isAllowed = !(accessRole === userRole)
    isNotAllowed = accessRole === userRole
  } else if (only && onlyNot) {
    isAllowed = accessRole === userRole
    isNotAllowed = accessRole === userRole
  } else {
    const checkIsAllowed = ROLES.indexOf(userRole) >= ROLES.indexOf(accessRole)
    isAllowed = checkIsAllowed
    isNotAllowed = !checkIsAllowed
  }

  return { isAllowed, isNotAllowed }
}

interface HaveAccess {
  roles: Array<UserRoles>
  only?: boolean
  onlyNot?: boolean
  inFund?: boolean
}

const rolesPriority = [UserRoles.Viewer, UserRoles.Admin, UserRoles.SuperAdmin]

export const useUserRole = () => {
  const { fundId } = useFundData()
  const user = useStore($user)
  const isDeactivated = user?.deactivated

  const biggestRoleIndex =
    user?.funds?.reduce((acc, fund) => {
      const roleIndex = rolesPriority.indexOf(fund.role)
      if (acc < roleIndex) {
        return roleIndex
      }
      return acc
    }, -1) ?? -1

  const fundRole = user?.funds?.find((fund) => fund.fundId === fundId)?.role

  const userRole = isDeactivated
    ? UserRoles.User
    : rolesPriority[biggestRoleIndex] || UserRoles.User

  return fundRole || userRole
}

export const useHaveAccess = ({ roles, only = true, onlyNot = false }: HaveAccess) => {
  let isAllowed = false
  let isNotAllowed = true

  const user = useStore($user)
  const { fundId } = useFundData()
  const userRole = useUserRole()

  const fundUserRole = user?.funds?.find((fund) => fund.fundId === fundId)?.role || UserRoles.User

  const role = userRole !== UserRoles.SuperAdmin && fundId ? fundUserRole : userRole

  if (only) {
    const includes = roles.includes(role)
    isAllowed = includes
    isNotAllowed = !includes
  }

  if (onlyNot) {
    const includes = roles.includes(role)
    isAllowed = !includes
    isNotAllowed = includes
  }

  return { isAllowed, isNotAllowed }
}
