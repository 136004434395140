import * as Yup from 'yup'

export const validationSchema = (fundId: number) =>
  Yup.object().shape({
    name: Yup.string()
      .required('Name is required')
      .max(90, 'Name must be at most 90 characters')
      .test('minimum', 'Name must be at least 2 characters', (value = '') =>
        /(\S){2}/g.test(value)
      ),
    ethAddress: Yup.string().required('Member’s wallet required field'),
    role: Yup.string().required(),
    teamMemberOf: Yup.number().test('isRequired', 'Required field', (value, { parent }) => {
      if (parent.role !== 'superAdmin' && !fundId) {
        return Boolean(value)
      }
      return true
    })
  })
