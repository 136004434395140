import React, { useState, useEffect, useCallback } from 'react'
import styled from '@emotion/styled'
import { Box, CircularProgress, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'

import apiService from 'src/services/api'
import usdcIcon from 'src/assets/currency/USDC.svg'
import ethIcon from 'src/assets/currency/ethereum-1.svg'
import { useIsOpen } from 'src/hooks/use-is-open'
import { ButtonPrimary, ButtonPrimaryOutlined } from 'src/components/common'
import { useFundData } from 'src/hooks/use-fund-data'
import { useHaveAccess } from 'src/hooks/useAccessController'
import { MEDIA_WIDTHS, UserRoles } from 'src/constants'

import { InvestorModal } from '../investors/investor-modal'
import { AllotTokens } from '../investors/allot-tokens-modal'
import { FundName } from '../fund-name'
import { ResourceCard } from './resource-card'

const resourcesList = ['eth', 'usdc', 'investors', 'investments']

const resourceTitles = {
  usdc: 'Dividends wallet',
  eth: 'Gas Wallet',
  investors: 'Total Investors',
  investments: 'Total Tokens/Shares'
} as Record<string, string>

const resourceValueText = {
  usdc: 'USDC',
  eth: 'ETH'
} as Record<string, string>

const icons = {
  usdc: <img src={usdcIcon} alt="usdcIcon" width="20px" />,
  eth: <img src={ethIcon} alt="ethIcon" width="20px" />
} as Record<string, JSX.Element>

export const Dashboard = () => {
  const [resources, setResources] = useState<any>({})
  const [isLoading, setIsLoading] = useState(true)

  const { isOpen: isAddInvestorOpen, close: closeAddInvestor, open: openAddInvestor } = useIsOpen()
  const { isOpen: isAllotTokensOpen, close: closeAllotTokens, open: openAllotTokens } = useIsOpen()

  const { fundId } = useFundData()

  const { isAllowed } = useHaveAccess({
    roles: [UserRoles.SuperAdmin, UserRoles.Admin]
  })

  const fetchResources = useCallback(async () => {
    try {
      if (!fundId) return
      setIsLoading(true)

      const response = await apiService.getResources(fundId)
      setResources(response.data)
    } catch (error) {
    } finally {
      setIsLoading(false)
    }
  }, [fundId])

  useEffect(() => {
    fetchResources()
  }, [fetchResources])

  const onCloseAllotTokens = () => {
    closeAllotTokens()
  }

  const onCloseAddInvestor = () => {
    closeAddInvestor()
  }

  const onSuccess = () => {
    fetchResources()
  }

  return (
    <>
      {isAddInvestorOpen && <InvestorModal onClose={onCloseAddInvestor} onSuccess={onSuccess} />}
      {isAllotTokensOpen && <AllotTokens onClose={onCloseAllotTokens} onSuccess={onSuccess} />}

      <Container>
        <FundName />
        <Header>
          <SectionLabel>Dashboard</SectionLabel>
          {isAllowed && (
            <div className="buttons">
              <ButtonPrimaryOutlined
                sx={{
                  width: '202px !important',
                  height: '38px !important',
                  minHeight: '38px !important'
                }}
                onClick={openAllotTokens}
              >
                <Typography
                  sx={{
                    fontSize: '16px',
                    textTransform: 'capitalize',
                    fontWeight: 700
                  }}
                >
                  Allot Shares/Tokens
                </Typography>
              </ButtonPrimaryOutlined>
              <ButtonPrimary
                endIcon={<AddIcon sx={{ width: '20px' }} />}
                sx={{
                  width: '202px !important',
                  height: '38px !important',
                  minHeight: '38px !important'
                }}
                onClick={openAddInvestor}
              >
                <Typography
                  sx={{
                    fontSize: '16px',
                    textTransform: 'capitalize',
                    fontWeight: 700
                  }}
                >
                  Add Investor
                </Typography>
              </ButtonPrimary>
            </div>
          )}
        </Header>
        {!isLoading && Object.keys(resources).length ? (
          <Resources>
            {resourcesList.map((key: any) => {
              const value = resources[key] as any

              return (
                <ResourceCard
                  key={key}
                  icon={icons[key]}
                  title={resourceTitles[key] || key}
                  valueText={resourceValueText[key]}
                  value={Number(value?.balance ?? (value || 0)).toLocaleString('en-US')}
                  address={value?.address}
                  isNotAvailable={value?.balance < value?.treshold}
                />
              )
            })}
          </Resources>
        ) : (
          <Box display="flex" justifyContent="center" py={5}>
            <CircularProgress />
          </Box>
        )}
      </Container>
    </>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-top: 32px;
  max-width: 900px;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
  .buttons {
    display: flex;
    align-items: center;
    gap: 12px;
    flex-wrap: wrap;
  }
`

const SectionLabel = styled.div`
  font-size: 20px;
  font-weight: 700;
`

const Resources = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  > * {
    flex: 1;
  }
  @media (max-width: ${MEDIA_WIDTHS.upToSmall}px) {
    grid-template-columns: 1fr;
  }
`
