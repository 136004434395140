import React, { useState } from 'react'
import styled from '@emotion/styled'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import { truncateAddress } from 'src/utils/truncateAddress'
import { colors } from 'src/constants/colors'
import copyIcon from 'src/assets/copy.svg'

import {
  AddressWrapper,
  CopiedMessageUpdated,
  CopyIconWrapper,
  MemberWrapper,
  renderNameWithTruncate
} from './index'

interface Copied {
  [key: string]: boolean
}

interface IProps {
  address: string
  name?: string
  id: string | number
  isNoAccount?: boolean
  isNoAccountMsg?: string
}

export const AddressesCell: React.FC<IProps> = ({
  address,
  name,
  id,
  isNoAccount,
  isNoAccountMsg
}) => {
  const [copied, setCopied] = useState<Copied>({})

  const onCopy = (copyId: string | number) => {
    setCopied((prev) => ({ ...prev, [copyId]: true }))
    setTimeout(() => setCopied((prev) => ({ ...prev, [copyId]: false })), 1000)
  }

  const getTruncatedAddress = (addressData: string) => truncateAddress(addressData, 2)

  return (
    <div style={{ width: '100%' }}>
      <AddressWrapper>
        <div className="text-to-copy">
          {isNoAccount && !!isNoAccountMsg ? isNoAccountMsg : getTruncatedAddress(address)}
        </div>
        {!isNoAccount && (
          <CopyIconWrapper>
            <CopyToClipboard onCopy={() => onCopy(id)} text={address}>
              <StyledCopyIcon src={copyIcon} alt="copyIcon" className="copy-icon" width="24px" />
            </CopyToClipboard>
            {copied[id] ? (
              <CopiedMessageUpdated className="copied">Copied!</CopiedMessageUpdated>
            ) : (
              ''
            )}
          </CopyIconWrapper>
        )}
      </AddressWrapper>

      {name ? <MemberWrapper>{renderNameWithTruncate(name)}</MemberWrapper> : null}
    </div>
  )
}

const StyledCopyIcon = styled.img`
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    color: ${colors.$lightBlue};
  }
`
