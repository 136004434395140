import ethereumLogoUrl from '../assets/ethereum.svg'
import { ENetworkTypes } from '../types/common.types'

export const SUPPORTED_CHAIN = (
  process.env.REACT_APP_SUPPORTED_CHAIN ? +process.env.REACT_APP_SUPPORTED_CHAIN : 11155111
) as number

export const CHAIN_INFO = {
  1: {
    blockExplorerUrls: ['https://etherscan.io/'],
    chainName: 'Ethereum',
    nativeCurrency: { name: 'ETH', symbol: 'ETH', decimals: 18 },
    iconUrls: ethereumLogoUrl,
    networkType: ENetworkTypes.ETH
  },
  11155111: {
    blockExplorerUrls: ['https://sepolia.etherscan.io/'],
    chainName: 'Sepolia',
    nativeCurrency: { name: 'Sepolia ETH', symbol: 'ETH', decimals: 18 },
    iconUrls: ethereumLogoUrl,
    networkType: ENetworkTypes.SEPOLIA
  }
} as {
  [key: number]: {
    blockExplorerUrls: string[]
    chainName: string
    nativeCurrency: { name: string; symbol: string; decimals: number }
    iconUrls: string
    networkType: ENetworkTypes
  }
}

export const MEDIA_WIDTHS = {
  upToExtremelySmall: 370,
  upToExtraSmall: 599,
  upToSmall: 720,
  upToMedium: 960,
  upToLarge: 1280
}

export enum UserRoles {
  User = 'user',
  Viewer = 'viewer',
  Editor = 'editor',
  Admin = 'admin',
  SuperAdmin = 'superAdmin'
}
