import React from 'react'
import styled from '@emotion/styled'

import { colors } from 'src/constants/colors'

interface LogoProp {
  logoView?: string
  padding?: string
  width?: number | string
  size?: string
}

export const AppLogo = ({ logoView = 'light', size, ...props }: LogoProp) => {
  return <Logo logoView={logoView}>TOKENEE</Logo>
}

const Logo = styled.div<{ logoView: string }>`
  color: ${({ logoView }) => (logoView === 'dark' ? colors.$secondary : 'white')};
  font-family: Roboto;
  font-size: 24px;
  font-weight: 700;
`
