import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useFormik } from 'formik'
import { Box } from '@mui/system'
import { toast } from 'react-toastify'
import { AxiosError } from 'axios'
import { FormControl, Grid, SelectChangeEvent, Typography } from '@mui/material'
import styled from '@emotion/styled'

import { useHaveAccess } from 'src/hooks/useAccessController'
import CustomModal from 'src/components/Modal/customModal'
import { colors } from 'src/constants/colors'
import {
  TextFieldPrimary,
  ErrorMessage,
  ButtonDangerOutlined,
  ButtonPrimary,
  ButtonSuccessOutlined
} from 'src/components/common'
import { isMobile } from 'src/utils/isMobile'
import { isAddressValid } from 'src/utils/truncateAddress'
import apiService, { CreateTeamMember } from 'src/services/api'
import { useFundData } from 'src/hooks/use-fund-data'
import { UserRoles } from 'src/constants'
import { TeamMember } from 'src/types/common.types'

import { validationSchema } from './validation'
import RoleSelector from './RoleSelector'
import { FundsSelector } from './FundsSelector'

export enum EModalTypes {
  Add = 'Add',
  Edit = 'Edit'
}

interface IProps {
  isOpen: boolean
  type: EModalTypes
  ethAddress?: string
  handleClose: () => void
  initialValues?: TeamMember
}

interface IState {
  name: string
  ethAddress: string
  role: UserRoles
  teamMemberOf: number
  deactivated: boolean
}

type StateFields = 'name' | 'ethAddress' | 'role' | 'teamMemberOf'
interface IWalletErrors {
  ethAddress?: string
}

export const getErrorMessage = (error: AxiosError<Error>) =>
  error?.response?.data?.message || 'Something went wrong'

const defaultValues: IState = {
  name: '',
  ethAddress: '',
  role: UserRoles.Viewer,
  teamMemberOf: 0,
  deactivated: false
}

// Add or Edit team modal
export const TeamModal: React.FC<IProps> = ({
  isOpen,
  type = EModalTypes.Add,
  initialValues,
  handleClose
}) => {
  const isAdd = type === EModalTypes.Add
  const isEdit = type === EModalTypes.Edit
  const mobile = isMobile()

  const { fundId } = useFundData()

  const { isNotAllowed } = useHaveAccess({ roles: [UserRoles.Viewer], onlyNot: true })

  const [values, setValues] = useState<IState>(defaultValues)
  const [walletErrors, setWalletErrors] = useState<IWalletErrors>({})
  const [isLoading, setIsLoading] = useState(false)
  const [isValidationLoading, setIsValidationLoading] = useState(false)

  const changeAddress = async (name: string, value: string, noError = false) => {
    await formik.setFieldValue(name, value)
    await formik.setFieldTouched(name, true)

    // if (noError) return

    // await validateAddress(name, value)
  }

  const handleChangeAddress = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    changeAddress(name, value)
  }

  const handleChangeRole = async (value: UserRoles) => {
    const name = 'role'
    await formik.setFieldValue(name, value)
    await formik.setFieldTouched(name, true)
  }

  const handleChangeFunds = (event: SelectChangeEvent<number>) => {
    formik.setFieldValue('teamMemberOf', event.target.value, true)
  }

  const onSubmit = async (submitValues: IState) => {
    setIsLoading(true)
    if (
      submitValues.role === UserRoles.SuperAdmin ||
      initialValues?.role === UserRoles.SuperAdmin
    ) {
      if (isAdd) {
        const submitData = {
          name: submitValues.name,
          walletAddress: submitValues.ethAddress,
          role: submitValues.role,
          deactivated: submitValues.deactivated,
          funds: []
        } as CreateTeamMember

        await apiService
          .createTeamMember(submitData)
          .then(() => {
            handleCloseModal()
            toast.success('Added successfully')
          })
          .catch(({ response }) => {
            console.log('response', response)
            if (response?.data?.message) {
              toast.error('Error: ' + getErrorMessage(response))
            } else toast.error('Error: Not added')
          })
          .finally(() => setIsLoading(false))
        return
      } else {
        const submitData = {
          name: submitValues.name,
          walletAddress: submitValues.ethAddress,
          role: submitValues.role,
          deactivated: submitValues.deactivated,
          ...(submitValues.role !== 'superAdmin' && {
            funds: [submitValues.teamMemberOf || fundId]
          }),
          id: initialValues?.id
        } as CreateTeamMember

        await apiService
          .editTeamMember(submitData)
          .then(() => {
            handleCloseModal()
            toast.success('Changes saved')
          })
          .catch(() => {
            toast.error('Changes not saved')
          })
          .finally(() => setIsLoading(false))
        return
      }
    }

    if ((fundId || submitValues.teamMemberOf) && isAdd) {
      const submitData = {
        name: submitValues.name.trim(),
        walletAddress: submitValues.ethAddress,
        role: submitValues.role,
        deactivated: submitValues.deactivated,
        fundId: fundId || submitValues.teamMemberOf
      } as CreateTeamMember

      await apiService
        .createFundTeamMember(submitData)
        .then(() => {
          handleCloseModal()
          toast.success('Added successfully')
        })
        .catch(({ response }) => {
          console.log('response', response)
          if (response?.data?.message) {
            toast.error('Error: ' + getErrorMessage(response))
          } else toast.error('Error: Not added')
        })
        .finally(() => setIsLoading(false))
      return
    }
    if ((fundId || initialValues?.fundId) && isEdit) {
      const submitData = {
        name: submitValues.name.trim(),
        walletAddress: submitValues.ethAddress,
        role: submitValues.role,
        deactivated: submitValues.deactivated,
        fundId: fundId || initialValues?.fundId
      }
      await apiService
        .editFundTeamMember({ ...submitData, id: initialValues?.id })
        .then(() => {
          handleCloseModal()
          toast.success('Changes saved')
        })
        .catch(() => {
          toast.error('Changes not saved')
        })
        .finally(() => setIsLoading(false))
      return
    }

    // if (!fundId && isAdd) {
    //   const submitData = {
    //     name: submitValues.name,
    //     walletAddress: submitValues.ethAddress,
    //     role: submitValues.role,
    //     deactivated: submitValues.deactivated,
    //     ...(submitValues.role !== 'superAdmin' && {
    //       funds: submitValues.teamMemberOf
    //     })
    //   } as CreateTeamMember

    //   await apiService
    //     .createTeamMember(submitData)
    //     .then(() => {
    //       handleCloseModal()
    //       toast.success('Added successfully')
    //     })
    //     .catch(({ response }) => {
    //       console.log('response', response)
    //       if (response?.data?.message) {
    //         toast.error('Error: ' + getErrorMessage(response))
    //       } else toast.error('Error: Not added')
    //     })
    //     .finally(() => setIsLoading(false))
    //   return
    // }
    // if (isEdit) {
    //   const submitData = {
    //     name: submitValues.name,
    //     walletAddress: submitValues.ethAddress,
    //     role: submitValues.role,
    //     deactivated: submitValues.deactivated,
    //     ...(submitValues.role !== 'superAdmin' && {
    //       funds: submitValues.teamMemberOf
    //     }),
    //     id: initialValues?.id
    //   } as CreateTeamMember

    //   await apiService
    //     .editTeamMember(submitData)
    //     .then(() => {
    //       handleCloseModal()
    //       toast.success('Changes saved')
    //     })
    //     .catch(() => {
    //       toast.error('Changes not saved')
    //     })
    //     .finally(() => setIsLoading(false))
    //   return
    // }

    if (isLoading) setIsLoading(false)
  }

  const formikInitialValues = useMemo(() => {
    return {
      name: initialValues?.name || initialValues?.user?.name || values.name,
      ethAddress: initialValues?.user?.ethAddress || values.ethAddress,
      role: initialValues?.role || values.role,
      teamMemberOf: initialValues?.fundId || values.teamMemberOf,
      deactivated: initialValues?.deactivated || values.deactivated
    }
  }, [initialValues, values])

  const formik = useFormik({
    initialValues: formikInitialValues,
    enableReinitialize: true,
    onSubmit,
    validationSchema: validationSchema(fundId)
  })

  const validateAddress = async () => {
    if (isEdit) return

    let errorMessage = ''
    const params = {
      ethAddress: formik.values.ethAddress,
      role: formik.values.role,
      ...(formik.values.role !== 'superAdmin' && {
        fund: fundId || initialValues?.fundId || formik.values.teamMemberOf
      })
    }

    if (!formik.values.ethAddress.length) return null

    if (formik.values.ethAddress.length && !isAddressValid(formik.values.ethAddress)) {
      errorMessage = 'Invalid wallet format, should be an Ethereum address'
      setWalletErrors((prev) => ({ ...prev, ethAddress: errorMessage }))
      return
    }

    setIsValidationLoading(true)
    await apiService
      .addTeamMemberValidate(params)
      .then(() => {
        errorMessage = ''
      })
      .catch((err) => {
        errorMessage = getErrorMessage(err)
      })
    setWalletErrors((prev) => ({
      ...prev,
      ethAddress: errorMessage.split(' Funds:')[0]
    }))
    setIsValidationLoading(false)
  }

  useEffect(() => {
    if (
      (formik.values.role === UserRoles.SuperAdmin && formik.values.ethAddress) ||
      (formik.values.ethAddress && formik.values.role && formik.values.teamMemberOf) ||
      (formik.values.ethAddress && fundId)
    ) {
      validateAddress()
    }
  }, [formik.values.ethAddress, formik.values.role, formik.values.teamMemberOf, fundId])

  const handleCloseModal = useCallback(() => {
    const handleReset = () => {
      formik.resetForm()
      setValues(defaultValues)
      setWalletErrors({})
      setIsLoading(false)
      setIsValidationLoading(false)
    }

    handleReset()
    handleClose()
  }, [handleClose, formik, defaultValues])

  // Close modal if no access to activate team member
  useEffect(() => {
    if (isNotAllowed) {
      handleCloseModal()
    }
  }, [isNotAllowed])

  const changeDeactivateMember = async (deactivate: boolean) => {
    try {
      if (!initialValues?.id) return
      setIsLoading(true)
      await apiService.deactivateFundTeamMember({ id: initialValues.id, deactivate })

      handleCloseModal()
      toast.success('Changes saved')
    } catch (e) {
      toast.error('Changes not saved')
    } finally {
      setIsLoading(false)
    }
  }

  const isError = (fieldName: StateFields) =>
    (formik.touched &&
      formik.touched[fieldName] &&
      formik.errors &&
      formik.errors[fieldName] &&
      true) ||
    (fieldName === 'ethAddress' && !!walletErrors[fieldName]) ||
    false

  const isValid = useMemo(() => !walletErrors.ethAddress, [walletErrors.ethAddress])

  return (
    <CustomModal
      isOpen={isOpen}
      title={`${isAdd ? 'Add' : 'Edit'} Team Member`}
      handleClose={handleCloseModal}
      isLoading={isLoading || isValidationLoading}
    >
      <form onSubmit={formik.handleSubmit} id="team-modal-form">
        <FormControl variant="standard" fullWidth onFocus={formik.handleBlur}>
          <LabelStyled>
            <TypographyWrapperByName mobile={mobile} color={colors.$black} variant="body1">
              Name{' '}
            </TypographyWrapperByName>

            <TextFieldPrimary
              name="name"
              value={formik.values.name}
              placeholder="Name Surname"
              onChange={formik.handleChange}
              invalid={isError('name')}
              disabled={initialValues?.deactivated}
            />
          </LabelStyled>
          <ErrorMessage message={formik.errors?.name} isError={isError('name')} />
        </FormControl>

        <FormControl variant="standard" fullWidth onFocus={formik.handleBlur}>
          <TypographyWrapper mobile={mobile} color={colors.$footer} variant="body1">
            Wallet Address
          </TypographyWrapper>
          <TextFieldPrimary
            name="ethAddress"
            value={formik.values.ethAddress}
            onChange={handleChangeAddress}
            invalid={isError('ethAddress')}
            placeholder="Please specify the wallet"
            disabled={type === EModalTypes.Edit}
          />
          <ErrorMessage
            message={formik.errors?.ethAddress || walletErrors.ethAddress}
            isError={isError('ethAddress')}
          />
        </FormControl>

        <FormControl fullWidth>
          <RoleSelector
            role={formik.values.role || UserRoles.Viewer}
            onClick={handleChangeRole}
            disabled={initialValues?.deactivated}
          />
        </FormControl>

        {formik.values.role !== UserRoles.SuperAdmin && !fundId && (
          <FormControl variant="standard" fullWidth onFocus={formik.handleBlur}>
            <TypographyWrapper mobile={mobile} color={colors.$footer} variant="body1">
              Access to Fund
            </TypographyWrapper>
            <FundsSelector
              value={formik.values.teamMemberOf}
              onChange={handleChangeFunds}
              disabled={isEdit && initialValues?.role !== UserRoles.SuperAdmin}
            />
            <ErrorMessage
              message={formik.errors?.teamMemberOf as string}
              isError={isError('teamMemberOf')}
            />
          </FormControl>
        )}

        <Box mt={3} />
        <>
          {isAdd && (
            <ButtonPrimary
              type="submit"
              btnSize="sm"
              disabled={
                !(formik.isValid && formik.dirty && isValid && formik.values.name) || isLoading
              }
            >
              <Typography
                sx={{
                  fontSize: mobile ? '18px' : '16px',
                  textTransform: 'capitalize',
                  fontWeight: 600
                }}
              >
                Add Team Member
              </Typography>
            </ButtonPrimary>
          )}
          {isEdit &&
            (initialValues?.deactivated ? (
              <ButtonSuccessOutlined
                onClick={() => changeDeactivateMember(false)}
                btnSize="sm"
                disabled={isNotAllowed}
              >
                <Typography
                  sx={{
                    fontSize: mobile ? '18px' : '16px',
                    textTransform: 'capitalize',
                    fontWeight: 600
                  }}
                >
                  Re-activate Team Member
                </Typography>
              </ButtonSuccessOutlined>
            ) : (
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <ButtonPrimary
                    type="submit"
                    btnSize="sm"
                    disabled={!(formik.isValid && formik.dirty && isValid) || isLoading}
                  >
                    <Typography
                      sx={{
                        fontSize: mobile ? '18px' : '16px',
                        textTransform: 'capitalize',
                        fontWeight: 600
                      }}
                    >
                      Save Changes
                    </Typography>
                  </ButtonPrimary>
                </Grid>
                <Grid item md={6} xs={12}>
                  <ButtonDangerOutlined
                    onClick={() => changeDeactivateMember(true)}
                    btnSize="sm"
                    disabled={isNotAllowed}
                  >
                    <Typography
                      sx={{
                        fontSize: mobile ? '18px' : '16px',
                        textTransform: 'capitalize',
                        fontWeight: 600
                      }}
                    >
                      Deactivate
                    </Typography>
                  </ButtonDangerOutlined>
                </Grid>
              </Grid>
            ))}
        </>
      </form>
    </CustomModal>
  )
}

interface StyledProps {
  mobile: boolean
}

const LabelStyled = styled('label')`
  & > div {
    width: 100%;
  }
`

const TypographyWrapper = styled(Typography)<StyledProps>`
  margin-top: 15px;
  margin-bottom: 5px;
  font-size: ${({ mobile }) => (mobile ? '14px' : '16px')};
  font-weight: 500;

  span {
    color: ${colors.$secondary2};
    font-size: ${({ mobile }) => (mobile ? '12px' : '14px')};
    font-weight: 400;
  }
`

const TypographyWrapperByName = styled(TypographyWrapper)`
  @media screen and (max-width: 1600px) {
    margin-top: 5px;
  }
`
