import styled from '@emotion/styled'
import { useStore } from 'effector-react'
import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { $fundsList } from 'src/state/effector/store'

export const FundName = () => {
  const { fund = '' } = useParams<{ fund?: string }>()
  const funds = useStore($fundsList)

  const fundName = useMemo(
    () => funds.find(({ name }) => name.replaceAll(' ', '-') === fund)?.name,
    [funds]
  )

  return <Name>{fundName}</Name>
}

const Name = styled.div`
  font-size: 30px;
  font-weight: 700;
  letter-spacing: -0.6px;
`
