import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react'
import styled from '@emotion/styled'
import AddIcon from '@mui/icons-material/Add'
import { Box, Typography } from '@mui/material'

import { ButtonPrimary } from 'src/components/common'
import { useIsOpen } from 'src/hooks/use-is-open'
import LoadingIndicator from 'src/components/LoadingIndicator'
import apiService from 'src/services/api'
import { ResponseWithPagination } from 'src/state/response/response.types'
import { useFundData } from 'src/hooks/use-fund-data'
import { TablePagination } from 'src/components/AdminPanel/Table/Pagination'
import { colors } from 'src/constants/colors'
import { useHaveAccess } from 'src/hooks/useAccessController'
import { UserRoles } from 'src/constants'

import { InvestorsTable } from './investors-table'
import { FundName } from '../fund-name'
import { InvestorModal } from './investor-modal'
import { Filters } from './filters'
import { HideUnhideSwitch } from '../../common'

interface Props {
  prospects?: boolean
}

export const Investors = ({ prospects = false }: Props) => {
  const [investors, setInvestors] = useState<ResponseWithPagination<any> | null>(null)
  const [isLoadingData, setIsLoading] = useState({ investors: false, investments: false })
  const [showHidden, setShowHidden] = useState(false)
  const [showPastInvestor, setShowPastInvestor] = useState(false)
  const [filters, setFilters] = useState({
    investorSearch: '',
    introducerSearch: '',
    whitelistStatus: null
  })

  const searchTimer = useRef(null as any)
  const getRef = useRef(false)

  const { isOpen: isAddInvestorOpen, close: closeAddInvestor, open: openAddInvestor } = useIsOpen()
  const { fundId } = useFundData()
  const { isAllowed: isAdmin } = useHaveAccess({
    roles: [UserRoles.SuperAdmin, UserRoles.Admin],
    inFund: true
  })

  const isLoading = useMemo(
    () => Object.values(isLoadingData).some((value) => value),
    [isLoadingData]
  )

  const fetchInvestors = useCallback(
    async ({ page }: Record<string, any>) => {
      try {
        if (!fundId || getRef.current) return
        getRef.current = true

        setIsLoading((state) => ({ ...state, investors: true }))
        if (prospects) {
          setShowPastInvestor(false)
        }
        const res = await apiService.getFundInvestors({
          offset: 10,
          page,
          fundId,
          showHidden,
          ...(prospects && {
            isProspect: true
          }),
          investorSearch: filters.investorSearch.trim(),
          introducerSearch: filters.introducerSearch.trim(),
          whitelistStatus: filters.whitelistStatus,
          isPastInvestor: showPastInvestor
        })
        setInvestors(res.data)
      } catch (e) {
      } finally {
        setIsLoading((state) => ({ ...state, investors: false }))
        getRef.current = false
      }
    },
    [fundId, showHidden, filters, prospects, showPastInvestor]
  )

  useEffect(() => {
    setInvestors(null)
    setFilters({
      investorSearch: '',
      introducerSearch: '',
      whitelistStatus: null
    })
  }, [prospects])

  useEffect(() => {
    clearTimeout(searchTimer.current)
    searchTimer.current = setTimeout(() => {
      fetchInvestors({ page: 1 })
    }, 250)
    return () => {
      getRef.current = false
    }
  }, [fetchInvestors])

  const onCloseAddModal = async () => {
    try {
      closeAddInvestor()
      await fetchInvestors({ page: 1 })
    } catch (error) {}
  }

  const handleChangePagination = (e: any, page: number) => {
    fetchInvestors({ page })
  }

  if (!investors) return <LoadingIndicator />

  return (
    <>
      {isLoading && <LoadingIndicator />}
      {isAddInvestorOpen && <InvestorModal onClose={onCloseAddModal} />}

      <Container>
        <FundName />
        <Header>
          <SectionLabel>
            {prospects ? 'Prospects' : 'Investors'} ({investors.totalItems})
          </SectionLabel>
          {isAdmin && (
            <ButtonPrimary
              endIcon={<AddIcon sx={{ width: '20px' }} />}
              sx={{
                width: '202px !important',
                height: '38px !important',
                minHeight: '38px !important'
              }}
              onClick={openAddInvestor}
            >
              <Typography
                sx={{
                  fontSize: '16px',
                  textTransform: 'capitalize',
                  fontWeight: 700
                }}
              >
                Add Investor
              </Typography>
            </ButtonPrimary>
          )}
        </Header>
        <Filters filters={filters} setFilters={setFilters} prospects={prospects} />
        <Box display="flex" alignItems="center" gap={2}>
          {!prospects && (
            <HideUnhideSwitch
              checked={showPastInvestor}
              onChange={setShowPastInvestor}
              mt={0}
              text={'Show past investors'}
            />
          )}
          <HideUnhideSwitch checked={showHidden} onChange={setShowHidden} mt={0} />
        </Box>
        <InvestorsTable
          investors={investors}
          fetchInvestors={fetchInvestors}
          prospects={prospects}
          isPastInvestor={showPastInvestor}
          showHidden={showHidden}
        />
        {!prospects && (
          <TotalFunds>
            <div>Total Investments</div>
            <div>{(investors.totalInvestments || 0).toLocaleString('en-US')}</div>
          </TotalFunds>
        )}

        {Boolean(investors.items?.length) && (
          <TablePagination
            response={investors}
            page={investors.page}
            handleChange={handleChangePagination}
          />
        )}
      </Container>
    </>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-top: 32px;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 48px;
`

const SectionLabel = styled.div`
  font-size: 20px;
  font-weight: 700;
`

const TotalFunds = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding: 28px;
  color: ${colors.$secondary};
  background: rgba(212, 219, 231, 0.16);
  font-weight: 600;
  line-height: 100%;
  > div:last-of-type {
    font-size: 20px;
    font-weight: 500;
    line-height: 100%;
  }
`
