import React from 'react'
import { FormControl, MenuItem, Select, styled } from '@mui/material'
import { SelectChangeEvent } from '@mui/material/Select/SelectInput'

import { colors } from 'src/constants/colors'
import { EIntroducerAddressTypes } from 'src/state/effector/users/user.types'

interface SelectOption {
  label: 'Mother Tribe' | 'Specific Introducer'
  value: EIntroducerAddressTypes
}

const selectOptions: SelectOption[] = [
  {
    label: 'Mother Tribe',
    value: EIntroducerAddressTypes.NonUS
  },
  {
    label: 'Specific Introducer',
    value: EIntroducerAddressTypes.Specific
  }
]

interface Props {
  value: EIntroducerAddressTypes
  disabled?: boolean
  onChange: (event: SelectChangeEvent<EIntroducerAddressTypes>) => void
}

export const IntroducerWallet: React.FC<Props> = ({ value, disabled, onChange }) => {
  return (
    <FormControl fullWidth sx={{ position: 'relative' }}>
      <Select
        id="item_type"
        labelId="item_type_label"
        value={value}
        size="medium"
        onChange={onChange}
        displayEmpty
        disabled={disabled}
        sx={{
          '.MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${colors.$disabledBg2}`,
            borderRadius: 1
          },
          '.MuiOutlinedInput-input': {
            paddingTop: '14px',
            paddingBottom: '14px'
          },
          '.Mui-disabled': {
            backgroundColor: colors.$disabledBg3
          }
        }}
        MenuProps={{
          sx: {
            '.MuiPaper-root': {
              borderRadius: 0
            }
          }
        }}
      >
        {selectOptions.map(({ label, value: optionValue }) => (
          <MenuItemWrapper key={label} value={optionValue}>
            {label}
          </MenuItemWrapper>
        ))}
      </Select>
    </FormControl>
  )
}

const MenuItemWrapper = styled(MenuItem)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:hover {
    color: ${colors.$black};
  }
`
