import React, { useMemo } from 'react'
import { Checkbox, FormControl, MenuItem, Select, styled } from '@mui/material'
import { SelectChangeEvent } from '@mui/material/Select/SelectInput'
import isEqual from 'lodash/isEqual'
import { useStore } from 'effector-react'

import { colors } from 'src/constants/colors'
import { $fundsList } from 'src/state/effector/store'

export interface SelectOption {
  label: string
  value: number
}

interface Props {
  disabled: boolean
  value: number
  onChange: (event: SelectChangeEvent<number>) => void
}

export const FundsSelector = ({ value, onChange, disabled }: Props) => {
  const fundsList = useStore($fundsList)

  const selectOptions = useMemo(
    () =>
      fundsList.reduce((acc, next) => {
        acc.push({ label: next.name, value: next.id })
        return acc
      }, [] as SelectOption[]),
    [fundsList]
  )

  const getSelectedOptionLabel = (element: number) => {
    const selectedOption = selectOptions.find((opt: SelectOption) => opt.value === element)
    if (selectedOption) return selectedOption.label
    else return ''
  }

  return (
    <FormControl fullWidth sx={{ position: 'relative' }}>
      <Select
        id="item_type"
        // multiple
        value={value}
        size="medium"
        onChange={onChange}
        displayEmpty
        disabled={disabled}
        renderValue={(selected: number) => {
          return selected ? getSelectedOptionLabel(selected) : <EmptyState>Select fund</EmptyState>
        }}
        sx={{
          '.MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${colors.$disabledBg2}`,
            borderRadius: 1
          },
          '.MuiOutlinedInput-input': {
            paddingTop: '14px',
            paddingBottom: '14px'
          }
        }}
        MenuProps={{
          sx: {
            '.MuiPaper-root': {
              borderRadius: 0
            }
          }
        }}
      >
        {selectOptions.map(({ label, value: optionValue }) => (
          <MenuItemWrapper key={label} value={optionValue}>
            {label}
            {/* <CheckboxStyled checked={value?.includes(optionValue)} size="small" /> */}
          </MenuItemWrapper>
        ))}
      </Select>
    </FormControl>
  )
}

const MenuItemWrapper = styled(MenuItem)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    color: ${colors.$black};
  }
`

const CheckboxStyled = styled(Checkbox)`
  .MuiSvgIcon-root {
    fill: ${colors.$blue};
  }
`
const EmptyState = styled('div')`
  color: ${colors.$secondary2};
`
