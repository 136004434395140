import React, { useState, useEffect, useMemo, createContext, useCallback, useContext } from 'react'
import { useWeb3React } from '@web3-react/core'
import { Web3ReactContextInterface } from '@web3-react/core/dist/types'
import { useStore } from 'effector-react'
import web3 from 'web3'
import { ethers } from 'ethers'

import { connectors } from '../utils/connectors'
import { $user, $isLoadingUser, saveUserInStore } from '../state/effector/users/users.store'
import { $auth, $isMaintenanceModeActive, setFundsList } from '../state/effector/store'

export const MetaMaskContext = createContext<any>(null)

type Prop = {
  children: React.ReactNode
}

export const MetaMaskProvider = ({ children }: Prop) => {
  let provider = useMemo(() => ({}), [])
  const auth = useStore($auth)
  const user = useStore($user)
  const isLoadingUser = useStore($isLoadingUser)

  const { activate, account, chainId, active, deactivate, ...restWeb3 } =
    useWeb3React<Web3ReactContextInterface>()

  const isMaintenanceModeActive = useStore($isMaintenanceModeActive)

  const [isLoading, setIsLoading] = useState(true)
  const { injected } = connectors
  if (window.ethereum) {
    provider = new ethers.providers.Web3Provider(window.ethereum, 'any') || undefined
    const { provider: ethereum } = provider as any | undefined
    ethereum.on('accountsChanged', async (accounts: string[]) => {
      setFundsList([])
      if (accounts.length) {
        connect()
      } else {
        await deactivate()
        // delete auth?.[account as keyof typeof account]
        // setAuth(auth)
        localStorage.removeItem('walletAddr')
        localStorage.removeItem('chainId')
        saveUserInStore(null)
      }
    })
  }

  const isAuth = useMemo(() => {
    const acc = account || localStorage.getItem('walletAddr')

    if (acc) {
      return Boolean(auth?.[acc])
    }
    return false
  }, [auth, account])

  useEffect(() => {
    if (!localStorage.getItem('walletAddr')) {
      setIsLoading(false)
      return
    }

    if (isAuth && user) {
      setIsLoading(false)
      return
    }

    if (active) {
      setIsLoading(false)
    }
  }, [isAuth, active, isMaintenanceModeActive, user])

  // const isLoading = useMemo(() => {
  //   if (localStorage.getItem('walletAddr')) {
  //     return !isAuth || !active || (!isMaintenanceModeActive && !user)
  //   }
  //   return false
  // }, [isAuth, isMaintenanceModeActive, user, active])

  // Connect to MetaMask wallet
  const connect = useCallback(async () => {
    try {
      // if (localStorage.getItem('walletAddr')) {
      //   setIsLoading(true)
      // }

      await activate(injected)

      // setIsLoading(false)
    } catch (error) {
      console.log('Error on connecting: ', error)
      localStorage.removeItem('walletAddr')
      // setIsLoading(false)
    }
  }, [activate, injected])

  useEffect(() => {
    if (localStorage.getItem('walletAddr') && !active) {
      connect()
    }
  }, [connect, active])

  useEffect(() => {
    if (account && chainId) {
      localStorage.setItem('walletAddr', account)
      localStorage.setItem('chainId', String(chainId))
    }
  }, [account, chainId])

  // Disconnect from Metamask wallet

  const switchNetwork = async (chainIdNetwork: any) => {
    if ((window as any).ethereum.networkVersion !== chainIdNetwork) {
      try {
        await (window as any).ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: web3.utils.toHex(chainIdNetwork) }]
        })
      } catch (error) {
        console.log('error', error)
      }
    }
  }

  const values = useMemo(() => {
    const disconnect = async () => {
      try {
        await deactivate()
      } catch (error) {
        console.log('Error on disconnecting: ', error)
      }
    }

    return {
      active,
      account,
      isLoading: isLoading || isLoadingUser,
      connect,
      disconnect,
      switchNetwork,
      chainId,
      provider,
      isAuth,
      // setIsAuth,
      ...restWeb3
    }
  }, [
    active,
    isLoading,
    connect,
    account,
    provider,
    chainId,
    isAuth,
    restWeb3,
    deactivate,
    isLoadingUser
  ])

  return <MetaMaskContext.Provider value={values}> {children} </MetaMaskContext.Provider>
}

export default function useMetaMask() {
  const context = useContext(MetaMaskContext)

  if (context === undefined) {
    throw new Error('useMetaMask hook must be used with a MetaMaskProvider component')
  }

  return context
}
