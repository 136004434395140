import React from 'react'
import { Checkbox, FormControl, MenuItem, Select, styled } from '@mui/material'
import { SelectChangeEvent } from '@mui/material/Select/SelectInput'
import isEqual from 'lodash/isEqual'

import { colors } from 'src/constants/colors'
import { useFundData } from 'src/hooks/use-fund-data'

export interface SelectOption {
  label: string
  value: string
}

const globalOptions: SelectOption[] = [
  { label: 'Added super admin', value: 'addSuperAdmin' },
  { label: 'Added team member', value: 'addTeamMember' },
  { label: 'Deactivated team member', value: 'deactivated' },
  { label: 'Activated team member', value: 'activated' },
  { label: 'Changed role', value: 'changeRole' }
]

const fundOptions: SelectOption[] = [
  { label: 'Added investor', value: 'addInvestor' },
  { label: 'Added team member', value: 'addFundTeamMember' },
  { label: 'Changed role', value: 'changeRole' },
  { label: 'Submitted airdrop', value: 'submitAirdrop' },
  { label: 'Change whitelist', value: 'changeWhitelist' },
  { label: 'Supplemented tokens', value: 'supplementedTokes' },
  { label: 'Subtracted tokens', value: 'subtractedTokens' },
  { label: 'Deactivated team member', value: 'deactivated' },
  { label: 'Activated team member', value: 'activated' },
  { label: 'Edit Investment', value: 'editInvestment' }
]

interface Props {
  value: string[]
  onChange: (event: SelectChangeEvent<string[]>) => void
}

export const ActionTypeSelect = ({ value, onChange }: Props) => {
  const { fundId } = useFundData()
  const options = fundId ? fundOptions : globalOptions

  const getSelectedOptionLabel = (element: string) => {
    const selectedOption = options.find((opt: SelectOption) => opt.value === element)
    if (selectedOption) return selectedOption.label
    else return ''
  }

  return (
    <FormControl fullWidth sx={{ position: 'relative' }}>
      <Select
        id="item_type"
        multiple
        value={value}
        size="medium"
        onChange={onChange}
        displayEmpty
        renderValue={(selected: string[]) => {
          return !isEqual(selected, []) ? (
            selected.map((element) => getSelectedOptionLabel(element)).join(', ')
          ) : (
            <>Show All Actions</>
          )
        }}
        sx={{
          '.MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${colors.$disabledBg2}`,
            borderRadius: 1
          },
          '.MuiOutlinedInput-input': {
            paddingTop: '14px',
            paddingBottom: '14px'
          }
        }}
        MenuProps={{
          sx: {
            '.MuiPaper-root': {
              borderRadius: 0
            }
          }
        }}
      >
        {options.map(({ label, value: optionValue }) => (
          <MenuItemWrapper key={label} value={optionValue}>
            {label}
            <CheckboxStyled checked={value?.includes(optionValue)} size="small" />
          </MenuItemWrapper>
        ))}
        <MenuItemWrapper key="select-show-all" value="all">
          Show All Actions
          <CheckboxStyled checked={isEqual(value, [])} size="small" />
        </MenuItemWrapper>
      </Select>
    </FormControl>
  )
}

const MenuItemWrapper = styled(MenuItem)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    color: ${colors.$black};
  }
`

const CheckboxStyled = styled(Checkbox)`
  .MuiSvgIcon-root {
    fill: ${colors.$blue};
  }
`
