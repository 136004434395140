import React from 'react'
import styled from '@emotion/styled'

import SearchBar from '../../SearchBar'
import { ButtonTransparent } from '../../common/Button'
import { MEDIA_WIDTHS } from 'src/constants'
import { ActionTypeSelect } from './ActionTypeSelect'

interface Props {
  setFilters: any
  filters: Record<string, any>
}

export const Filters = ({ filters, setFilters }: Props) => {
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const search = event.currentTarget.value
    const inputName = event.target.name
    setFilters((state: any) => ({ ...state, [inputName]: search }))
  }

  const handleChangeActions = (event: any) => {
    const selectedValue: any = event.target.value
    const isAll = selectedValue.includes('all')
    setFilters((state: any) => ({ ...state, actions: isAll ? [] : selectedValue }))
  }

  const onClearFilters = () => {
    setFilters({
      teamWallet: '',
      actionWallet: '',
      actions: []
    })
  }

  return (
    <Container>
      <SearchBar
        name="teamWallet"
        value={filters.teamWallet}
        onChange={handleSearchChange}
        placeholder="Search Team Wallet"
      />
      <SearchBar
        name="actionWallet"
        value={filters.actionWallet}
        onChange={handleSearchChange}
        placeholder="Search Action Wallet"
      />
      <ActionTypeSelect value={filters.actions} onChange={handleChangeActions} />
      <ClearFiltersButton onClick={onClearFilters}>Clear</ClearFiltersButton>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  gap: 20px;
  > * {
    flex: 1;
  }

  @media (max-width: ${MEDIA_WIDTHS.upToSmall}px) {
    flex-direction: column;
  }
`

const ClearFiltersButton = styled(ButtonTransparent)`
  background-color: #1e2223 !important;
  color: white;
  text-transform: none;
  white-space: nowrap;
  padding: 15px 22px !important;
  max-width: 136px;
  @media (max-width: ${MEDIA_WIDTHS.upToSmall}px) {
    max-width: 100%;
  }
`
