import React, { useMemo, useState } from 'react'
import { Box, ListItemIcon, ListItemText, Menu, MenuItem, styled } from '@mui/material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import LogoutIcon from '@mui/icons-material/Logout'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import JazzIcon from 'react-jazzicon'
import { useNavigate } from 'react-router-dom'
import { useStore } from 'effector-react'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import useMetaMask from 'src/hooks/useMetaMask'
import { truncateAddress } from 'src/utils/truncateAddress'
import { colors } from 'src/constants/colors'
import { isMobile } from 'src/utils/isMobile'

import { $user } from 'src/state/effector/users/users.store'
import { UserRoles } from 'src/constants'
import { useUserRole } from 'src/hooks/useAccessController'

import { ButtonTransparent } from '../common/Button'

const AddressDropdown = () => {
  const { account, disconnect } = useMetaMask()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [copied, setCopied] = useState(false)
  const user = useStore($user)

  const userRole = useUserRole()

  const open = Boolean(anchorEl)
  const mobile = isMobile()

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const nav = useNavigate()
  const handleLogout = () => {
    localStorage.clear()
    disconnect()
    nav('/')
    window.location.reload()
  }

  const onCopy = () => {
    setCopied(true)
    setTimeout(() => setCopied(false), 1000)
  }

  const userRoleSymbol = useMemo(
    () =>
      userRole && window.location.pathname.includes('/admin')
        ? (userRole === UserRoles.User && '') ||
          (userRole === UserRoles.Viewer && '(V)') ||
          (userRole === UserRoles.Editor && '(E)') ||
          (userRole === UserRoles.Admin && '(A)') ||
          (userRole === UserRoles.SuperAdmin && '(SA)')
        : '',
    [userRole]
  )

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <ButtonTransparent
        sx={{
          color: colors.$footer,
          fontWeight: 400,
          fontSize: mobile ? 12 : 16,
          background: '#F8FAFC',
          borderRadius: '4px !important',
          padding: '8px 10px !important'
        }}
        width="auto"
        variant="outlined"
        border="false"
        boxshadow="true"
        endIcon={<KeyboardArrowDownIcon />}
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <Box mr={1} display="flex" justifyContent="flex-start" alignItems="center">
          <JazzIcon diameter={20} seed={user?.id || 0} />
        </Box>
        {truncateAddress(account)} &nbsp;
        {userRoleSymbol}
      </ButtonTransparent>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        <MenuItem
          sx={{
            width: mobile ? 175 : 200,
            borderBottom: '1px solid #E5E5E5',
            padding: '10px 15px',
            fontWeight: 'bold'
          }}
        >
          <div
            style={{
              width: '100%'
            }}
          >
            {copied ? <CopiedMessage>Copied!</CopiedMessage> : ''}
            <CopyToClipboard onCopy={onCopy} text={account}>
              <ListItemIcon sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                <ContentCopyIcon sx={{ fontSize: 18, marginRight: '15px' }} />
                <ListItemText sx={{ color: colors.$footer }}>
                  {truncateAddress(account)}
                </ListItemText>
              </ListItemIcon>
            </CopyToClipboard>
          </div>
        </MenuItem>
        <MenuItem
          sx={{
            width: mobile ? 175 : 200,
            borderBottom: '1px solid #E5E5E5',
            padding: '10px 15px',
            fontWeight: 'bold'
          }}
          onClick={handleLogout}
        >
          <ListItemIcon>
            <LogoutIcon sx={{ fontSize: 18 }} />
          </ListItemIcon>
          <ListItemText sx={{ color: colors.$footer }}>Log Out</ListItemText>
        </MenuItem>
      </Menu>
    </div>
  )
}

export default AddressDropdown

export const CopiedMessage = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 100%;
  background: ${colors.$primaryLight};
  color: ${colors.$white};
  border-radius: 10px;
  padding: 5px 7px;
  font-size: 12px;
  font-weight: 700;
  z-index: 1;
`
