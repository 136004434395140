import React from 'react'
import { Box, Tooltip } from '@mui/material'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import WarningIcon from 'src/assets/warning.svg'
import { colors } from 'src/constants/colors'
import { AddressesCell } from 'src/components/AdminPanel/Table/AddressesCell'

interface Props {
  title: string
  value: string
  valueText?: string
  address?: string
  isNotAvailable?: boolean
  icon?: JSX.Element
}

export const ResourceCard = ({ title, value, isNotAvailable, icon, valueText, address }: Props) => {
  return (
    <ItemContainer isNotAvailable={isNotAvailable}>
      <Box display="flex" mb={1}>
        <DescriptionWrapper mr={1}>{title}</DescriptionWrapper>
        {isNotAvailable && (
          <Tooltip title="Contact a manager to top-up" placement="top" arrow>
            <img src={WarningIcon} alt="warningIcon" />
          </Tooltip>
        )}
      </Box>
      <Box display="flex" gap="8px">
        {icon}
        {valueText && <ValueWrapper>{valueText}</ValueWrapper>}
        <Tooltip title={value} placement="top-start" arrow>
          <ValueWrapper>{value}</ValueWrapper>
        </Tooltip>
      </Box>
      {address && (
        <Box marginTop="16px">
          <AddressesCell address={address} id={address} />
        </Box>
      )}
    </ItemContainer>
  )
}

const ItemContainer = styled.div<{ isNotAvailable?: boolean }>`
  padding: 28px;
  border: 1px solid #d4dbe7;
  background: white;
  .text-to-copy {
    font-size: 14px;
    color: ${colors.$secondary3};
  }
  ${({ isNotAvailable }) =>
    isNotAvailable &&
    css`
      border-color: ${colors.$lightYellow};
      background: rgba(246, 185, 28, 0.05);
    `};
  @media (max-width: 1200px) {
    padding: 12px;
  }
`

const DescriptionWrapper = styled(Box)`
  color: ${colors.$secondary};
  font-weight: 500;
  font-size: 14px;
  .walletBox {
    color: ${colors.$secondary3};
  }
`

const ValueWrapper = styled(Box)`
  color: ${colors.$secondary};
  font-weight: 500;
  font-size: 28px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
