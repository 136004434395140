import React from 'react'
import styled from '@emotion/styled'
import { Box, Typography } from '@mui/material'

import { colors } from 'src/constants/colors'
import { isMobile } from 'src/utils/isMobile'
import { WhitelistTypes } from 'src/state/effector/users/user.types'
import { RadioButton } from 'src/components/common'

interface Props {
  whitelist: WhitelistTypes
  onClick: (val: WhitelistTypes) => void
}

export const WhitelistSelect = ({ whitelist = 'ACCEPTED', onClick }: Props) => {
  const mobile = isMobile()

  return (
    <Box sx={{ width: '100%' }}>
      <TypographyWrapper mobile={mobile} color={colors.$footer} variant="body1">
        Whitelist
      </TypographyWrapper>

      <WhitelistItem
        status="ACCEPTED"
        disabled={whitelist === 'ACCEPTED'}
        onClick={() => onClick('ACCEPTED')}
      >
        <RadioButton checked={whitelist === 'ACCEPTED'} />
        Approved
      </WhitelistItem>

      <WhitelistItem
        status="PENDING"
        disabled={whitelist === 'PENDING'}
        onClick={() => onClick('PENDING')}
      >
        <RadioButton checked={whitelist === 'PENDING'} />
        Pending
      </WhitelistItem>

      <WhitelistItem
        style={{ margin: 0 }}
        status="REJECTED"
        disabled={whitelist === 'REJECTED'}
        onClick={() => onClick('REJECTED')}
      >
        <RadioButton checked={whitelist === 'REJECTED'} />
        Rejected
      </WhitelistItem>
    </Box>
  )
}

interface StyledProps {
  mobile: boolean
}

interface WhitelistItemProps {
  status: WhitelistTypes
  disabled: boolean
}

const TypographyWrapper = styled(Typography)<StyledProps>`
  margin-top: 15px;
  margin-bottom: 5px;
  font-size: ${({ mobile }) => (mobile ? '14px' : '16px')};
  span {
    color: ${colors.$primary};
    font-size: ${({ mobile }) => (mobile ? '12px' : '14px')};
    font-weight: 400;
  }
`

const WhitelistItem = styled('div')<WhitelistItemProps>`
  margin-bottom: 15px;
  background: #f8fafc;
  cursor: pointer;

  border: 1px solid #d4dbe7;
  border-radius: 8px;
  padding: 12px;
  width: 100%;
  height: 53px;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;

  color: ${({ status }) =>
    (status === 'ACCEPTED' && '#0FD884') ||
    (status === 'PENDING' && '#F6B91C') ||
    (status === 'REJECTED' && '#E55252')};

  display: flex;
  justify-content: flex-start;
  align-items: center;

  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

  img {
    margin-right: 10px;
  }
`
