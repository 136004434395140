import { createEvent, createStore } from 'effector'
import { User, EUsersHiddenTypes, IShowHiddenUsers } from './user.types'
import { ResponseWithPagination } from '../../response/response.types'
import { UserRoles } from 'src/constants'

// User
export const saveUserInStore = createEvent<User | null>()
export const saveIsUserLoading = createEvent<boolean>()
export const changeUserDeactivatedStatus = createEvent<boolean>()

export const $user = createStore<User | null>(null)
  .on(saveUserInStore, (_, user) => user)
  .on(changeUserDeactivatedStatus, (state, deactivated) => {
    if (state) state.deactivated = deactivated
    return state
  })

export const $isUserDeactivated = createStore<boolean>(false)
  .on(changeUserDeactivatedStatus, (_, deactivated) => deactivated)
  .on(saveUserInStore, (_, user) => Boolean(user?.deactivated))

export const $isLoadingUser = createStore<boolean>(Boolean(localStorage.getItem('auth'))).on(
  saveIsUserLoading,
  (_, isLoading) => isLoading
)

// Users
export const saveIsUsersLoading = createEvent<boolean>()
export const saveUsersInStore = createEvent<ResponseWithPagination<User[]> | null>()
export const savePendingUsersInStore = createEvent<ResponseWithPagination<User[]> | null>()

export const changeShowHiddenUsersCount = createEvent<EUsersHiddenTypes>()

export const resetShowHiddenUsersCount = createEvent()

export const saveTeamMembersInStore = createEvent<ResponseWithPagination<User[]> | null>()

export const triggerUsers = createEvent()

export const triggerPendingUsers = createEvent()

export const savePendingUsersCountInStore = createEvent<number>()
export const triggerPendingUsersCount = createEvent<boolean>()

export const savePendingTribersCountInStore = createEvent<number>()
export const triggerPendingTribersCount = createEvent<boolean>()

export const $users = createStore<ResponseWithPagination<User[]> | null>(null).on(
  saveUsersInStore,
  (_, users) => {
    return users || null
  }
)

export const $usersTrigger = createStore<number>(0).on(triggerUsers, (state) => state + 1)

export const $pendingUsers = createStore<ResponseWithPagination<User[]> | null>(null).on(
  savePendingUsersInStore,
  (_, users) => {
    return users || null
  }
)

export const $showHiddenUsers = createStore<IShowHiddenUsers>({
  allTribers: false,
  pendingWhitelist: false,
  pendingTribers: false
})
  .on(changeShowHiddenUsersCount, (state, props) => {
    return { ...state, [props]: !state[props] }
  })
  .on(resetShowHiddenUsersCount, (_) => ({
    allTribers: false,
    pendingWhitelist: false,
    pendingTribers: false
  }))

export const $pendingUsersTrigger = createStore<number>(0).on(
  triggerPendingUsers,
  (state) => state + 1
)

export const $pendingUsersCount = createStore<number>(0).on(
  savePendingUsersCountInStore,
  (_, count) => count
)

export const $pendingTribersCount = createStore<number>(0).on(
  savePendingTribersCountInStore,
  (_, count) => count
)

export const $pendingUsersCountTrigger = createStore<boolean>(false).on(
  triggerPendingUsersCount,
  (_, trigger) => !trigger
)
export const $pendingTribersCountTrigger = createStore<boolean>(false).on(
  triggerPendingTribersCount,
  (_, trigger) => !trigger
)

export const $teamMembers = createStore<ResponseWithPagination<User[]> | null>(null).on(
  saveTeamMembersInStore,
  (_, users) => {
    return users || null
  }
)

export const $isLoadingUsers = createStore<boolean>(false).on(
  saveIsUsersLoading,
  (_, isLoading) => isLoading
)
