import React, { useEffect, useState } from 'react'
import { Box } from '@mui/system'
import { CircularProgress } from '@mui/material'
import { useSearchParams } from 'react-router-dom'
import moment from 'moment'
import styled from '@emotion/styled'

import apiService from 'src/services/api'
import { ResponseWithPagination } from 'src/state/response/response.types'
import { ISalesTransaction } from 'src/state/effector/token/token.types'
import { numberFormatter } from 'src/utils/numberFormatter'
import { useFundData } from 'src/hooks/use-fund-data'

import { PaymentsTable } from './payments-table'
import { TablePagination } from '../../Table/Pagination'
import { EmptyStateBox } from '../../common'
import { Filters } from './filters'
import { FundName } from '../fund-name'

const OFFSET_VALUE = 10
let timer = null as any

export const Payments = () => {
  const [searchParams] = useSearchParams()
  const queryEvent: string = searchParams.get('event') as string

  const [payments, setRewards] = useState<any>(null)
  const [filters, handleFilters] = useState({
    search: '',
    eventName: queryEvent || 'all'
  })

  const { fundId } = useFundData()
  const getAllRewards = async (page = 1) => {
    try {
      const validFilters = Object.entries(filters).reduce(
        (acc, [key, value]) => ({
          ...acc,
          ...(value && value !== 'all' && { [key]: value })
        }),
        {}
      )
      const res = await apiService.getAllRewards({
        page,
        offset: OFFSET_VALUE,
        fundId,
        ...validFilters,
        search: filters.search.trim()
      })
      setRewards(res.data)
    } catch (e) {}
  }

  useEffect(() => {
    handleFilters({
      search: '',
      eventName: queryEvent || 'all'
    })
    setRewards(null)
  }, [fundId])

  useEffect(() => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      getAllRewards(1)
    }, 250)
  }, [filters, fundId])

  const onFiltersChange = (callback: any) => {
    handleFilters(callback)
  }

  const handleChangePagination = (event: React.ChangeEvent<unknown>, value: number) => {
    getAllRewards(value)
  }

  const onExport = async () => {
    const validFilters = Object.entries(filters).reduce(
      (acc, [key, value]) => ({
        ...acc,
        ...(value && value !== 'all' && { [key]: value })
      }),
      {}
    )
    const res = await apiService.getAllRewards({
      page: 1,
      offset: payments?.totalItems || OFFSET_VALUE,
      fundId,
      ...validFilters
    })

    const data: ResponseWithPagination<ISalesTransaction[]> = res?.data

    const rows = [
      [
        'Date of payment',
        'Hash of txn',
        `Member's name`,
        `Member's wallet`,
        'Currency',
        'Payment',
        'Event name'
      ]
    ]

    data.items?.forEach((item: any) => {
      const row = [
        moment(item.createdAt).format('YYYY-MM-DD'),
        item.txHash || '-',
        item.user?.name || '-',
        item.user?.ethAddress || '-',
        item.currency,
        Number(item.reward),
        item.eventName
      ]

      rows.push(row)
    })

    const csvContent = 'data:text/csv;charset=utf-8,' + rows.map((e) => e.join(',')).join('\n')

    const encodedUri = encodeURI(csvContent)
    const link = document.createElement('a')
    link.setAttribute('href', encodedUri)
    link.setAttribute(
      'download',
      `payments-${queryEvent ? `${queryEvent}-` : ''}${moment().format('DD-MM-YYYY')}.csv`
    )
    document.body.appendChild(link)

    link.click()
  }

  return (
    <Container>
      <FundName />

      <SectionLabel>Payments</SectionLabel>

      {payments ? (
        <>
          <Filters filters={filters} handleFilters={onFiltersChange} />
          {payments?.items?.length ? (
            <>
              <Box>
                <PaymentsTable items={payments?.items || []} />
              </Box>
              <TablePagination
                response={payments}
                handleChange={handleChangePagination}
                page={payments.page}
                onExport={onExport}
              />
            </>
          ) : (
            <EmptyStateBox message="No records found" />
          )}
        </>
      ) : (
        <Box display="flex" justifyContent="center" alignItems="center" mt={5}>
          <CircularProgress />
        </Box>
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-top: 32px;
`

const SectionLabel = styled.div`
  font-size: 20px;
  font-weight: 700;
`
