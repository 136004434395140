import React, { FC, ChangeEvent } from 'react'
import {
  Box,
  Button,
  PaginationItem,
  styled,
  Pagination,
  Typography,
  useMediaQuery
} from '@mui/material'
import { colors } from '../../../constants/colors'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import { ResponseWithPagination } from '../../../state/response/response.types'
import exportCsvImg from '../../../assets/export-csv.png'

interface IPagination {
  response: ResponseWithPagination<any>
  page: number
  handleChange: (event: ChangeEvent<unknown>, page: number) => void
  onExport?: () => void
  showTotal?: boolean
}

export const TablePagination: FC<IPagination> = ({
  response,
  page,
  handleChange,
  showTotal = true,
  onExport
}) => {
  const hidePrevNextBtn = useMediaQuery('@media (max-width: 700px)')

  return (
    <Container>
      <Box sx={{ minHeight: '60px' }} margin="0px auto">
        <StyledPagination
          count={response.totalPages}
          shape="rounded"
          color="primary"
          size="small"
          page={response?.page || page}
          onChange={handleChange}
          hideNextButton={hidePrevNextBtn}
          hidePrevButton={hidePrevNextBtn}
          renderItem={(item: any) => (
            <PaginationContent
              components={{
                next: (props) => (
                  <PaginationButton {...props}>
                    Next
                    <KeyboardArrowRightIcon fontSize="small" />
                  </PaginationButton>
                ),
                previous: (props) => (
                  <PaginationButton color="info" {...props}>
                    <KeyboardArrowLeftIcon fontSize="small" />
                    Prev
                  </PaginationButton>
                )
              }}
              {...item}
            />
          )}
        />
      </Box>
      <ActionsContainer>
        {showTotal && Boolean(response?.totalItems) && (
          <StyledTotal>{response?.totalItems} record(s)</StyledTotal>
        )}
        {onExport && (
          <img
            src={exportCsvImg}
            alt="exportCsvImg"
            style={{ cursor: 'pointer' }}
            onClick={onExport}
          />
        )}
      </ActionsContainer>
    </Container>
  )
}

const ActionsContainer = styled('div')`
  display: flex;
  align-items: center;
  gap: 24px;
  @media (max-width: 920px) {
    width: 100%;
    justify-content: flex-end;
  }
`

const Container = styled('div')`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 15px 0px;
  flex-wrap: wrap;
  @media (max-width: 920px) {
    flex-direction: column-reverse;
  }
`

const PaginationContent = styled(PaginationItem)`
  color: ${colors.$darkBlue};
  margin: 5px;
  padding: 15px;
  font-size: 14px;

  &:hover {
    background-color: ${colors.$hoverBlue};
  }
`

const StyledPagination = styled(Pagination)`
  .MuiPaginationItem-root.Mui-selected {
    color: #fff;
    background-color: ${colors.$blue};

    &:hover {
      background-color: rgb(0, 80, 177);
    }
  }

  .MuiPaginationItem-text {
    color: ${colors.$blue};
  }

  button {
    @media screen and (max-width: 700px) {
      margin: 0;
    }

    &:hover {
      background-color: rgba(2, 136, 209, 0.04);
    }
  }
`

const PaginationButton = styled('div')`
  font-size: 14px !important;
  color: ${colors.$darkBlue};
  text-transform: none;
  display: flex;
  align-items: center;

  &:hover {
    background-color: transparent !important;
  }
`
const StyledTotal = styled(Typography)`
  font-weight: 500;
  color: #000000;
  text-align: right;
`
