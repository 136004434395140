import React from 'react'
import styled from '@emotion/styled'
import { Box, Typography } from '@mui/material'

import { colors } from 'src/constants/colors'
import { isMobile } from 'src/utils/isMobile'
import { UserRoles } from 'src/constants'
import { ROLES, useHaveAccess, useUserRole } from 'src/hooks/useAccessController'
import { RadioButton } from 'src/components/common'

interface Props {
  role: UserRoles
  onClick: (val: UserRoles) => void
  disabled?: boolean
}

const RoleSelector: React.FC<Props> = ({ role = UserRoles.Viewer, onClick, disabled = false }) => {
  const mobile = isMobile()
  const userRole = useUserRole()

  const roles = [UserRoles.SuperAdmin, UserRoles.Admin]
  const { isAllowed: isSuperAdmin } = useHaveAccess({ roles: [UserRoles.SuperAdmin] })
  const { isAllowed: isAdmin } = useHaveAccess({ roles: [UserRoles.SuperAdmin, UserRoles.Admin] })

  const checkIsAllowed = (accessRole: UserRoles) =>
    roles.includes(userRole) ? ROLES.indexOf(userRole) >= ROLES.indexOf(accessRole) : false

  const handleOnClick = (accessRole: UserRoles) => {
    if (disabled) return
    if (checkIsAllowed(accessRole)) {
      onClick(accessRole)
    }
  }

  return (
    <Box width={'100%'}>
      <TypographyWrapper mobile={mobile} color={colors.$footer} variant="body1">
        Role
      </TypographyWrapper>

      {isSuperAdmin && (
        <RoleItem
          disabled={disabled}
          role={UserRoles.SuperAdmin}
          onClick={() => handleOnClick(UserRoles.SuperAdmin)}
        >
          <RadioButton disabled={disabled} checked={role === UserRoles.SuperAdmin} />
          Super-Admin
        </RoleItem>
      )}

      {isSuperAdmin && (
        <RoleItem
          disabled={disabled}
          role={UserRoles.Admin}
          onClick={() => handleOnClick(UserRoles.Admin)}
        >
          <RadioButton disabled={disabled} checked={role === UserRoles.Admin} />
          Admin
        </RoleItem>
      )}
      {isAdmin && (
        <RoleItem
          disabled={disabled}
          role={UserRoles.Viewer}
          onClick={() => handleOnClick(UserRoles.Viewer)}
        >
          <RadioButton disabled={disabled} checked={role === UserRoles.Viewer} />
          Viewer
        </RoleItem>
      )}
      {/* 
      <RoleItem
        role={UserRoles.Editor}
        disabled={checkIsNotAllowed(UserRoles.Editor)}
        onClick={() => handleOnClick(UserRoles.Editor)}
      >
        <RadioButton
          checked={role === UserRoles.Editor}
          disabled={checkIsNotAllowed(UserRoles.Editor)}
        />
        Editor
      </RoleItem>

       */}
    </Box>
  )
}

export default RoleSelector

interface StyledProps {
  mobile: boolean
}

interface RoleItemProps {
  role: UserRoles
  disabled?: boolean
}

const TypographyWrapper = styled(Typography)<StyledProps>`
  margin-top: 15px;
  margin-bottom: 5px;
  font-size: ${({ mobile }) => (mobile ? '14px' : '16px')};
  span {
    color: ${colors.$primary};
    font-size: ${({ mobile }) => (mobile ? '12px' : '14px')};
    font-weight: 400;
  }
`

const RoleItem = styled('div')<RoleItemProps>`
  margin-bottom: 15px;
  background: #f8fafc;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

  border: 1px solid #d4dbe7;
  border-radius: 8px;
  padding: 12px;
  width: 100%;
  height: 53px;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  color: black;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  pointer-events: ${({ disabled }) => (disabled ? 'none !important' : 'auto')};

  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};

  img {
    margin-right: 10px;
  }
`
