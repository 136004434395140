import React, { useMemo, useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { Box } from '@mui/system'
import moment from 'moment'
import { toast } from 'react-toastify'

import CustomModal from 'src/components/Modal/customModal'
import { colors } from 'src/constants/colors'
import exportCsvImg from 'src/assets/export-csv.png'
import loaderIcon from 'src/assets/loader.png'
import pendingIcon from 'src/assets/pending.png'
import errorIcon from 'src/assets/error-filled.png'
import successIcon from 'src/assets/success-filled.png'
import { ButtonPrimary } from 'src/components/common'
import apiService from 'src/services/api'
import failedIcon from 'src/assets/error_icon.svg'
import { AddressesCell } from 'src/components/AdminPanel/Table/AddressesCell'
import LoadingIndicator from 'src/components/LoadingIndicator'
import { useIsOpen } from 'src/hooks/use-is-open'
import { MEDIA_WIDTHS, UserRoles } from 'src/constants'
import { useHaveAccess } from 'src/hooks/useAccessController'

import { formatValue } from './constants'
import { SpecificMember } from './execute-template/specific-members'
import { ConfirmModal } from './confirm-modal'

interface Props {
  handleClose: (needDelete: boolean) => void
  calculations: Record<string, any>
  draftId: number | null
  event?: Record<string, any> | null
  onlyView?: boolean
}

const executingStatusLabel = {
  InProgress: 'Going',
  Failed: 'Failed',
  Done: 'Done successfully'
} as Record<string, string>

const membersStatusIcon = {
  Done: <img src={successIcon} alt="successIcon" />,
  InProgress: <img src={pendingIcon} alt="pendingIcon" />,
  Failed: <img src={errorIcon} alt="errorIcon" />
} as Record<string, JSX.Element>

let interval = null as any

export const DetailsPopup = ({
  handleClose,
  calculations,
  draftId,
  event: initialEvent,
  onlyView = false
}: Props) => {
  const [isLoading, handleIsLoading] = useState(false)
  const [event, handleEvent] = useState<Record<string, any>>(initialEvent || {})
  const executingStatus = event.status

  const { fund = '' } = useParams<{ fund?: string }>()
  const navigate = useNavigate()
  const location = useLocation()

  const { isOpen: isConfirmOpen, open: openConfirm, close: closeConfirm } = useIsOpen()
  const { isOpen: isRetryOpen, open: openRetry, close: closeRetry } = useIsOpen()

  const { isAllowed: isAdmin } = useHaveAccess({
    roles: [UserRoles.SuperAdmin, UserRoles.Admin]
  })

  const withAdditionalColumn = event.distribution !== 'Manual'

  const getEvent = async (id = 0) => {
    try {
      if (!id) return
      const res = await apiService.getAirdropEvent(id)
      handleEvent({ ...res.data, isVotingRequired: res.data.isVotingRequired ? 'yes' : 'no' })
    } catch (error) {}
  }

  useEffect(() => {
    if (event.status === 'InProgress') {
      clearInterval(interval)
      interval = setInterval(() => getEvent(event.id), 10000)
    } else {
      clearInterval(interval)
    }
  }, [event])

  const onExecute = async () => {
    try {
      if (isLoading) return
      closeConfirm()
      handleIsLoading(true)
      if (draftId) {
        const res = await apiService.executeAirdrop(draftId)
        handleEvent({ ...res.data, isVotingRequired: res.data.isVotingRequired ? 'yes' : 'no' })
        clearInterval(interval)
        interval = setInterval(() => getEvent(res.data.id), 10000)
      }
    } catch (e: any) {
      const message = e.response.data.message || 'Failed to execute. Please try again.'
      toast.error(message)
    } finally {
      handleIsLoading(false)
    }
  }

  const onProceedFailed = async () => {
    try {
      closeRetry()
      handleIsLoading(true)
      const res = await apiService.retryAirdropEvent(event.id)
      handleEvent({ ...res.data, isVotingRequired: res.data.isVotingRequired ? 'yes' : 'no' })
    } catch (error: any) {
      const message = error.response.data.message || 'Failed to execute. Please try again.'
      toast.error(message)
    } finally {
      handleIsLoading(false)
    }
  }

  const onViewPayments = async () => {
    navigate(
      `/admin/fund/${fund}/payments?event=${event.name}&tab=${
        event.region !== 'US' ? 'NonUS' : 'US'
      }`
    )
  }

  const onExport = async () => {
    try {
      if (event.distribution === 'IntroducedTokens') {
        const res = await apiService.generateCsv(event.id)
        const filename = `${event.name}.xlsx`
        const link = document.createElement('a')

        link.setAttribute('href', window.URL.createObjectURL(new Blob([res.data])))
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()

        return
      }

      const rows = [[`Date`, `Investor`, `Wallet`, 'Amount paid (USDC)']]

      // if (withAdditionalColumn) {
      //   rows[0].splice(3, 0, 'Points')
      // }

      event.members.forEach((item: SpecificMember | any) => {
        console.log(item)
        const row = [
          '',
          item.name || item.investor?.name || '-',
          item.walletAddress || item.investor?.walletAddress || '-',
          item.amount.toString()
        ]
        // if (withAdditionalColumn) {
        //   row.splice(3, 0, `${Math.ceil(item.bonus || +item.amount / +calculations.pointAmount)}`)
        // }
        rows.push(row)
      })
      rows[1][0] = moment(event.createdAt).format('DD MMM YYYY HH:mm') || '-'
      const csvContent = 'data:text/csv;charset=utf-8,' + rows.map((e) => e.join(',')).join('\n')

      const encodedUri = encodeURI(csvContent)
      const link = document.createElement('a')
      link.setAttribute('href', encodedUri)
      link.setAttribute('download', `${event.name}.csv`)
      document.body.appendChild(link)

      link.click()
    } catch (error) {
      console.error(error)
    }
  }

  const onClose = () => {
    clearInterval(interval)
    handleClose(Boolean(event.status === 'Draft'))
    if (event.status !== 'Draft' && location.pathname !== `/admin/fund/${fund}/airdrops`) {
      navigate(`/admin/fund/${fund}/airdrops`)
    }
  }

  const { membersPassed, memberFailed } = useMemo(
    () =>
      (event.members || []).reduce(
        (acc: { membersPassed: number; memberFailed: number }, next: any) => {
          if (next.reward?.status === 'Done') acc.membersPassed += 1
          if (next.reward?.status === 'Failed') acc.memberFailed += 1
          return acc
        },
        {
          membersPassed: 0,
          memberFailed: 0
        }
      ),
    [event.members]
  )

  return (
    <>
      <ConfirmModal
        isOpen={isConfirmOpen}
        handleClose={closeConfirm}
        onSubmit={onExecute}
        title="Do you want to submit this Airdrop?"
      />
      <ConfirmModal
        isOpen={isRetryOpen}
        handleClose={closeRetry}
        onSubmit={onProceedFailed}
        title="Do you want to retry failed txns?"
      />
      <CustomModal
        isOpen
        title={''}
        handleClose={onClose}
        options={{ overflow: 'initial', bgColor: '#FDFCFC', maxWidth: '680px' }}
      >
        {isLoading && <LoadingIndicator />}
        <Header>{event.name}</Header>
        <Container>
          <Summary>
            <div className="row">
              <div className="label">Airdrop Budget:</div>
              <div className="value" style={{ textTransform: 'none' }}>
                {(+event.budget || +calculations.budget).toLocaleString('en-US', {
                  maximumFractionDigits: 6
                })}{' '}
                USDC
              </div>
            </div>
            <div className="row">
              <div className="label">Distribution Rule:</div>
              <div className="value" style={{ textTransform: 'none' }}>
                {formatValue('distribution', event.distribution, event as any)}
              </div>
            </div>
            {event.distribution !== 'Manual' && (
              <>
                {Boolean(calculations.totalPoints) && (
                  <div className="row">
                    <div className="label">Total points:</div>
                    <div className="value">{calculations.totalPoints}</div>
                  </div>
                )}
                {Boolean(calculations.pointAmount) && (
                  <div className="row">
                    <div className="label">Reward for 1 point:</div>
                    <div className="value">
                      {calculations.pointAmount.toLocaleString('en-US', {
                        maximumFractionDigits: 6
                      })}{' '}
                      USDC
                    </div>
                  </div>
                )}
                <div className="row">
                  <div className="label">Calculation Period:</div>
                  <div className="value">
                    {`${moment(calculations.calculationPeriod.start)
                      .utc()
                      .format('DD MMM YYYY')} - ${moment(calculations.calculationPeriod.end)
                      .utc()
                      .format('DD MMM YYYY')}`}
                    <span>{formatValue('frequency', event.frequency, event as any)}</span>
                  </div>
                </div>
              </>
            )}
          </Summary>
          <MembersToBePaid>
            <div className="header">
              <div className="title">Members to be paid: {event.members?.length || 0}</div>
              <img src={exportCsvImg} alt="export" onClick={onExport} />
            </div>
            <div style={{ overflow: 'auto' }}>
              <div style={{ minWidth: withAdditionalColumn ? '574px' : '484px' }}>
                <div
                  className="table-header"
                  style={{
                    gridTemplateColumns: withAdditionalColumn
                      ? 'minmax(150px, 1fr) 120px minmax(auto, 90px) minmax(150px, 1fr)'
                      : 'minmax(150px, 1fr) 120px minmax(150px, 1fr)'
                  }}
                >
                  <div>Member</div>
                  <div>Wallet</div>
                  {withAdditionalColumn && <div>Points</div>}
                  <div style={{ paddingRight: (event.members?.length || 0) > 4 ? '16px' : '0px' }}>
                    Reward
                  </div>
                </div>
                <div className="table">
                  {(event.members || []).map((member: SpecificMember) => (
                    <div
                      className="row"
                      key={member.walletAddress || member.address}
                      style={{
                        gridTemplateColumns: withAdditionalColumn
                          ? 'minmax(150px, 1fr) 120px minmax(auto, 90px) minmax(150px, 1fr)'
                          : 'minmax(150px, 1fr) 120px minmax(150px, 1fr)'
                      }}
                    >
                      <div className="name">
                        <span>{member.investor?.name || '-'}</span>
                      </div>
                      <div className="ethAddress">
                        <AddressesCell
                          address={member.walletAddress || member.investor?.walletAddress || '-'}
                          name={''}
                          id={`${member.walletAddress || member.investor?.walletAddress}_buyer`}
                        />
                      </div>
                      {withAdditionalColumn && (
                        <div>
                          {member.bonus || Math.ceil(+member.amount / +calculations.pointAmount)}
                        </div>
                      )}
                      <div className="value">
                        {member.amount
                          ? (+member.amount).toLocaleString('en-US', { maximumFractionDigits: 6 })
                          : '-'}{' '}
                        USDC
                        {member.reward?.status && (
                          <>{membersStatusIcon[member.reward?.status || 'InProgress']}</>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </MembersToBePaid>
        </Container>
        {(executingStatus && executingStatus !== 'Draft') || onlyView ? (
          <>
            <ExecutionStatus>
              <div className="label">
                {executingStatus === 'InProgress' && (
                  <div className="progress">
                    <img src={loaderIcon} className="rotate" alt="loader" />
                  </div>
                )}
                <div>
                  Rewarded:
                  <br />
                  {membersPassed}/{event.members?.length || 0}
                </div>
              </div>
              {executingStatus === 'Failed' && (
                <div className="label failed">
                  <div>
                    Failed*:
                    <br />
                    {memberFailed}/{event.members?.length || 0}
                  </div>
                </div>
              )}
              {executingStatus === 'Failed' ? (
                <div className="failed-info">
                  * Failures usually happen when your payment wallet
                  <br />
                  doesn’t have enough ETH (for gas) or USDC (for transfer).
                </div>
              ) : (
                <div className={`status status-${executingStatus}`}>
                  {executingStatusLabel[executingStatus]}
                </div>
              )}
            </ExecutionStatus>
            {executingStatus === 'Done' && (
              <Box
                onClick={onViewPayments}
                sx={{
                  textDecoration: 'underline',
                  fontSize: '12px',
                  cursor: 'pointer',
                  color: colors.$blue,
                  margin: '24px 0px 20px',
                  textAlign: 'center'
                }}
              >
                View payments
              </Box>
            )}
            {executingStatus === 'Failed' && isAdmin && (
              <FiledSteps>
                <div className="header">
                  <img src={failedIcon} alt="failedIcon" width="24px" />
                  Top-up your wallet before retry
                </div>
                <ButtonPrimary
                  sx={{
                    textTransform: 'none',
                    marginBottom: '8px',
                    width: 'fit-content',
                    padding: '10px 16px'
                  }}
                  onClick={openRetry}
                >
                  Retry failed
                </ButtonPrimary>
                <div className="info">
                  If it still doesn’t work after the top-up, please contact the support team &nbsp;
                  <a href="mailto:support@tokenee.com">support@tokenee.com</a>
                </div>
              </FiledSteps>
            )}
          </>
        ) : (
          <ExecuteBtnContainer>
            {isAdmin && (
              <ButtonPrimary disabled={isLoading} onClick={openConfirm}>
                Submit
              </ButtonPrimary>
            )}
          </ExecuteBtnContainer>
        )}
      </CustomModal>
    </>
  )
}

const Header = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 138%;
  margin-bottom: 20px;
  color: ${colors.$secondary};
  text-align: center;
`

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 32px;
  margin-bottom: 24px;
`

const Summary = styled.div`
  /* background-color: #f8fafc; */
  border: 1px solid ${colors.$borderSecondary};
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  .row {
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
    gap: 16px;
    .label {
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      color: ${colors.$default};
    }
    .value {
      text-transform: capitalize;
      color: ${colors.$secondary};
      line-height: 150%;
      font-weight: 600;
      font-size: 14px;
      display: flex;
      flex-direction: column;
      text-align: right;
      > span {
        color: ${colors.$secondary2};
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
      }
      @media (max-width: ${MEDIA_WIDTHS.upToSmall}px) {
        text-align: left;
      }
    }
    @media (max-width: ${MEDIA_WIDTHS.upToSmall}px) {
      flex-direction: column;
      gap: 8px;
    }
  }
  @media (max-width: ${MEDIA_WIDTHS.upToSmall}px) {
    padding: 12px;
  }
`

const MembersToBePaid = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 1px;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    margin-bottom: 20px;
    .title {
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      color: ${colors.$secondary};
      > span {
        color: ${colors.$secondary2};
      }
    }
    img {
      cursor: pointer;
    }
  }
  .table-header {
    display: grid;
    gap: 12px;
    padding: 12px;
    background-color: ${colors.$primaryDarkLight};
    font-weight: 600;
    font-size: 14px;
    line-height: 138%;
    color: white;
    > div:last-of-type {
      text-align: right;
    }
  }
  .table {
    max-height: 192px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    /* background: #f8fafc; */
    border: 1px solid ${colors.$borderSecondary};
    > div {
      border-bottom: 1px solid #d9d9d9;
    }
    > div:last-of-type {
      border-bottom: none;
    }
    .row {
      min-height: 48px;
      display: grid;
      > div:last-of-type {
        text-align: right;
      }
      gap: 19px;
      font-weight: 400;
      font-size: 14px;
      line-height: 138%;
      padding: 12px;
      color: ${colors.$default};
      .name {
        display: flex;
        align-items: center;
        gap: 4px;
        min-width: 1px;
        span {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .value {
        display: flex;
        align-items: center;
        gap: 9px;
        justify-content: flex-end;
      }
      .ethAddress {
        > div > div {
          justify-content: space-between;
        }
      }
      .text-to-copy {
        font-weight: 400;
        font-size: 14px;
        line-height: 138%;
      }
      .copy-icon {
        font-size: 20px;
      }
      .copied {
        left: -5px;
        transform: translateX(-100%);
        top: -7.5px;
        height: 30px;
        width: fit-content;
      }
    }
  }
`

const ExecuteBtnContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    max-width: 180px;
    text-transform: none;
  }
`

const ExecutionStatus = styled.div`
  /* background: #f8fafc; */
  border: 1px solid ${colors.$borderSecondary};
  padding: 18px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  .label {
    font-weight: 700;
    font-size: 14px;
    line-height: 138%;
    color: ${colors.$secondary};
    display: flex;
    align-items: center;
    gap: 12px;
    .progress {
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: ${colors.$primaryLight};
      .rotate {
        animation: rotation 2s infinite linear;
      }
      @keyframes rotation {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(359deg);
        }
      }
    }
  }
  .failed-info {
    font-weight: 400;
    font-size: 12px;
    line-height: 180%;
    color: ${colors.$secondary};
  }
  .failed {
    color: ${colors.$error};
  }
  .status-container {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .status {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
  }
  .status-InProgress {
    color: ${colors.$lightYellow};
  }
  .status-Done {
    color: #1eb173;
  }
  .status-Failed {
    color: ${colors.$error};
  }
`

const FiledSteps = styled.div`
  margin: 24px 0px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  .header {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .info {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    a {
      color: ${colors.$blue};
      text-decoration: underline;
    }
  }
`
