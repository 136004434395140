// import React from 'react'
import { Button as RebassButton, ButtonProps as ButtonPropsOriginal, css } from '@mui/material'
import styled from '@emotion/styled'

import { colors } from '../../../constants/colors'

type ButtonProps = Omit<ButtonPropsOriginal, 'css'>

type ButtonSize = 'sm' | 'md' | 'lg'

interface CustomButtonProps {
  btnSize?: ButtonSize
}

const getCurrentHeight = (size: ButtonSize = 'md') =>
  (size === 'sm' && '40px') || (size === 'md' && '48px') || (size === 'lg' && '52px') || '48px'

interface IBase extends ButtonProps {
  padding?: string
  width?: string
  border?: string
  boxshadow?: string
  altDisabledStyle?: boolean
  mobile?: number
}

const Base = styled(RebassButton)<IBase>`
  padding: ${({ padding }) => (padding ? padding : '5px')};
  width: ${({ width }) => (width ? width : '100%')};
  font-weight: 600;
  text-align: center;
  // border-radius: 5px;
  font-size: 14px;
  color: white;
  text-decoration: none;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 1;
  &:disabled {
    cursor: auto;
    pointer-events: none;
  }

  > * {
    user-select: none;
  }

  > a {
    text-decoration: none;
  }
`

export const ButtonPrimary = styled(Base)<CustomButtonProps>`
  background-color: ${colors.$blue};
  color: rgba(255, 255, 255, 1);
  min-height: ${({ btnSize }) => getCurrentHeight(btnSize)};
  z-index: 0;
  &:hover {
    background-color: ${colors.$lightBlue};
  }
  &:active {
    background-color: ${colors.$blue};
  }
  &:disabled {
    color: white;
    opacity: 1;
    cursor: auto;
    box-shadow: none;
    border: 1px solid transparent;
    outline: none;
    /* opacity: 0.4; */
    background: ${colors.$disabledBg2};
    /* opacity: ${({ altDisabledStyle }) => (altDisabledStyle ? '0.5' : '0.4')}; */
  }
  /* ${({ sx }) => sx && css`sx`} */
`

export const ButtonPrimaryOutlined = styled(Base)<CustomButtonProps>`
  border: 1px solid ${colors.$blue};
  color: ${colors.$blue};
  min-height: ${({ btnSize }) => getCurrentHeight(btnSize)};
  z-index: 0;

  &:hover {
    background-color: transparent;
    color: ${colors.$lightBlue};
    border-color: ${colors.$lightBlue};
  }
  &:active {
    background-color: ${colors.$lightBlue};
    color: ${colors.$white};
  }
  &:disabled {
    cursor: auto;
    box-shadow: none;
    border: 1px solid ${colors.$disabledBg2};
    outline: none;
    opacity: 0.8;
  }
  ${({ sx }) => sx && css`sx`}
`

export const ButtonDangerOutlined = styled(Base)<CustomButtonProps>`
  border: 1px solid ${colors.$error};
  color: ${colors.$error};
  min-height: ${({ btnSize }) => getCurrentHeight(btnSize)};
  z-index: 0;
  text-transform: none;

  &:hover {
    background-color: transparent;
    color: ${colors.$lightError};
    border-color: ${colors.$lightError};
  }
  &:active {
    background-color: ${colors.$lightError};
    color: ${colors.$white};
  }
  &:disabled {
    cursor: auto;
    box-shadow: none;
    border: 1px solid ${colors.$disabledBg2};
    outline: none;
    opacity: 0.8;
  }
`

export const ButtonSuccess = styled(Base)<CustomButtonProps>`
  background-color: ${colors.$success};
  color: white;
  min-height: ${({ btnSize }) => getCurrentHeight(btnSize)};
  z-index: 0;

  &:hover {
    background-color: ${colors.$lightSuccess2};
  }
  &:active {
    background-color: ${colors.$lightSuccess2};
  }
  &:disabled {
    cursor: auto;
    box-shadow: none;
    background-color: ${colors.$disabledBg2};
    outline: none;
    opacity: 0.8;
  }
`

export const ButtonSuccessOutlined = styled(Base)<CustomButtonProps>`
  border: 1px solid ${colors.$success};
  color: ${colors.$success};
  min-height: ${({ btnSize }) => getCurrentHeight(btnSize)};
  z-index: 0;

  &:hover {
    background-color: transparent;
    color: ${colors.$lightSuccess2};
    border-color: ${colors.$lightSuccess2};
  }
  &:active {
    background-color: ${colors.$lightSuccess2};
    color: ${colors.$lightSuccess2};
  }
  &:disabled {
    cursor: auto;
    box-shadow: none;
    border: 1px solid ${colors.$disabledBg2};
    outline: none;
    opacity: 0.8;
  }
`

export const ButtonPrimaryShort = styled(ButtonPrimary)`
  width: auto;
  /* padding-left: 40px; */
  /* padding-right: 40px; */
`

export const ButtonPrimaryTransparent = styled(Base)`
  background-color: ${colors.$white};
  color: ${colors.$blue};
  border: 2px solid ${colors.$blue};
  /* border-radius: 6px; */
  font-size: ${({ mobile }) => (mobile ? '16px' : '20px')};
  font-weight: 600;
  height: 54px;
  max-width: 280px;
  text-transform: inherit;

  &:hover {
    background-color: ${colors.$blue};
    color: ${colors.$white};
  }
`

export const ButtonTransparent = styled(Base)`
  background-color: transparent;
  color: ${colors.$primary};
  border: ${({ border }) => (border === 'true' ? `1px solid ${colors.$primary}` : 'none')};
  box-shadow: ${({ boxshadow }) => (boxshadow === 'true' ? colors.$boxShadow3 : 'none')};
  &:focus {
    // background-color: ${({ disabled }) => !disabled && 'red'};
  }
  &:hover {
    background-color: transparent;
    border: ${({ border }) => (border === 'true' ? `1px solid ${colors.$primary}` : 'none')};
  }
  &:active {
    // background-color: ${({ disabled }) => !disabled && 'green'};
  }
`

// Base buttons
export const ButtonBase = styled(Base)`
  /* border-radius: 6px; */
  text-transform: capitalize;
  border: 1px solid ${colors.$blue};
`

export const ButtonBasePrimary = styled(ButtonBase)`
  background-color: ${colors.$blue};
  color: rgba(255, 255, 255, 1);
  &:hover {
    background-color: ${colors.$blueSecondary};
  }
  &:active {
    background-color: ${colors.$blue};
  }
  &:disabled {
    color: white;
    opacity: 1;
    cursor: auto;
    box-shadow: none;
    border: 1px solid transparent;
    outline: none;
    /* opacity: 0.4; */
    background: ${colors.$disabledBg2};
    /* opacity: ${({ altDisabledStyle }) => (altDisabledStyle ? '0.5' : '0.4')}; */
  }
`

export const ButtonBasePrimaryOutlined = styled(ButtonBase)`
  background-color: transparent;
  color: ${colors.$blue};
  border-color: ${colors.$blue};

  &:hover {
    background-color: ${colors.$blue};
    color: white;
    border-color: ${colors.$blue};
  }
  &:active {
    background-color: ${colors.$blueSecondary};
  }
  &:disabled {
    border-color: ${colors.$disabledBg2};
  }
`
