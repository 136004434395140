import React, { Suspense, useLayoutEffect, useMemo } from 'react'
import { Navigate, Outlet, useLocation, useParams } from 'react-router-dom'
import { Box } from '@mui/system'
import { useMediaQuery } from '@mui/material'
import { useStore } from 'effector-react'

import { Navigation } from '../AdminPanel/Navigation'
import { Header } from '../AdminPanel/Header'
import LoadingIndicator from '../LoadingIndicator'
import { AdminLayoutWrapper, BoxWrapper, ContentWrapper } from './styles'
import { useHaveAccess } from 'src/hooks/useAccessController'
import useMetaMask from 'src/hooks/useMetaMask'
import { $auth, $fundsList, $isMaintenanceModeActive } from 'src/state/effector/store'
import { $user, $isUserDeactivated } from 'src/state/effector/users/users.store'
import { UserRoles } from 'src/constants'
import { useFundData } from 'src/hooks/use-fund-data'

import { MaintenanceModeOnBlock } from '../AdminPanel/MaintenanceMode/MaintenanceModeOnBlock'

const AdminLayout: React.FC = () => {
  const { isNotAllowed } = useHaveAccess({ roles: [UserRoles.User], onlyNot: true })
  const { account, isLoading } = useMetaMask()
  const auth = useStore($auth)
  const user = useStore($user)
  const isUserDeactivated = useStore($isUserDeactivated)
  const isMaintenanceModeActive = useStore($isMaintenanceModeActive)
  const { fundId } = useFundData()
  const fundsList = useStore($fundsList)
  const { fund: fundName = '' } = useParams()
  const isLaptop = useMediaQuery('@media(min-width: 1000px)')

  const location = useLocation()

  useLayoutEffect(() => {
    // Destroy support beacon for Admin panel
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (window?.Beacon) window.Beacon!('destroy')
  }, [location.pathname])

  const needRedirect = useMemo(() => {
    if (isLoading) return false

    if (auth?.[account]) {
      if (user && (isUserDeactivated || isNotAllowed)) {
        return true
      }
      return false
    }
    return true
  }, [auth, account, user, isUserDeactivated, isNotAllowed, isLoading])

  const needRedirectFromFund = useMemo(() => {
    if (isLoading) return false

    if (user && user.role !== UserRoles.SuperAdmin) {
      if (
        fundName &&
        fundsList.length &&
        fundsList.every((fund) => fund.name.replaceAll(' ', '-') !== fundName)
      ) {
        return true
      }
      return false
    }
    return false
  }, [auth, account, user, isLoading, fundName, fundsList])

  if (needRedirect) {
    let to = '/'
    if (!auth?.[account]) to = '/?redirectTo=/admin'
    return <Navigate to={to} replace={true} />
  }

  if (needRedirectFromFund) {
    let to = '/admin'
    if (fundsList.length) {
      to = `/admin/fund/${fundsList[0].name.replaceAll(' ', '-')}/dashboard`
    }
    return <Navigate to={to} />
  }

  // Destroy support beacon for Admin panel
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (window?.Beacon) window.Beacon('destroy')

  if (isNotAllowed) {
    return <LoadingIndicator />
  }

  const withoutMaxWidth = location.pathname.includes('manual')

  return (
    <Suspense fallback={<LoadingIndicator />}>
      <Box position="relative" sx={{ paddingTop: isMaintenanceModeActive ? '37px' : 0 }}>
        <MaintenanceModeOnBlock />
        <AdminLayoutWrapper isMaintenanceModeActive={isMaintenanceModeActive}>
          <Navigation />

          <BoxWrapper
            mobile={!isLaptop}
            isMaintenanceModeActive={isMaintenanceModeActive}
            withoutMaxWidth={withoutMaxWidth}
            className="hide-scroll"
          >
            <Header />

            <ContentWrapper mobile={!isLaptop}>
              <Outlet />
            </ContentWrapper>
          </BoxWrapper>
        </AdminLayoutWrapper>
      </Box>
    </Suspense>
  )
}

export default AdminLayout
