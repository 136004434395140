import React, { useState, useEffect, useCallback, useMemo } from 'react'
import styled from '@emotion/styled'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useFormik } from 'formik'
import { IconButton, useMediaQuery } from '@mui/material'
import { toast } from 'react-toastify'
import { useNavigate, useParams } from 'react-router-dom'

import { colors } from 'src/constants/colors'
import { ButtonPrimary } from 'src/components/common'
import { useIsOpen } from 'src/hooks/use-is-open'
import apiService from 'src/services/api'
import LoadingIndicator from 'src/components/LoadingIndicator'
import { CHAIN_INFO } from 'src/constants'
import useMetaMask from 'src/hooks/useMetaMask'
import { useFundData } from 'src/hooks/use-fund-data'

import { validationSchema } from './validation-schema'
import { TemplatePreset } from '../template-preset'

import {
  AirdropName,
  CalculationPeriod,
  DistributionRule,
  ManualDistribution,
  AirdropFixedBudget
} from '../step-components'
import { DetailsPopup } from '../details-popup'
import { SpecificMember } from '../execute-template/specific-members'

let timer = null as any

const defaultValues = {
  name: '',
  budget: '',
  distribution: '',
  frequency: '',
  specificMembers: []
}

const getStepFields = (distribution: string) =>
  ['name', 'budget', 'distribution', 'frequency', 'specificMembers'].reduce(
    (acc: string[], next): string[] => {
      if (distribution === 'Manual' && next === 'frequency') return acc
      if (distribution !== 'Manual' && next === 'specificMembers') return acc

      return [...acc, `${acc[acc.length - 1] ? `${acc[acc.length - 1]},` : ''}${next}`]
    },
    []
  )

export const ManualAirdrop = () => {
  const [passedSteps, handlePassedSteps] = useState<Array<number>>([0])
  const [calculations, handleCalculations] = useState<Record<string, any>>({})
  const [isLoadingData, handleIsDataLoading] = useState<Record<string, boolean>>({})
  const [draftId, handleDraftId] = useState(null)
  const [event, handleEvent] = useState(null)

  const { chainId } = useMetaMask()

  const navigate = useNavigate()
  const { fundId } = useFundData()
  const { fund = '' } = useParams<{ fund?: string }>()

  const isLaptop = useMediaQuery('@media(min-width: 1200px)')

  const isLoading = useMemo(() => Object.values(isLoadingData).some((el) => el), [isLoadingData])

  const { isOpen: isExecuteOpen, open: openExecute, close: closeExecute } = useIsOpen()

  const onGoBack = () => {
    navigate(`/admin/fund/${fund}/airdrops`)
  }

  const onSubmit = async (values: any) => {
    try {
      handleIsDataLoading((state) => ({ ...state, submit: true }))

      const draft = await apiService.createOneTimeEvent({
        fundId,
        network: CHAIN_INFO[chainId].networkType,
        name: values.name,
        distribution: values.distribution,
        budget: calculations.budget || values.budget,
        rewardType: 'IsFixedAmount',
        ...(values.distribution === 'Manual' && {
          members: values.specificMembers.map((member: SpecificMember) => ({
            address: member.walletAddress,
            amount: member.amount
          }))
        }),
        frequency: values.frequency || 'Monthly'
      })
      handleDraftId(draft.data.id)
      handleEvent({ ...draft.data, isVotingRequired: draft.data.isVotingRequired ? 'yes' : 'no' })
      openExecute()
      return
    } catch (e: any) {
      const message = e.response.data.message || 'Failed to execute. Please try again.'
      toast.error(message)
    } finally {
      handleIsDataLoading((state) => ({ ...state, submit: false }))
    }
  }

  const {
    values,
    handleChange,
    errors,
    setFieldValue,
    validateForm,
    touched,
    submitForm,
    setFieldError,
    setFieldTouched
  } = useFormik({
    initialValues: defaultValues,
    validationSchema: validationSchema(passedSteps.length),
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnMount: true,
    onSubmit: () => {}
  })

  const stepFields = getStepFields(values.distribution)

  const fetchCalculations = async () => {
    try {
      handleIsDataLoading((state) => ({ ...state, summary: true }))

      const summary = await apiService.getManualSummary({
        networkId: chainId,
        frequency: values.frequency,
        distribution: values.distribution,
        rewardType: 'IsFixedAmount',
        budget: values.budget,
        fundId
      })

      setFieldValue('budget', summary.data.budget)

      handleCalculations({
        budget: summary.data.budget,
        calculationPeriod: summary.data.calculationPeriod,

        ...(values.distribution !== 'Manual' && {
          totalPoints: summary.data.totalPoints,
          pointAmount: summary.data.pointAmount,
          membersCount: summary.data.membersCount
        })
      })
    } catch (error) {
    } finally {
      handleIsDataLoading((state) => ({ ...state, summary: false }))
    }
  }

  useEffect(() => {
    if (values.distribution && values.frequency && values.budget) {
      clearTimeout(timer)
      timer = setTimeout(fetchCalculations, 250)
    }

    return () => {
      clearTimeout(timer)
    }
  }, [values.frequency, values.distribution, values.budget])

  const maxSteps = 4

  useEffect(() => {
    if (passedSteps.length > maxSteps) {
      handlePassedSteps((state) => {
        state.pop()
        return state
      })
    }
  }, [maxSteps, passedSteps])

  const onNext = useCallback(async () => {
    try {
      await submitForm()

      const res = await validateForm(values)

      if (Object.keys(res).length !== 0) return

      const events = await apiService.getAirdropEvents({
        offset: 20,
        duplicate: values.name,
        fundId
      })
      if (events.data.items.length) {
        setFieldError('name', `Event with name '${values.name}' already exists`)
        return
      }

      if (passedSteps.length === maxSteps) {
        onSubmit(values)
      } else {
        handlePassedSteps((state) => {
          const newState = [...state, state.length]
          if (newState.length === 4 && values.distribution === 'Manual') {
            setFieldValue(
              'specificMembers',
              [
                {
                  name: '',
                  walletAddress: '',
                  amount: '',
                  percent: ''
                }
              ],
              true
            )
          }
          return newState
        })
      }
      setTimeout(() => {
        document.getElementById('endOfForm')?.scrollIntoView({ behavior: 'smooth' })
      }, 100)
    } catch (e) {
      console.error(e)
    }
  }, [values, passedSteps, validateForm, maxSteps, submitForm, fundId])

  const actionBtnText = useMemo(() => {
    if (passedSteps.length !== maxSteps) return 'Next Step'
    return 'Create Airdrop'
  }, [passedSteps.length, maxSteps])

  const onCloseExecute = async (needDelete = true) => {
    try {
      handleIsDataLoading((state) => ({ ...state, deleteDraft: true }))
      if (draftId && needDelete) {
        await apiService.deleteAirdropDraft(draftId)
      }
      closeExecute()
    } catch (error) {
    } finally {
      handleIsDataLoading((state) => ({ ...state, deleteDraft: false }))
    }
  }

  const changeBudget = (e: any) => {
    if (isLoading) return
    setFieldValue(e.target.name, e.target.value.match(/[0-9]*[.]?[0-9]{0,6}/g)[0] || '', true)
  }

  const disabledNext = useMemo(() => {
    if (calculations.calculationPeriod) {
      return Object.values(calculations).some((value) => !value)
    }

    return false
  }, [calculations])

  if (!isLaptop) return <UseLaptop>Use a device with bigger screen width for this page</UseLaptop>

  return (
    <>
      {isExecuteOpen && (
        <DetailsPopup
          draftId={draftId}
          handleClose={onCloseExecute}
          calculations={calculations}
          event={event}
        />
      )}
      {isLoading && <LoadingIndicator />}

      <Container>
        <Form id="template-form">
          <div className="header">
            <IconButton onClick={onGoBack}>
              <ArrowBackIcon sx={{ color: '#111213' }} />
            </IconButton>
          </div>
          <StepsContainer>
            {passedSteps.includes(0) && (
              <AirdropName
                value={values.name}
                onChange={handleChange}
                error={touched.name ? errors.name : ''}
                setFieldTouched={setFieldTouched}
              />
            )}
            {passedSteps.includes(1) && (
              <AirdropFixedBudget
                step={2}
                value={values.budget}
                error={touched.budget ? errors.budget : ''}
                onChange={changeBudget}
              />
            )}
            {passedSteps.includes(2) && (
              <DistributionRule
                step={3}
                value={values.distribution}
                error={touched.distribution ? errors.distribution : ''}
                onChange={(value: string) => {
                  if (value !== 'Manual' && values.distribution === 'Manual') {
                    setFieldValue('specificMembers', [], true)
                  }
                  setFieldValue('distribution', value, true)
                }}
              />
            )}

            {passedSteps.includes(3) && values.distribution !== 'Manual' && (
              <CalculationPeriod
                step={4}
                value={values.frequency}
                error={touched.frequency ? errors.frequency : ''}
                onChange={(value: string) => setFieldValue('frequency', value, true)}
              />
            )}
            {passedSteps.includes(3) && values.distribution === 'Manual' && (
              <ManualDistribution
                step={4}
                errors={errors}
                touched={touched}
                specificMembers={values.specificMembers}
                setFieldValue={setFieldValue}
                budget={calculations.budget || values.budget}
                template={values}
              />
            )}
          </StepsContainer>
          <ActionContainer id="actions">
            <ButtonPrimary onClick={onNext} disabled={disabledNext}>
              {actionBtnText}
            </ButtonPrimary>
          </ActionContainer>
          <div id="endOfForm"></div>
        </Form>
        <Sidebar>
          <TemplatePreset
            keys={stepFields[passedSteps.length - 1].split(',')}
            values={values}
            calculations={values.distribution !== 'Manual' ? calculations : {}}
          />
        </Sidebar>
      </Container>
    </>
  )
}

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 224px;
  column-gap: 80px;
  grid-template-rows: calc(100vh - 88px - 46px - 30px);
`

export const Form = styled.div`
  padding-right: 16px;
  overflow: auto;
  .header {
    display: flex;
    align-items: center;
    gap: 21px;
    margin-bottom: 80px;
    div {
      font-weight: 700;
      text-align: center;
      flex: 1;
    }
  }
`

export const Sidebar = styled.div``

export const StepsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 80px;
`

export const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 32px;
  gap: 32px;
  font-weight: 400;
  font-size: 14px;
  button {
    text-transform: none;
    max-width: 180px;
    min-height: 44px;
  }
  .enterContainer {
    display: flex;
    align-items: center;
    img {
      margin-left: 9px;
    }
  }
  .enter {
    font-weight: 700;
    font-size: 16px;
  }
`

const UseLaptop = styled.div`
  padding: 32px;
  width: 100%;
  height: 200px;
  font-size: 24px;
  color: ${colors.$secondary};
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
`
