import React from 'react'
import styled from '@emotion/styled'

import SearchBar from '../../SearchBar'
import { ButtonTransparent } from '../../common/Button'
import { MEDIA_WIDTHS } from '../../../constants'

interface Props {
  setFilters: any
  filters: Record<string, any>
}

export const Filters = ({ filters, setFilters }: Props) => {
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const search = event.currentTarget.value
    const inputName = event.target.name
    setFilters((state: any) => ({ ...state, [inputName]: search }))
  }

  const onClearFiters = () => {
    setFilters({
      searchByName: '',
      searchByTeamWallet: ''
    })
  }

  return (
    <Container>
      <SearchBar
        name="searchByName"
        value={filters.searchByName}
        onChange={handleSearchChange}
        placeholder="Search by Fund Name"
      />
      <SearchBar
        name="searchByTeamWallet"
        value={filters.searchByTeamWallet}
        onChange={handleSearchChange}
        placeholder="Search by Creator"
      />
      <ClearFiltersButton onClick={onClearFiters}>Clear</ClearFiltersButton>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  gap: 20px;
  > * {
    flex: 1;
  }

  @media (max-width: ${MEDIA_WIDTHS.upToSmall}px) {
    flex-direction: column;
  }
`

const ClearFiltersButton = styled(ButtonTransparent)`
  background-color: #1e2223 !important;
  color: white;
  text-transform: none;
  white-space: nowrap;
  padding: 15px 22px !important;
  max-width: 136px;
  @media (max-width: ${MEDIA_WIDTHS.upToSmall}px) {
    max-width: 100%;
  }
`
